import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISNGPPool } from '../../types/sngpPool.type';

const initialState: ISNGPPool = {
  activityLoading: false,

  activity: {
    balance: 0,
    generated: 0,
  },

  claimedDetails: {
    claimedAmount: 0,
    claimedDate: '',
    rewardToken: '',
  },

  totalSNGPLoading: false,
  totalSngp: {
    totalSngp: 0,
  },
  totalClaimed: {
    globalSngpUsed: 0,
  },
  sngpRecords: [],
  userSNGPCommunityLoading: false,
  userSNGPCommunity: null,
  historyLoading: false,
  history: null,
  sngpRecordsLoading: false,
  sngpBannerLoading: false,
  sngpBannerData: null,
  claimLoading: false,
};

export const SNGP_SLICE_NAME = 'sngpPool';
const sngpPoolSlice = createSlice({
  name: SNGP_SLICE_NAME,
  initialState,
  reducers: {
    setSNGPPoolSlice: (state, action: PayloadAction<Partial<ISNGPPool>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSNGPPoolSlice } = sngpPoolSlice.actions;

export const selectSNGPPoolSlice = (state: { [SNGP_SLICE_NAME]: ISNGPPool }) =>
  state[SNGP_SLICE_NAME];

export default sngpPoolSlice.reducer;
