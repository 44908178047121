import { MayHaveLabel, ResponsivePie } from '@nivo/pie';
import { useWindowSize } from '../utils/helper';
import { memo, useEffect, useRef, useState } from 'react';
import GlobalSkeleton from './Global/GlobalSkeleton';
import { ComputedDatum } from '@nivo/bar';

export type TPieChart = {
  data?: any;
  colors?: any;
  valueFormat?: any;
  enableArcLinkLabels?: boolean;
  enableArcLabels?: boolean;
  innerRadius?: number;
  padAngle?: number;
  totalPercentage?: number | string | undefined;
  isInteractive?: boolean;
  loading?: boolean;
  arcLinkLabel?: (d: ComputedDatum<MayHaveLabel>) => string;
};

const CenteredText = memo(
  ({
    centerX,
    centerY,
    totalPercentage,
  }: {
    centerX: number;
    centerY: number;
    totalPercentage: number | string | undefined;
  }) => {
    return (
      <g transform={`translate(${centerX}, ${centerY})`}>
        <text
          textAnchor="middle"
          dominantBaseline="central"
          style={{ fontSize: '10px', fill: '#969696' }}
          y={-10} // Adjust this value to vertically position the first line
        >
          Total Values
        </text>
        <text
          textAnchor="middle"
          dominantBaseline="central"
          style={{ fontSize: '16px', fontWeight: 'bold' }}
          y={10} // Adjust this value to vertically position the second line
        >
          {Number(totalPercentage).toFixed(2)}%
        </text>
      </g>
    );
  }
);

export const WalletPieChart = memo(
  ({
    data,
    colors,
    valueFormat,
    enableArcLinkLabels = true,
    enableArcLabels = true,
    innerRadius = 0.6,
    padAngle = 0.7,
    totalPercentage,
    isInteractive = true,
    loading = false,
    arcLinkLabel = (d: ComputedDatum<MayHaveLabel>) => '',
  }: TPieChart) => {
    const size = useWindowSize();

    // Define default values for arc link lengths based on screen size
    const diagonalLength = size.width <= 425 ? 3 : 15;
    const straightLength = size.width <= 425 ? 10 : 12;
    const [hoveredSliceId, setHoveredSliceId] = useState<string | null>(null);

    const handleMouseEnter = (data: any) => {
      setHoveredSliceId(data.id);
    };

    const handleMouseLeave = () => {
      setHoveredSliceId(null);
    };

    const getArcLinkLabelColor = (id: any) => {
      return hoveredSliceId === id.id ? id.color : 'white';
    };
    return (
      <div className="w-full h-full rounded-full border border-[#F3F3F3] z-999 bg-white shadow-pieChartBoxShadow relative">
        {loading && data.length === 0 ? (
          <div className="p-2 h-full w-full">
            <div className="h-full w-full bg-[#E6E6E6] rounded-full flex justify-center items-center">
              <div className="h-[50%] w-[50%] bg-white rounded-full flex flex-col justify-center items-center gap-2">
                <GlobalSkeleton
                  animationValue="wave"
                  variant="text"
                  sx={{ fontSize: '20px' }}
                  width={'50%'}
                />
                <GlobalSkeleton
                  animationValue="wave"
                  variant="text"
                  sx={{ fontSize: '20px' }}
                  width={'50%'}
                />
              </div>
            </div>
          </div>
        ) : (
          <ResponsivePie
            data={data}
            margin={{ top: 15, right: 15, bottom: 15, left: 15 }} // Increase margins
            startAngle={0}
            colors={colors}
            innerRadius={innerRadius || 0.6}
            padAngle={padAngle || 0.7}
            cornerRadius={6}
            activeOuterRadiusOffset={8}
            isInteractive={isInteractive}
            enableArcLinkLabels={
              hoveredSliceId !== null ? true : enableArcLinkLabels
            }
            arcLinkLabel={(d: any) =>
              hoveredSliceId === d.id ? arcLinkLabel(d) : ''
            }
            tooltip={() => null}
            arcLinkLabelsDiagonalLength={diagonalLength}
            arcLinkLabelsStraightLength={straightLength}
            arcLinkLabelsSkipAngle={0}
            arcLinkLabelsTextOffset={3}
            arcLinkLabelsTextColor={{
              from: 'color',
              modifiers: [['darker', 1]],
            }}
            arcLinkLabelsColor={getArcLinkLabelColor}
            enableArcLabels={enableArcLabels || false}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.2]],
            }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0)',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            layers={[
              'arcs',
              'arcLabels',
              'arcLinkLabels',
              'legends',
              // (props) => (
              //   <CenteredText {...props} totalPercentage={totalPercentage} />
              // ),
            ]}
            legends={[]}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
      </div>
    );
  }
);
