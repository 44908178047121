import { useLocation } from 'react-router-dom';

type QueryParams = {
  [key: string]: string | null;
};

const useQueryParams = (keys: string[]): QueryParams => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const result: QueryParams = {};
  keys.forEach((key) => {
    result[key] = params.get(key);
  });

  return result;
};

export default useQueryParams;
