import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  className?: string;
  onClick?: any;
  loadingType?: 'text' | 'circular';
  outlined?: boolean;
  buttonType?: 'submit' | 'button';
  disable?: boolean;
}

const LoadingButton = ({
  children,
  loading,
  className,
  onClick,
  loadingType = 'text',
  outlined = false,
  buttonType = 'submit',
  disable = false,
}: Props) => {
  return (
    <button
      className={`rounded px-6 py-3 flex justify-center items-center cursor-pointer ${disable ? `bg-gray-500` : `bg-buttonColor`} ${!disable && `shadow-[0_13px_39px_-15px_rgba(38,165,255,0.9)]`} text-white ${outlined ? 'border !border-secondary !bg-[#121212] ' : ''} ${className}`}
      disabled={loading || disable}
      onClick={onClick}
      type={buttonType || 'submit'}
    >
      {loading ? (
        <>
          {loadingType === 'text' ? (
            <span>Loading...</span>
          ) : (
            <RotatingLines
              visible={true}
              width="20"
              strokeWidth="3"
              animationDuration="0.75"
              strokeColor="white"
            />
          )}
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingButton;
