import React from 'react';
import { NoDataImage } from '../../images/Dashboard';
import {
  EmptyDepositHistoryGif,
  EmptySwapHistoryGif,
  EmptyWithdrawHistoryGif,
} from '../../images/wallets';
import GlobalButton from '../Global/GlobalButton';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';

type Props = {
  visible?: boolean;
  text?: string;
  textClassName?: string;
  isWallet?: boolean;
  selectedButton?: string;
  hide?: boolean;
};

const NoData = ({
  visible,
  text = 'No History',
  textClassName,
  isWallet = false,
  selectedButton = 'Withdraw',
  hide = false
}: Props) => {
  const navigate = useNavigate();

  if (hide)
    return;
  if (!visible)
    return (
      <div className="flex flex-1 justify-center items-center self-center h-[12rem] text-primary">
        <div className="flex flex-col gap-5">
          <Spinner className="animate-spin h-6" />
        </div>
      </div>
    );
  if (isWallet)
    return (
      <div className="flex-1 flex justify-center items-center w-full xxs:px-2 sm:px-0 py-5 md:py-10 lg:py-24">
        <div className="flex flex-col gap-6 items-center">
          <div className="xxs:w-40 sm:w-44 h-auto">
            <img
              src={
                selectedButton === 'Withdraw'
                  ? EmptyWithdrawHistoryGif
                  : selectedButton === 'Deposit'
                    ? EmptyDepositHistoryGif
                    : EmptySwapHistoryGif
              }
              alt="empty transactions"
            />
          </div>
          <div className="flex flex-col gap-2 items-center">
            {selectedButton === 'Withdraw' ? (
              <span className="text-primary xxs:text-sm  sm:text-[18px] font-medium">
                Sorry, No Withdraw History Found.
              </span>
            ) : selectedButton === 'Deposit' ? (
              <span className="text-primary xxs:text-sm  sm:text-[18px] font-medium">
                Sorry, No Deposit History Found.
              </span>
            ) : (
              <span className="text-primary xxs:text-sm  sm:text-[18px] font-medium">
                Sorry, No Swap History Found.
              </span>
            )}

            {/* <span className="text-[#7D7D7D] xxs:text-[10px]  sm:text-xs font-normal">
              Its getting boring in here. Time to treat yourself.
            </span> */}
            {/* <GlobalButton
              text="Deposit Now"
              // outlined={true}
              // animate={true}
              classNames={`!text-white  font-normal rounded-lg !px-5 !w-auto `}
              onClick={() => navigate('/wallet/deposit')}
            /> */}
          </div>
        </div>
      </div>
    );

  return (
    <div className="flex flex-col justify-center items-center h-full min-h-[250px] gap-2">
      <img src={NoDataImage} alt="No Data" className="max-w-full h-auto" />
      <span className={`text-sm text-gray-500 ${textClassName}`}>{text}</span>
    </div>
  );
};

export default NoData;
