import {
  IActiveFirstLineUser,
  ISuperNodeSuperNodeRewardsList,
  TRewardAnalytics,
  UserStatsData,
} from '../types/superNodes.type';

export const rewardAnalyticsData: TRewardAnalytics = [
  {
    label: 'Total Rewards',
    value: 0,
    unit: 'LYK-W',
    bigText: true,
  },
  {
    label: 'Base Referral Rewards',
    value: 0,
    unit: 'LYK-W',
  },
  {
    label: 'Builder Generational',
    value: 0,
    unit: 'LYK-W',
  },
  {
    label: 'Builder Referral * 2',
    value: 0,
    unit: 'LYK-W',
  },
  {
    label: 'Claimable Rewards',
    value: 0,
    btn: true,
    unit: 'LYK-W',
  },
];
export const myTreeRewardData: TRewardAnalytics = [
  {
    label: 'Total Nodes',
    value: 0,
    bigText: true,
  },
  { label: 'No. of Nodes in 1st Line', value: 0 },
  {
    label: 'No. of Active Nodes in First Line',
    value: 0,
    childs: [{ label: 'Base Referral' }, { label: 'builder Generational' }],
  },
  {
    label: 'Total Active Nodes',
    value: 0,
    childs: [{ label: 'Base Referral' }, { label: 'builder Generational' }],
  },
];
export const sngpScoreData: TRewardAnalytics = [
  {
    label: 'Global SNGP',
    value: 0,
  },
  {
    label: 'Country Pool',
    value: 0,
  },
  {
    label: 'Country Pool',
    value: 0,
  },
  {
    label: 'Country Pool',
    value: 0,
  },
  {
    label: 'Country Pool',
    value: 0,
  },
];

export const userStatsArray: UserStatsData[] = [
  { title: 'My Daily Rewards', value: 2000, blackBG: false, token: '' },
  { title: 'Daily Production', value: 1000, blackBG: true, token: '' },
  { title: 'My Total Rewards', value: 20050, blackBG: false, token: '' },
  { title: 'Total Production', value: 12000, blackBG: true, token: '' },
];
export const dailyProductionChartData = [
  {
    id: 'japan',
    color: 'hsl(151, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 181,
      },
      {
        x: 'helicopter',
        y: 221,
      },
      {
        x: 'boat',
        y: 37,
      },
      {
        x: 'train',
        y: 91,
      },
      {
        x: 'subway',
        y: 234,
      },
      {
        x: 'bus',
        y: 45,
      },
      {
        x: 'car',
        y: 20,
      },
      {
        x: 'moto',
        y: 70,
      },
      {
        x: 'bicycle',
        y: 158,
      },
      {
        x: 'horse',
        y: 171,
      },
      {
        x: 'skateboard',
        y: 107,
      },
      {
        x: 'others',
        y: 59,
      },
    ],
  },
  {
    id: 'france',
    color: 'hsl(326, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 295,
      },
      {
        x: 'helicopter',
        y: 175,
      },
      {
        x: 'boat',
        y: 73,
      },
      {
        x: 'train',
        y: 168,
      },
      {
        x: 'subway',
        y: 247,
      },
      {
        x: 'bus',
        y: 250,
      },
      {
        x: 'car',
        y: 208,
      },
      {
        x: 'moto',
        y: 168,
      },
      {
        x: 'bicycle',
        y: 204,
      },
      {
        x: 'horse',
        y: 74,
      },
      {
        x: 'skateboard',
        y: 62,
      },
      {
        x: 'others',
        y: 95,
      },
    ],
  },
  {
    id: 'us',
    color: 'hsl(284, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 245,
      },
      {
        x: 'helicopter',
        y: 214,
      },
      {
        x: 'boat',
        y: 71,
      },
      {
        x: 'train',
        y: 100,
      },
      {
        x: 'subway',
        y: 211,
      },
      {
        x: 'bus',
        y: 80,
      },
      {
        x: 'car',
        y: 120,
      },
      {
        x: 'moto',
        y: 42,
      },
      {
        x: 'bicycle',
        y: 218,
      },
      {
        x: 'horse',
        y: 288,
      },
      {
        x: 'skateboard',
        y: 294,
      },
      {
        x: 'others',
        y: 256,
      },
    ],
  },
  {
    id: 'germany',
    color: 'hsl(339, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 270,
      },
      {
        x: 'helicopter',
        y: 36,
      },
      {
        x: 'boat',
        y: 138,
      },
      {
        x: 'train',
        y: 37,
      },
      {
        x: 'subway',
        y: 1,
      },
      {
        x: 'bus',
        y: 206,
      },
      {
        x: 'car',
        y: 72,
      },
      {
        x: 'moto',
        y: 221,
      },
      {
        x: 'bicycle',
        y: 108,
      },
      {
        x: 'horse',
        y: 30,
      },
      {
        x: 'skateboard',
        y: 38,
      },
      {
        x: 'others',
        y: 207,
      },
    ],
  },
  {
    id: 'norway',
    color: 'hsl(142, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 44,
      },
      {
        x: 'helicopter',
        y: 24,
      },
      {
        x: 'boat',
        y: 195,
      },
      {
        x: 'train',
        y: 37,
      },
      {
        x: 'subway',
        y: 296,
      },
      {
        x: 'bus',
        y: 33,
      },
      {
        x: 'car',
        y: 268,
      },
      {
        x: 'moto',
        y: 4,
      },
      {
        x: 'bicycle',
        y: 87,
      },
      {
        x: 'horse',
        y: 29,
      },
      {
        x: 'skateboard',
        y: 226,
      },
      {
        x: 'others',
        y: 97,
      },
    ],
  },
];

export const SuperNodeRewardDummyData: ISuperNodeSuperNodeRewardsList[] = [
  {
    _id: '11232',
    user: 'test user',
    fromUser: {
      _id: 'abc123def456',
      email: 'sampleuser@example.com',
      blockchainId: '0x1234abcd5678efgh',
      username: 'sampleuser',
      firstName: 'John',
      lastName: 'Doe',
      profilePicture: 'https://example.com/profile.jpg', // or null if no picture
      createdAt: '2024-08-21T12:34:56Z',
      updatedAt: '2024-08-21T12:34:56Z',
      dateJoined: '2024-08-21',
    },
    type: 'base-refreal',
    amount: 99999,
    tokenAmount: 12.555,
    receivable: true,
    lostReason: 'failed',
    gaskRemaining: 12.44,
    loss: 2555,
    claimed: false,
    rewardData: { amount: 1111, percentage: 80, currentLevel: 5 },
    cloudkTrx: 'aaaa',
    createdAt: 'aaaa',
    updatedAt: new Date(),
    token: {
      _id: '1234567890abcdef',
      name: 'Sample Token',
      symbol: 'SMP',
      type: 'ERC20',
      withdrawType: 'automatic',
      color: '#FF5733', // A sample color hex code
      networks: ['Ethereum', 'Binance Smart Chain'], // Sample networks
      iconUrl: 'https://example.com/icon.png', // A sample icon URL
    },
  },

  {
    _id: '11232',
    user: 'test user',
    fromUser: {
      _id: 'abc123def456',
      email: 'sampleuser@example.com',
      blockchainId: '0x1234abcd5678efgh',
      username: 'sampleuser',
      firstName: 'John',
      lastName: 'Doe',
      profilePicture: 'https://example.com/profile.jpg', // or null if no picture
      createdAt: '2024-08-21T12:34:56Z',
      updatedAt: '2024-08-21T12:34:56Z',
      dateJoined: '2024-08-21',
    },
    type: 'base-refreal',
    amount: 99999,
    tokenAmount: 12.555,
    receivable: true,
    lostReason: 'failed',
    gaskRemaining: 12.44,
    loss: 2555,
    claimed: false,
    rewardData: { amount: 1111, percentage: 80, currentLevel: 5 },
    cloudkTrx: 'aaaa',
    createdAt: 'aaaa',
    updatedAt: new Date(),
    token: {
      _id: '1234567890abcdef',
      name: 'Sample Token',
      symbol: 'SMP',
      type: 'ERC20',
      withdrawType: 'automatic',
      color: '#FF5733', // A sample color hex code
      networks: ['Ethereum', 'Binance Smart Chain'], // Sample networks
      iconUrl: 'https://example.com/icon.png', // A sample icon URL
    },
  },
  {
    _id: '11232',
    user: 'test user',
    fromUser: {
      _id: 'abc123def456',
      email: 'sampleuser@example.com',
      blockchainId: '0x1234abcd5678efgh',
      username: 'sampleuser',
      firstName: 'John',
      lastName: 'Doe',
      profilePicture: 'https://example.com/profile.jpg', // or null if no picture
      createdAt: '2024-08-21T12:34:56Z',
      updatedAt: '2024-08-21T12:34:56Z',
      dateJoined: '2024-08-21',
    },
    type: 'base-refreal',
    amount: 99999,
    tokenAmount: 12.555,
    receivable: true,
    lostReason: 'failed',
    gaskRemaining: 12.44,
    loss: 2555,
    claimed: false,
    rewardData: { amount: 1111, percentage: 80, currentLevel: 5 },
    cloudkTrx: 'aaaa',
    createdAt: 'aaaa',
    updatedAt: new Date(),
    token: {
      _id: '1234567890abcdef',
      name: 'Sample Token',
      symbol: 'SMP',
      type: 'ERC20',
      withdrawType: 'automatic',
      color: '#FF5733', // A sample color hex code
      networks: ['Ethereum', 'Binance Smart Chain'], // Sample networks
      iconUrl: 'https://example.com/icon.png', // A sample icon URL
    },
  },
];
