import React, { ReactNode, useState, useEffect } from 'react';

interface HoverBorderAnimationProps {
  children: ReactNode;
  className?: string;
  hoverAnimation?: boolean;
  animationDuration?: number;
}

const HoverBorderAnimation: React.FC<HoverBorderAnimationProps> = ({
                                                                     children,
                                                                     className,
                                                                     animationDuration = 700,
                                                                     hoverAnimation = true,
                                                                   }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isHovered) {
      timer = setTimeout(() => {
        setIsHovered(false);
      }, animationDuration);
    }

    return () => clearTimeout(timer);
  }, [isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`${className} ${hoverAnimation && isHovered ? 'cardHoverEffect' : ''}`}
      style={{ animationDuration: `${animationDuration / 1000}s !important;` }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export default HoverBorderAnimation;
