import {
  setCountryPool,
  setMyScoreHistoryLoading,
  setTokenType,
} from './poolSlice';
import { useDispatch } from 'react-redux';
import {
  POOL_REWARD_HISTORY,
  MY_SCORE,
  POOLS_SCORE_HISTORY,
  UPCOMING_POOLS,
  ACTIVE_POOLS,
  TOKEN_TYPE,
  GET_COUNTRY_BANNER,
  COUNTRY_CLAIM,
  GET_SNGP_POOL_HISTORY,
} from './../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import {
  setUpComingPools,
  setRewardHistory,
  setScoreHistory,
  setMyScore,
  setActivePool,
  setMyScoreHistory,
} from './poolSlice';
import { TFilter } from '../../types/countryPool.type';
import { toast } from 'react-toastify';

export const useCountryPoolActions = () => {
  const dispatch = useDispatch();
  const getCountryBannerData = async () => {
    dispatch(
      setCountryPool({
        countryBannerLoading: true,
      })
    );
    try {
      const response = await ApiRequestHomnifi().request({
        method: 'GET',
        url: GET_COUNTRY_BANNER,
      });
      if (response.status === 200) {
        dispatch(
          setCountryPool({
            countryBannerData: response.data.data.data,
            countryBannerToken: response.data.data.rewardToken,
            countryBannerLoading: false,
          })
        );

        return response.data.data;
      }
    } catch (error: any) {
      if (error?.data) {
        toast.error(error?.data.message);
      }
      dispatch(
        setCountryPool({
          countryBannerLoading: false,
        })
      );
      return error;
    }

    //  .then((response) => {
    //    const {
    //      data: { data },
    //    } = response;

    //  dispatch(
    //    setSNGPPoolSlice({
    //      activity,
    //      totalSngp,
    //      totalClaimed,
    //      sngpRecords,
    //    })
    //  );
  };
  const getCountryPool = async ({
    page,
    limit,
    query,
    fromDate,
    toDate,
  }: TFilter) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: ACTIVE_POOLS,
        params: {
          page,
          limit,
          query,
          fromDate,
          toDate,
        },
      })
      .then((response: any) => {
        const { data } = response?.data;
        dispatch(setActivePool(data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getRewardHistory = async ({
    page,
    limit,
    query,
    fromDate,
    toDate,
    type,
  }: TFilter) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: POOL_REWARD_HISTORY,
        params: {
          page,
          limit,
          query,
          fromDate,
          toDate,
          type,
        },
      })
      .then((response: any) => {
        dispatch(setRewardHistory(response?.data?.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getScoreHistory = async ({
    page,
    limit,
    query,
    fromDate,
    toDate,
  }: TFilter) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: POOLS_SCORE_HISTORY,
        params: {
          page,
          limit,
          query,
          fromDate,
          toDate,
        },
      })
      .then((response: any) => {
        dispatch(setScoreHistory(response?.data?.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getUpComingPool = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: UPCOMING_POOLS,
      })
      .then((response: any) => {
        dispatch(setUpComingPools(response?.data?.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getMyScore = async ({ year }: { year: string | Date }) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: MY_SCORE,
        params: {
          year,
        },
      })
      .then((response: any) => {
        dispatch(setMyScore(response?.data?.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const getTokenType = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: TOKEN_TYPE,
      })
      .then((response: any) => {
        dispatch(setTokenType(response?.data?.data));
        return response;
      })
      .catch((error: any) => error);
  };

  const claimCountryPoolReward = async () => {
    dispatch(
      setCountryPool({
        claimLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: COUNTRY_CLAIM,
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch(
            setCountryPool({
              countryBannerData: [],
              claimLoading: false,
            })
          );
          toast.success('Reward claimed successfully!');
        }

        return response;
      })
      .catch((error) => {
        dispatch(
          setCountryPool({
            claimLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getMyScoreHistory = async ({
    page,
    limit,
    query,
    fromDate,
    toDate,
    type = 'country-pool',
  }: TFilter) => {
    dispatch(setMyScoreHistoryLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: GET_SNGP_POOL_HISTORY,
        params: { page, type, limit, query, fromDate, toDate },
      })
      .then((response) => {
        dispatch(setMyScoreHistory(response?.data?.data));
        dispatch(setMyScoreHistoryLoading(false));
        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  return {
    getRewardHistory,
    getUpComingPool,
    getScoreHistory,
    getCountryPool,
    getTokenType,
    getMyScore,
    getCountryBannerData,
    claimCountryPoolReward,
    getMyScoreHistory,
  };
};
