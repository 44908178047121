import { Dialog, IconButton } from '@mui/material';
import React from 'react';
import { verified } from '../../images/others';
import LoadingButton from '../button/LoadingButton';

const GlobalSuccess = ({
  open,
  closeDialog,
  title = 'Congratulations',
  message = 'Your transaction has completed successfully',
}: {
  open: boolean;
  closeDialog: () => void;
  title?: string;
  message?: string;
}) => {
  return (
    <Dialog open={open} onClose={closeDialog}>
      <div className="px-16 md:px-28 py-8  flex flex-col gap-4 bg-[#1a1a1a] relative">
        <div
          className={`flex justify-end w-full items-center absolute top-0 right-0 `}
        >
          <IconButton onClick={closeDialog}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M16.5 5.5L5.5 16.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.5 5.5L16.5 16.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="flex flex-col items-center gap-4">
          <img src={verified} alt="Subscribe to Newsletter" />
          <div className="flex flex-col gap-2 items-center">
            <h1 className="text-xl font-medium">{title || 'title'}</h1>
            <p className="text-center text-sm text-textSecondary">
              {message || 'Message'}
            </p>
          </div>
          <div className="flex flex-col items-center w-full">
            <LoadingButton
              className="w-full"
              loadingType="circular"
              onClick={closeDialog}
            >
              Continue
            </LoadingButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GlobalSuccess;
