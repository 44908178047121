import {
  WebPlatformsLogo1,
  WebPlatformsLogo2,
  WebPlatformsLogo3,
} from '../images/ExternalApp';

export const userDetails = {
  firstName: 'John',
  lastName: 'Doe',
  phone: '123-456-7890',
  accountType: 'PERSONAL',
};

export const companyAddress = {
  country: 'USA',
  state: 'California',
  addressLine1: '123 Street',
  city: 'San Francisco',
  zipcode: '12344444',
};

export const billingAddress = {
  country: 'Canada',
  state: 'Ontario',
  addressLine1: '456 Avenue',
  city: 'San Francisco',
  zipcode: '12344444',
};

export const PLATFORMS = [
  {
    heading: 'Logo Ipsum Platform',
    description: ` Lorem ipsum dolor sit amet consectetur. Adipiscing molestie ut
                sit cursus dui laoreet. Consectetur volutpat sit at leo
                dictumst.`,
    image: WebPlatformsLogo1,
    buttonText: 'Enroll',
    isFavourite: false,
    isButtonFill: true,
  },
  {
    heading: 'Logo Ipsum Platform',
    description: `Logo Lorem ipsum dolor sit amet consectetur. Adipiscing molestie ut sit cursus dui laoreet. Consectetur volutpat sit at leo dictumst.`,
    image: WebPlatformsLogo1,
    buttonText: 'Enroll',
    isFavourite: false,
    isButtonFill: true,
  },
  {
    heading: 'Logo Ipsum Platform',
    description: `Lorem ipsum dolor sit amet consectetur. Adipiscing molestie ut sit cursus dui laoreet.`,
    image: WebPlatformsLogo2,
    buttonText: 'Launch',
    isFavourite: true,
    isButtonFill: false,
  },
  {
    heading: 'Logo Ipsum Platform',
    description: `Lorem ipsum dolor sit amet consectetur. Adipiscing molestie ut sit cursus dui laoreet.`,
    image: WebPlatformsLogo2,
    buttonText: 'Launch',
    isFavourite: true,
    isButtonFill: false,
  },
  {
    heading: 'Logo Ipsum Platform',
    description: `Lorem ipsum dolor sit amet consectetur. Adipiscing molestie ut sit cursus dui laoreet.`,
    image: WebPlatformsLogo3,
    buttonText: 'Launch',
    isFavourite: false,
    isButtonFill: false,
  },
  {
    heading: 'Logo Ipsum Platform',
    description: `Lorem ipsum dolor sit amet consectetur. Adipiscing molestie ut sit cursus dui laoreet.`,
    image: WebPlatformsLogo3,
    buttonText: 'Launch',
    isFavourite: false,
    isButtonFill: false,
  },
];
