import React from 'react';
import { InfoPoolIcon } from '../../images/svgs';
import GlobalTooltip from '../Tooltips';
import { formatDateToString } from '../../utils/helper';

const GlobalImageCardList = ({
  country,
  date,
  component,
}: {
  country: string;
  date: string;
  component: React.ReactNode;
}) => {
  return (
    <div className="flex justify-between text-xs lg:text-sm  xl:!text-md   bg-[#FAFAFA] p-4 items-center   mb-3 rounded-lg border border-[#E6E6E6] shadow-sm shadow-[#E6E6E6] ">
      {/* <div className="flex gap-4 justify-between items-center p-4"> */}
      <div className="w-6 lg:w-9">{component}</div>
      <p className="!font-normal text-xs lg:text-sm ">{country}</p>
      <p className="!text-primaryGrey  !font-normal text-xs lg:text-sm">
        {formatDateToString(new Date(date))}
      </p>
      {/* </div> */}
      <div className="flex justify-end items-start self-start  ">
        <GlobalTooltip
          content={`Coming soon!`}
          placement="top-end"
          containerClass="!self-start !text-[10px]"
          showArrow={true}
          classContainer={{
            base: [
              // arrow color
              'before:bg-white before:z-1 before:border-b-[1px] before:border-r-[1px] before:border-[#007AFF33]',
            ],
            content: [
              'py-4 px-2 shadow-sm',
              'text-black bg-white rounded-md border-[1px] text-[10px] max-w-[150px] border-[#007AFF33]  ',
            ],
          }}
        >
          <InfoPoolIcon></InfoPoolIcon>
        </GlobalTooltip>
      </div>
    </div>
  );
};

export default GlobalImageCardList;
