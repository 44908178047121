import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../store/cloudk/cloudkSlice';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';
import GlobalTextInput from '../../../../components/Global/GlobalTextInput';
import GlobalButton from '../../../../components/Global/GlobalButton';
import GlobalSelectMui from '../../../../components/Global/GlobalSelectMui';
import ImageWithFallback from '../Common/ImageWithFallback/ImageWithFallback';
import { hardwareImg } from '../../../../images/cloudk';

const LinkedLicense = ({ minter, handleClose }: any) => {
  const [minterName, setMinterName] = useState(minter.minterData.name);
  const { allLicencesData, linkLicenseWithMinterLoading } =
    useSelector(selectCloudSlice);
  const {
    getAllLicenses,
    LinkLicenseWithMinterFunc,
    getUserMinterLinkedLicenses,
  } = useCloudkActions();

  const [selectedOption, setSelectedOption] = useState<any>(null);

  useEffect(() => {
    getAllLicenses();
  }, []);

  return (
    <div className="flex flex-col gap-2  px-6 pb-6">
      <div className="grid grid-cols-12 gap-4 items-center">
        <div className="col-span-12 sm:col-span-6 order-2 sm:order-1 flex flex-col gap-2 w-full">
          <div className="text-defaultBlack text-sm font-medium">
            Minter Name <span className="text-redDanger">*</span>
          </div>
          <div className="w-full relative">
            <GlobalTextInput
              name="name"
              placeholder="Enter minter name"
              type="text"
              className="!rounded-[50px] bg-gray-50"
              animationClasses="!rounded-[50px]"
              value={minterName || ''}
              style={{ cursor: 'default', appearance: 'none' }}
              errorStyle="!text-left"
              onChange={(e) => !minterName && setMinterName(e.target.value)}
              disabled={minterName ? true : false}
            />
          </div>
        </div>

        <div className="col-span-12 sm:col-span-6 order-1 sm:order-2 flex justify-center sm:justify-end">
          <div className="w-64 h-36">
            <ImageWithFallback
              src={minter.minterData.documents[0].link}
              alt="hardwareImage"
              classNames="h-full w-full"
              fallbackSrc={''}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex-1 flex items-start justify-between flex-col gap-5">
          <div className="flex flex-col gap-2 w-full">
            <div className="text-defaultBlack text-sm font-medium">
              Select a license
              {/* <span className="text-redDanger">*</span> */}
            </div>
            {
              <>
                <GlobalSelectMui
                  required={true}
                  className={`!rounded-[50px]`}
                  showPropName={'label'}
                  options={
                    allLicencesData?.list
                      ? allLicencesData?.list?.map((item: any) => ({
                          ...item,
                          label: item.name,
                          value: item._id,
                        }))
                      : []
                  }
                  selectedOption={selectedOption}
                  setSelectedOption={(option: any) => {
                    setSelectedOption(option);
                  }}
                  placeHolder={'Select a license'}
                  firstAutoSelected={false}
                />
              </>
            }
          </div>
        </div>
      </div>

      <div className="mt-3 w-full">
        <GlobalButton
          text="Connect License"
          type="button"
          loading={linkLicenseWithMinterLoading}
          disabled={
            !linkLicenseWithMinterLoading && minterName && selectedOption
              ? false
              : true
          }
          containerClassName={'w-full'}
          classNames={`!w-full rounded-[10px] !text-base !py-[10px] px-5 min-w-max place-item-end 
               ${!linkLicenseWithMinterLoading && minterName && selectedOption ? '!bg-updateBtnGredient' : 'bg-[#C0D5DF]'} font-medium !text-[#FFFFFF]`}
          onClick={async () => {
            const data = {
              minterId: minter?._id,
              licenseId: selectedOption?._id,
            };
            const response = await LinkLicenseWithMinterFunc(data);
            if (response?.status) {
              handleClose();
              getUserMinterLinkedLicenses({
                page: 1,
                limit: 10,
                id: minter?._id,
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default LinkedLicense;
