import React from 'react';
import AppTooltip from '../../pages/ExternalApp/AppTooltip';
import Tooltips from '../Tooltips';

interface AppsSwiperProps {
  cards1: any[];
  loginClick: (id: string) => void;
}

const DesktopPlatforms = ({ cards1, loginClick }: AppsSwiperProps) => {
  return (
    <div className="w-full hidden flex-nowrap overflow-hidden justify-end lg:inline-flex">
      {Array.from({ length: 3 }, (_, i) => (
        <div className="flex flex-col gap-4 items-center h-80 group" key={i}>
          <ul
            className={`flex flex-col gap-4 items-center justify-center md:justify-start [&_li]:mx-4 2xl:[&_li]:mx-8 [&_img]:max-w-none
                      ${i === 1 ? 'animate-infinite-scroll-reverse' : 'animate-infinite-scroll'} group-hover:animation-play-state-paused
                      cursor-pointer
            `}
          >
            {cards1.map((card, index) => {
              return (
                <React.Fragment key={index}>
                  <Tooltips
                    placement="left"
                    content={
                      <AppTooltip
                        name={card.name}
                        version={'Web 2.0'}
                        description={''}
                      />
                    }
                  >
                    <li>
                      <img
                        src={card?.logo}
                        className="bg-white/30 h-20 w-20 p-3 rounded-lg"
                        onClick={() => loginClick(card.id)}
                        alt="cartd_logo"
                      />
                    </li>
                  </Tooltips>
                </React.Fragment>
              );
            })}
          </ul>
          <ul
            className={`flex flex-col gap-4 items-center justify-center md:justify-start [&_li]:mx-4 [&_img]:max-w-none
                      ${i === 1 ? 'animate-infinite-scroll-reverse' : 'animate-infinite-scroll'} group-hover:animation-play-state-paused
                      cursor-pointer
            `}
            aria-hidden="true"
          >
            {cards1.map((card, index) => (
              <React.Fragment key={index}>
                <Tooltips
                  placement="left"
                  content={
                    <AppTooltip
                      name={card.name}
                      version={'Web 2.0'}
                      description={card.description}
                    />
                  }
                >
                  <img
                    src={card?.logo}
                    onClick={() => loginClick(card.id)}
                    className="bg-white/30 h-20 w-20 p-3 rounded-lg"
                    alt="cartd_logo"
                  />
                </Tooltips>
              </React.Fragment>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default DesktopPlatforms;
