import React, { forwardRef, useEffect, useRef } from 'react';

interface OutsideClickHandlerProps {
  children: React.ReactElement; // Single React element for ref forwarding
  onOutsideClick: () => void;
}

const OutsideClickHandler = forwardRef<HTMLElement, OutsideClickHandlerProps>(
  ({ children, onOutsideClick }, ref) => {
    const internalRef = useRef<HTMLElement>(null);
    const combinedRef = (ref as React.RefObject<HTMLElement>) || internalRef;
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          combinedRef.current &&
          !combinedRef.current.contains(event.target as Node)
        ) {
          onOutsideClick();
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onOutsideClick, combinedRef]);

    return React.cloneElement(children, { ref: combinedRef });
  }
);

OutsideClickHandler.displayName = 'OutsideClickHandler';
export default OutsideClickHandler;
