import React from 'react'
import CardLayout from '../../../../../components/Cards/CardLayout'
import { tokenIcon2, totalLinkedWaterMark } from '../../../../../images/cloudk'
import { useSelector } from 'react-redux'
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice'
import { formatNumberWithCommas, toFixedFormat } from '../../../../../utils/utils'
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';

const TotalLinkedMlyk = () => {
    const { totalStacked, upgradeAbleMachinesLoading } = useSelector(selectCloudSlice)
    return (
        <CardLayout className="md:col-span-6 xl:col-span-3 col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow relative bg-[#FFFFFF]">
            {upgradeAbleMachinesLoading && totalStacked === 0 ? <div className="flex justify-center items-center flex-1  h-full w-full">
                <div className="justify-center items-center self-center">
                    <div className="flex flex-col gap-5">
                        <Spinner className="animate-spin h-6" />
                    </div>
                </div>
            </div> : <section className="flex flex-col gap-2 ">
                <span className='font-medium text-[#616161]'>Total Linked mLYK</span>
                <div className='flex flex-col gap-9 w-full'>
                    <div className='flex gap-2 items-center'>
                        <div className='w-8 h-8'>
                            <img src={tokenIcon2} alt={'icon'} />
                        </div>
                        <span className='font-medium text-2xl xl:text-xl 2xl:text-3xl sm:text-3xl '>{formatNumberWithCommas(Number(toFixedFormat(Number(totalStacked?.totalToken || 0), 2)))}<span className='text-base'>{totalStacked.tokenName}</span></span>
                    </div>
                    <div>
                        <div className={'rounded-[100px] border border-[#1B9FDA] py-1 px-2 text-[#1B9FDA] font-medium text-sm inline-flex '}>
                            ${formatNumberWithCommas(Number(toFixedFormat(Number(totalStacked?.tokenAmountInUsd || 0), 2)))} (Amount in USD)
                        </div>
                    </div>

                </div>

            </section>}

            <img src={totalLinkedWaterMark} alt={'water-mark'} className='absolute top-2 right-6 h-full' />

        </CardLayout>
    )
}

export default TotalLinkedMlyk
