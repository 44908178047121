//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const MAX_NOTIFICATIONS = 5;

interface Notification {
  list: [];
  badge: number;
  userActivity: {
    list: any[];
    paginate: any;
    pageLoaded: number;
  };
  badgeUpdated: boolean;
  homnifiNotificationList: any[];
  homnifiAllNotificationList: any[];
  newNotificationList: any[];
  notificationData: {
    totalCount: number;
    list: {
      _id: string;
      user: string;
      message: string;
      read: boolean;
      type: string;
      code: string;
      createdAt: string;
      updatedAt: string;
    }[];
    totalPages: number;
    currentPage: number;
  };
  infinitLoader: boolean;
  isNotificationOpen: boolean;
  unReadNotificationCount: number;
}

const initialState: Notification = {
  list: [],
  badge: 0,
  userActivity: {
    list: [],
    paginate: null,
    pageLoaded: 0,
  },
  badgeUpdated: false,
  homnifiNotificationList: [],
  homnifiAllNotificationList: [],
  newNotificationList: [],
  notificationData: {
    totalCount: 0,
    list: [],
    totalPages: 0,
    currentPage: 1,
  },
  infinitLoader: false,
  unReadNotificationCount: 0,
  isNotificationOpen: false,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUserActivity: (state, action: PayloadAction<any>) => {
      if (action.payload.force) {
        return {
          ...state,
          userActivity: {
            list: action.payload.activityData.list,
            paginate: action.payload.activityData.paginate,
            pageLoaded: action.payload.page,
          },
          badge: action.payload.activityData.badgeCount,
        };
      }
      if (action.payload.page > state.userActivity.pageLoaded)
        return {
          ...state,
          userActivity: {
            list: [
              ...state.userActivity.list,
              ...action.payload.activityData.list,
            ],
            paginate: action.payload.activityData.paginate,
            pageLoaded: action.payload.page,
          },
          badge:
            action.payload.page === 1
              ? action.payload.activityData.badgeCount
              : 0,
        };
    },
    setNotificationData: (state, action: PayloadAction<any>) => {
      return { ...state, list: action.payload };
    },
    setBadge: (state, action: PayloadAction<any>) => {
      return { ...state, badge: action.payload };
    },
    setBadgeUpdated: (state) => {
      return { ...state, badge: 0, badgeUpdated: true };
    },
    setIsNotificationOpen: (state, action: PayloadAction<any>) => {
      return { ...state, isNotificationOpen: action.payload };
    },
    removeNotification: (state, action: PayloadAction<any>) => {
      const newArray: any = state.list.filter(
        (item: any) => item.id !== action.payload
      );

      return { ...state, list: newArray };
    },

    setHomnifiNotificationList: (state, action: PayloadAction<any>) => {
      const list =
        action.payload.currentPage <= state.notificationData.currentPage
          ? action.payload.list
          : [...state.homnifiNotificationList, ...action.payload.list];

      const result = list.filter((itm: any) => !itm.read);
      return {
        ...state,
        homnifiNotificationList: list,
        notificationData: action.payload,
        unReadNotificationCount: result.length,
      };
    },
    setHomniFiAllNotificationList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        homnifiAllNotificationList: action.payload,
      };
    },

    setInfinitLoader: (state, action: PayloadAction<any>) => {
      return { ...state, infinitLoader: action.payload };
    },

    updateNotificationStatus: (state, action: PayloadAction<any>) => {
      const result = state.homnifiNotificationList.map((itm: any) => {
        if (itm._id === action.payload) {
          return {
            ...itm,
            read: true,
          };
        }
        return itm;
      });
      return {
        ...state,
        homnifiNotificationList: result,
        unReadNotificationCount:
          state.unReadNotificationCount <= 0
            ? 0
            : state.unReadNotificationCount - 1,
      };
    },

    setMarkAllAsRead: (state, action: PayloadAction<any>) => {
      const result = state.homnifiNotificationList.map((itm: any) => {
        return {
          ...itm,
          read: true,
        };
      });

      return {
        ...state,
        homnifiNotificationList: result,
        unReadNotificationCount: 0,
      };
    },

    setNewNotification: (state, action) => {
      const newId =
        state.newNotificationList.length > 0
          ? state.newNotificationList[state.newNotificationList.length - 1].id +
            1
          : 1;

      // Prepare the new notification with an ID
      const newNotification = {
        id: newId,
        ...action.payload,
      };

      if (state.newNotificationList.length >= MAX_NOTIFICATIONS) {
        state.newNotificationList.shift();
      }

      // Add the new notification to the list
      state.newNotificationList.push(newNotification);
    },
    removeNewNotification: (state, action) => {
      const idToRemove = action.payload; // ID of the notification to remove, if provided

      let index = -1;

      if (idToRemove !== undefined) {
        // If an ID is provided, find the index of the notification with the matching ID
        index = state.newNotificationList.findIndex(
          (notification) => notification.id === idToRemove
        );
      }

      if (index === -1) {
        // If no ID is provided or the ID is not found, remove the oldest notification
        index = 0;
      }

      // Remove the notification at the determined index
      if (index !== -1 && index < state.newNotificationList.length) {
        state.newNotificationList.splice(index, 1);
      }
    },
  },
});

export const {
  setNotificationData,
  setBadge,
  removeNotification,
  setUserActivity,
  setBadgeUpdated,
  setHomnifiNotificationList,
  setInfinitLoader,
  updateNotificationStatus,
  setMarkAllAsRead,
  setIsNotificationOpen,
  setHomniFiAllNotificationList,
  setNewNotification,
  removeNewNotification,
} = slice.actions;

export const selectNotificationData = (state: {
  notifications: Notification;
}) => state.notifications.list;
export const notification_badge = (state: { notifications: Notification }) =>
  state.notifications.badge;
export const selectUserActivities = (state: { notifications: Notification }) =>
  state.notifications.userActivity;
export const selectBadgeUpdated = (state: { notifications: Notification }) =>
  state.notifications.badgeUpdated;
export default slice.reducer;
export const selectNotification = (state: { notifications: Notification }) =>
  state.notifications;
