import { useState } from 'react';
import { useSelector } from 'react-redux';
import LineChart from '../../../../components/Charts/LineChart/index.tsx';
import GlobalDropdown from '../../../../components/Global/GlobalDropdown.tsx';
import { CloseSvg, FilterIconSvg } from '../../../../images/svgs/index.ts';
import { useSuperNodeActions } from '../../../../store/superNode/superNodeActions.ts';
import { selectSuperNodeSlice } from '../../../../store/superNode/superNodeSlice.ts';
import { ReactComponent as Spinner } from '../../../../SVGIcons/spinner.svg';
import { IMyTreeUsers } from '../../../../types/superNodes.type.ts';
import {
  TotalRewardsChartDefs,
  TotalRewardsChartFill,
} from '../../../../utils/data/LineChartData.ts';

type Props = {
  data?: any;
  detailedUser: IMyTreeUsers;
};

const DailyProductionGraphComp = ({ data, detailedUser }: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('weekly');
  const { getUserChartDetailLoading } = useSelector(selectSuperNodeSlice);
  const { getUserChartDetail } = useSuperNodeActions();

  const handleDropdownItemClick = (item: string) => {
    setSelectedMenuItem(item);
    // You can add additional logic here based on the clicked item
  };

  const FilterOptions = [
    {
      key: 'weekly',
      label: 'Weekly',
    },
    {
      key: 'monthly',
      label: 'Monthly',
    },
    {
      key: 'yearly',
      label: 'Yearly',
    },
  ];

  return (
    <div className="xl:col-span-1 col-span-2  small-scroll bg-white overflow-hidden">
      <article className="  text-cardPrimaryText font-normal text-base flex items-center justify-between">
        <span className="text-black text-lg font-medium mb-2 xs:mb-0 sm:mb-2">
          Daily Production
        </span>
        <aside className="flex items-center gap-2 flex-col-reverse xs:flex-row ">
          {selectedMenuItem ? (
            <div className="text-extraDarkBlue flex items-center gap-2 bg-extralightGray w-max py-2 px-4 rounded-lg">
              {selectedMenuItem}{' '}
              <button onClick={() => setSelectedMenuItem('')}>
                <CloseSvg className="w-4 h-4" />
              </button>
            </div>
          ) : (
            ''
          )}
          <GlobalDropdown
            buttonText="Filters"
            StartIcon={<FilterIconSvg className="w-4 h-4" />}
            EndIcon={<></>}
            onItemClick={handleDropdownItemClick}
            data={FilterOptions || []}
            callBack={(item: string) => {
              setSelectedMenuItem(item);
              getUserChartDetail(item, detailedUser?._id);
            }}
          />
        </aside>
      </article>

      <article className="w-full h-[400px] ">
        {getUserChartDetailLoading ? (
          <div className="flex justify-center items-center flex-1  h-full w-full">
            <div className="justify-center items-center self-center">
              <div className="flex flex-col gap-5">
                <Spinner className="animate-spin h-6" />
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full w-full">
            <LineChart
              // data={TotalRewardsChartData}
              data={[{ id: 'graph', data }]}
              // data={[{ id: "USDK", data: rewardChart }]}
              defs={TotalRewardsChartDefs}
              fill={TotalRewardsChartFill}
              margin={{ top: 50, right: 20, bottom: 50, left: 50 }}
              isInteractive={true}
              enablePoints={false}
              enableGridX={true}
              enableGridY={true}
              enableArea={true}
              colors={['#0E173F']}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickValues: 5,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
              }}
              theme={{
                text: {
                  fontSize: '12px',
                  fill: '#AAAAAA',
                },
                grid: {
                  line: {
                    stroke: '#E0E0E0', // Ensure this is a visible color
                    strokeWidth: 1,
                    // strokeDasharray: [6, 4],
                  },
                  top: {
                    line: {
                      stroke: 'transparent', // Make the top grid line invisible
                      strokeWidth: 0,
                    },
                  },
                },
                axis: {
                  ticks: {
                    line: {
                      stroke: '#FFFFFF40',
                    },
                    text: {
                      fontSize: '8px',
                      fill: '#7D7D7D',
                    },
                  },
                },
              }}
              legends={[]}
              axisLeft={{
                tickSize: 5,
                tickValues: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
                // tickValues: [0, 5, 10, 20, 30, 40, 50],
                format: (value: any) => `${value} LYK-W`,
              }}
              gridXValues={2} // Set to a lower number to increase grid box size along the X-axis
              gridYValues={5}
              tootlipColor={'#292929'}
              tootlipText={'LYK-W'}
              showSub={true}
              curve={'basis'}
            />
          </div>
        )}
      </article>
    </div>
  );
};

export default DailyProductionGraphComp;
