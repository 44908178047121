/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import useModalHook from '../../hooks/useModalHook';
import {
  FooterFacebook,
  FooterInstagram,
  FooterLinkedin,
  FooterYoutube,
  Logo,
  Spinner,
} from '../../images/svgs';
import { useCloudActions } from '../../store/Cloudk2/cloudk2Actions';
import { selectCloudSlice } from '../../store/Cloudk2/clouldk2Slice';
import { FOOTER_SOCIALS } from '../../utils/constants';
import DashboardBanner from './_components/DashboardBanner';
import MembershipPurchased from './_components/MembershipPurchased';
import Steps from './_components/Steps';
import { FloatingDock } from '../../components/ui/FloatingDock';
import { useWalletDataAction } from '../../store/walletData/walletDataAction';
import { useGeneralActions } from '../../store/general/generalActions';
import { useWalletActions } from '../../store/wallets/walletsAction';
import { useSuperNodeActions } from '../../store/superNode/superNodeActions';
import { selectWalletData } from '../../store/walletData/walletDataSlice';
import { selectSuperNodeSlice } from '../../store/superNode/superNodeSlice';
import { selectWallet } from '../../store/wallets/walletsSlice';
import { selectConvertedPriceLoading } from '../../store/general/generalSlice';
import { useSettingAction } from '../../store/setting/settingActions.ts';
import { selectSetting } from '../../store/setting/settingSlice.ts';

const Dashboard = (): ReactElement => {
  const { allTokensLoading, allTokens } = useSelector(selectWallet);

  const { userProducts } = useSelector(selectCloudSlice);

  const { loading, graphLoading, isWalletDataDepositSettingsLoading } =
    useSelector(selectWalletData);

  const { getPlatformSettingsLoading, platformSettings } =
    useSelector(selectSetting);

  const { getPlatformSettings } = useSettingAction();

  const {
    userRewardsLoading,
    machinePurchased,
    userPurchasedMachinesLoading,
    userGlobalAutoCompoundLoading,
    getAllProductsLoading,
    userMachinesLoading,
    machineList,
    isBurnLoading,
    userTransactionHistoryLoading,
  } = useSelector(selectCloudSlice);

  const {
    rewardTreeLoading,
    superNodeSuperNodeRewardsLoading,
    getActiveFirstLineUsersLoading,
    getSuperNodeRewardLoading,
    getSngpScoreLoading,
    getProductionLoading,
    getRewardAnalyticsLoading,
    getTotalGaskLoading,
    getCurrentLossOfRewardsLoading,
    getMyTreeLoading,
    isUserLoading,
    userActiveStatus,
  } = useSelector(selectSuperNodeSlice);

  const isConvertedPriceLoading: boolean = useSelector(
    selectConvertedPriceLoading
  );

  const {
    getWalletDataWalletsList,
    getWalletGraphData,
    getWalletDataDepositSettings,
  } = useWalletDataAction();

  const {
    getUserStatus,
    getMyTreeRewards,
    getCurrentLossOfRewards,
    getTotalGask,
    getRewardAnalytics,
    getTotalProduction,
    getSngpScore,
    getSuperNodeDailyRewards,
    getActiveFirstLineUsers,
    getRewardsTableData,
    getRewardsTree,
  } = useSuperNodeActions();

  const { getAllTokens } = useWalletActions();

  const {
    getUserRewardsData,
    getGlobalAutoCompound,
    getAllProducts,
    getUserMachinesList,
    getUserPurchasedMachines,
    getUserMachineRewards,
    getBurnUserStatus,
  } = useCloudActions();

  const { GetConvertedPrice } = useGeneralActions();

  const { handleOpen } = useModalHook();
  const [activeStep, setActiveStep] = React.useState<string>('start');

  const fetchUserMachines = async (): Promise<void> => {
    await getUserPurchasedMachines();
  };

  useLayoutEffect((): void => {
    if (!machinePurchased) {
      fetchUserMachines();
      handleOpen();
    }
  }, []);

  useEffect((): void => {
    if (!userProducts) {
      getWalletDataWalletsList();

      if (!getPlatformSettingsLoading && !platformSettings) {
        getPlatformSettings();
      }
      // if (!graphLoading) {
      //   getWalletGraphData();
      // }
      // if (!userRewardsLoading) {
      //   getUserRewardsData();
      // }
      // if (!userGlobalAutoCompoundLoading) {
      //   getGlobalAutoCompound();
      // }
      if (!getAllProductsLoading && !userProducts) {
        getAllProducts();
      }
      if (!userMachinesLoading && !machineList) {
        getUserMachinesList();
      }
      if (!isConvertedPriceLoading) {
        GetConvertedPrice();
      }
      // if (!isBurnLoading) {
      //   getBurnUserStatus();
      // }
      // if (!userTransactionHistoryLoading) {
      //   getUserMachineRewards({
      //     limit: 10,
      //     page: 1,
      //     type: '',
      //     date: '',
      //     machine: '',
      //   });
      // }
      // if (!isWalletDataDepositSettingsLoading) {
      //   getWalletDataDepositSettings('deposit');
      // }
      if (!allTokensLoading && !allTokens) {
        getAllTokens();
      }
      if (!isUserLoading && !userActiveStatus) {
        getUserStatus();
      }
      // if (!getMyTreeLoading) {
      //   getMyTreeRewards();
      // }
      // if (!getCurrentLossOfRewardsLoading) {
      //   getCurrentLossOfRewards();
      // }
      // if (!getTotalGaskLoading) {
      //   getTotalGask();
      // }
      // if (!getRewardAnalyticsLoading) {
      //   getRewardAnalytics();
      // }
      // if (!getProductionLoading) {
      //   getTotalProduction();
      // }
      // if (!getSngpScoreLoading) {
      //   getSngpScore();
      // }
      // if (!getSuperNodeRewardLoading) {
      //   getSuperNodeDailyRewards();
      // }
      // if (!getActiveFirstLineUsersLoading) {
      //   getActiveFirstLineUsers({ page: 1, limit: 10, type: 'base-referral' });
      // }
      // if (!superNodeSuperNodeRewardsLoading) {
      //   getRewardsTableData({ page: 1, limit: 10 });
      // }
      // if (!rewardTreeLoading) {
      //   getRewardsTree({ depth: 1 });
      // }
    }
  }, []);

  const dockerItems: { title: string; icon: ReactElement; href: string }[] = [
    {
      title: 'Facebook',
      icon: <FooterFacebook className="w-4 lg:w-6" />,
      href: FOOTER_SOCIALS.facebook || '',
    },
    {
      title: 'Instagram',
      icon: <FooterInstagram className="w-4 lg:w-6" />,
      href: FOOTER_SOCIALS.instagram || '',
    },
    {
      title: 'LinkedIn',
      icon: <FooterLinkedin className="w-4 lg:w-6" />,
      href: FOOTER_SOCIALS.linkedIn || '',
    },
    {
      title: 'Youtube',
      icon: <FooterYoutube className="w-4 lg:w-6" />,
      href: FOOTER_SOCIALS.youtube || '',
    },
  ];

  return (
    <section className="flex flex-col justify-between w-full min-h-screen gap-8">
      <DashboardBanner
        membership={
          machinePurchased ? machinePurchased.isMachinePurshaed : false
        }
        activeStep={activeStep}
      />
      {userPurchasedMachinesLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Spinner className="w-16 h-16 animate-spin text-primary" />
        </div>
      ) : machinePurchased?.isMachinePurshaed ? (
        <MembershipPurchased />
      ) : (
        <Steps
          setStep={(value: string): void => {
            setActiveStep(value);
          }}
          activeStep={activeStep}
        />
      )}
      <div className="flex flex-col">
        <div className="flex items-center w-full h-20 lg:h-32 bg-[#DEF7FF]">
          <div className="flex justify-center w-full 2xl:w-[1200px] m-auto px-10">
            <Logo className="h-6 lg:h-10" />
          </div>
        </div>
        <div className="flex items-center w-full h-30 bg-gradient-to-r from-primary to-primaryLight">
          <div className="flex justify-center w-full ">
            <FloatingDock items={dockerItems}></FloatingDock>
          </div>
        </div>
        <div className="w-full flex justify-center 2xl:w-[1200px] m-auto px-10 py-2 text-xs md:text-sm text-center">
          COPYRIGHT &copy; HOMNIFI. ALL RIGHTS RESERVED.
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
