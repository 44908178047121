export const kmallImage = '/img/Support/kmall.svg';

export const quantwiseLabel = '/img/Support/quantwise.svg';
export const quantwiseImage = '/img/Support/quantwise.png';
export const quantwisePlainImage = '/img/quantwise-plain.svg';

export const wallekImage = '/img/Support/wallek.svg';

export const homnifiImage = '/img/Support/homnifi.svg';

export const xeraImage = '/img/Support/xera.svg';
export const xeraLabel = '/img/Support/xeraLabel.svg';

export const jbImage = '/img/Support/jb.svg';
export const jbLabel = '/img/Support/jbLabel.svg';

export const rampImage = '/img/Support/ramp.svg';
export const rampLabel = '/img/Support/rampLabel.svg';

export const neoImage = '/img/Support/neo.svg';
export const neoLabel = '/img/Support/neo.png';

export const zenitImage = '/img/Support/zenit.png';
export const zenitLabel = '/img/Support/zenit-logo.png';

export const homnifiIcon = '/img/Support/homnifiIcon.svg';
export const homnifiLabel = '/img/Support/homnifiImage.svg';
