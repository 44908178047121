import { MouseEventHandler, ReactNode, useState } from 'react';

interface Icons {
  default: ReactNode;
  active?: ReactNode;
}
interface ButtonProps {
  label: string;
  active?: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  icons: Icons;
}
const Button: React.FC<ButtonProps> = ({
  label,
  active = false,
  onClick,
  icons: { default: defaultIcon, active: activeIcon },
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`border flex xxs:gap-1 xs:gap-2 sm:gap-3 justify-center items-center cursor-pointer xxs:px-3 xs:px-6 sm:px-8 py-2 text-primary border-[#1B9FDA] shadow-btnDefaultShadow xxs:rounded-[8px] xxs:flex-col lg:flex-row hover:bg-primary hover:text-white hover:shadow-btnActiveShadow ${active && 'bg-primary text-white !shadow-btnActiveShadow'}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="h-4 w-4">
        {active || isHovered ? activeIcon ?? defaultIcon : defaultIcon}
      </div>
      <span>{label}</span>
    </div>
  );
};

export default Button;
