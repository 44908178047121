import { ReactElement, useEffect, useState } from 'react';

interface IStep {
  label: string;
  unit?: number;
  isHeading?: boolean;
}

const VerticalStepper: React.FC<{ steps: IStep[] }> = ({
  steps,
}): ReactElement => {
  return (
    <div className="flex flex-col items-start mt-4">
      {steps.length > 0 &&
        steps?.map(
          (step: IStep, index: number): ReactElement => (
            <div key={index} className="flex items-start w-full">
              <div className="flex flex-col items-center">
                <div className="w-2 h-2 rounded-full bg-gray-300" />
                {index < steps.length - 1 && (
                  <div className="w-px h-6 bg-gray-300"></div>
                )}
              </div>
              <div className="ml-4 -mt-2 flex w-full justify-between items-center">
                <span
                  className={`text-sm text-gray-500 ${step?.isHeading && step?.isHeading ? 'font-medium' : 'font-normal'}`}
                >
                  {step?.label}
                </span>
                {!step?.isHeading && (
                  <span
                    className={`text-cardPrimaryText text-sm  ${step?.isHeading && typeof step?.unit === 'number' ? (step?.unit ? 'text-3xl xl:text-xl 2xl:text-3xl' : 'text-3xl') : typeof step?.unit === 'number' ? (step?.unit ? 'xl:text-base 2xl:text-lg text-lg ' : 'text-lg') : 'text-sm'}`}
                  >
                    {step?.unit && step?.unit}
                  </span>
                )}
              </div>
            </div>
          )
        )}
    </div>
  );
};

const MyTree = ({ data }: { data: any }): ReactElement => {
  const step2Data: IStep[] = [
    { label: 'Total Active Users', isHeading: true },
    { label: 'Base Referral', unit: data?.activeBaseReferralTotalNode || 0 },
    {
      label: 'Builder Generational',
      unit: data?.activeBuilderGenerationTotalNode || 0,
    },
  ];

  const stepData: IStep[] = [
    { label: 'No. of Active Users in First Line', isHeading: true },
    {
      label: 'Base Referral',
      unit: data?.activeFirstLineBaseReferralNode || 0,
    },
    {
      label: 'Builder Generational',
      unit: data?.activeFirstLineBuilderGenerationNode || 0,
    },
  ];

  return (
    <div className="flex flex-col h-full gap-2 justify-evenly">
      <div className="  flex w-full justify-between items-center mt-2 ">
        <span className={`text-sm text-gray-500 font-normal`}>
          {'Total Nodes:'}
        </span>
        <span className={'text-3xl xl:text-xl 2xl:text-3xl font-semibold'}>
          {data?.totalNodes || 0}
        </span>
      </div>
      <div className="  flex w-full justify-between items-center">
        <span className={`text-sm text-gray-500 font-normal`}>
          {'No. of nodes in 1st line'}
        </span>
        <span className={'text-lg'}>{data?.firstLineNode || 0}</span>
      </div>
      <VerticalStepper steps={step2Data} />
      <VerticalStepper steps={stepData} />
    </div>
  );
};
export default MyTree;
