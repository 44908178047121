import { ReactElement } from 'react';

interface Props {
  label: string;
  value: string | ReactElement;
  valueClassName?: string;
}

const ListTile = ({ label, value, valueClassName }: Props) => (
  <div className="py-2 border-b-2 border-b-borderLightGray flex justify-between w-full flex-wrap items-center gap-y-2">
    <span>{label}</span>
    <span className={`text-[#6E6E6E] ${valueClassName} overflow-auto`}>
      {value}
    </span>
  </div>
);

export default ListTile;
