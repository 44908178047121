
import React from 'react';

const TransferIcon = ({ color = '#292929' }: any) => {
  return (
<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.375 16.0834H10.625C14.1667 16.0834 15.5833 14.6667 15.5833 11.125V6.87502C15.5833 3.33335 14.1667 1.91669 10.625 1.91669H6.375C2.83333 1.91669 1.41667 3.33335 1.41667 6.87502V11.125C1.41667 14.6667 2.83333 16.0834 6.375 16.0834Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.1479 10.2892L9.99458 12.4425" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.85208 10.2892H12.1479" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.85215 7.71126L7.00549 5.55792" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.148 7.71057H4.85215" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};

export default TransferIcon;
