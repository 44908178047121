/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useWalletDataAction } from '../../../../store/walletData/walletDataAction';
import DepositStep1 from './DepositStep1';
import DepositStep2 from './DepositStep2';
import DepositStep3Address from './DepositStep3Address';
import DepositStep3Stake from './DepositStep3Stake';
import { ReactComponent as InfoIcon } from '../../../../SVGIcons/info-circle-primary.svg';
import { ReactComponent as Spinner } from '../../../../SVGIcons/spinner.svg';
import DepositStakeRequest, { StakeRequest } from './DepositStakeRequest';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEPOSIT_SLICE_NAME,
  depositSliceAction,
} from '../../../../store/wallets/depositSlice';
import useSocketEvent from '../../../../hooks/socketIO/useSocketEvent';
import { RootState } from '../../../../store';
import GlobalDivider from '../../../../components/Global/GlobalDivider';
import { DepositTransactionResponse } from '../../../../types/wallet.types';
import useUserCheck from '../../../../hooks/useUserCheck';
import { TransactionEvents } from '../../../../utils/allConstants';
import { selectWalletData } from '../../../../store/walletData/walletDataSlice';

export enum TABS_OPTIONS {
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_AND_STAKE = `DEPOSIT_AND_STAKE`,
}

interface IDespositForm {
  setIsDeposited?: (flag: boolean) => void;
  handleDepositHistory: () => void;
}

const DepositForm: FC<IDespositForm> = ({
  setIsDeposited,
  handleDepositHistory,
}) => {
  const {
    getWalletDataDepositSettings,
    getWalletDepositStakeRequest,
    cancelWalletDepositStakeRequest,
    getWalletDataDepositHistory,
  } = useWalletDataAction();

  const [depositStakeTabSelected, setDepositStakeTabSelected] = useState(false);
  const {
    depositSettings,
    isWalletDataDepositSettingsLoading,
    depositStackSettings,
  } = useSelector(selectWalletData);
  const [depositStakeRequest, setDepositStakeRequest] =
    useState<StakeRequest | null>();
  const { user } = useUserCheck();
  const [getRequestLoading, setGetRequestLoading] = useState(false);
  const [fetchSettingsLoading, setFetchSettingsLoading] = useState(false);

  const dispatch = useDispatch();
  const { selectedNetwork }: any = useSelector(
    (state: RootState) => state[DEPOSIT_SLICE_NAME]
  );

  useEffect((): void => {
    try {
      if (depositSettings === null) setFetchSettingsLoading(true);
      getWalletDataDepositSettings('deposit');
    } catch (err) {
      console.error(err);
    } finally {
      setFetchSettingsLoading(false);
    }
    handleGetDepositStakeRequest();
  }, []);

  useEffect(() => {
    if (!isWalletDataDepositSettingsLoading) {
      if (!depositSettings) {
        getWalletDataDepositSettings('deposit');
      } else if (!depositStackSettings) {
        getWalletDataDepositSettings('deposit-and-stake');
      }
    }
  }, [depositStakeTabSelected]);
  const handleGetDepositStakeRequest = async (): Promise<void> => {
    setGetRequestLoading(true);
    const response = await getWalletDepositStakeRequest();
    if (response?.data?.data) {
      setDepositStakeRequest(response.data?.data);
      handleDepositHistory();
    }
    setGetRequestLoading(false);
  };

  const handleCancelDepositStakeRequest = async (): Promise<void> => {
    const response = await cancelWalletDepositStakeRequest();
    if (response?.status) {
      setDepositStakeRequest(null);
      handleDepositHistory();
    }
  };
  const handleResetForm = () => {
    dispatch(depositSliceAction.resetDepositSlice());
  };
  const handleSelectTap = async (tab: TABS_OPTIONS): Promise<void> => {
    handleResetForm();
    setDepositStakeTabSelected(tab === TABS_OPTIONS.DEPOSIT_AND_STAKE);
    // if (depositSettings === null) {
    //   setFetchSettingsLoading(true);
    // }
    // if (tab === TABS_OPTIONS.DEPOSIT_AND_STAKE) {
    //   await getWalletDataDepositSettings('deposit-and-stake');
    // } else {
    //   await getWalletDataDepositSettings('deposit');
    // }
    // setFetchSettingsLoading(false);
  };
console.log("++++++++",depositStakeTabSelected)
  const updateValueDeposit = (value: any) =>
    dispatch(
      depositSliceAction.setDepositSlice({
        ...value,
      })
    );

  useSocketEvent<DepositTransactionResponse>({
    eventName: `${user?.id}`,
    onData: (data: DepositTransactionResponse): void => {
      if (
        data.body.eventAction.trim() ===
        TransactionEvents.DEPOSIT_AND_STAKE_TRANSACTION_SUCCESS
      ) {
        setDepositStakeRequest(null);
        setTimeout(async (): Promise<void> => {
          await getWalletDepositStakeRequest();
        }, 3000);
      }
    },
  });

  const activeTabClasses =
    '!text-primary border-b-2 font-medium border-primary';
  return (
    <div>
      <div className="flex mb-2 gap-7">
        <button
          className={`pb-1.5 text-sm cursor-pointer hover:text-primary text-secondaryGrey ${!depositStakeTabSelected && activeTabClasses}`}
          onClick={(): Promise<void> => handleSelectTap(TABS_OPTIONS.DEPOSIT)}
        >
          Deposit
        </button>
        <button
          className={`pb-1.5 text-sm cursor-pointer hover:text-primary text-secondaryGrey ${depositStakeTabSelected && activeTabClasses} relative`}
          onClick={(): Promise<void> =>
            handleSelectTap(TABS_OPTIONS.DEPOSIT_AND_STAKE)
          }
        >
          Deposit & Stake
          {depositStakeRequest && (
            <div className="absolute -right-2 -top-1">
              <span className="relative flex w-2 h-2">
                <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-primaryRed"></span>
                <span className="relative inline-flex w-2 h-2 rounded-full bg-primaryRed"></span>
              </span>
            </div>
          )}
        </button>
      </div>

      {!getRequestLoading && depositStakeTabSelected && depositStakeRequest ? (
        <DepositStakeRequest
          request={depositStakeRequest}
          onCancelRequest={handleCancelDepositStakeRequest}
          onReset={(): void => {
            setDepositStakeRequest(null);
            setTimeout(async (): Promise<void> => {
              await getWalletDepositStakeRequest();
              await getWalletDataDepositHistory({
                page: 1,
                limit: 10,
              });
            }, 3000);
          }}
        />
      ) : (
        <>
          {depositStakeTabSelected && (
            <div className="flex items-center gap-3 px-4 py-2 my-5 text-xs rounded bg-primary/15 text-primary">
              <InfoIcon />
              <span>Deposit & Stake is only for NodeK Staking Purpose.</span>
            </div>
          )}
          <div className="space-y-4">
            <DepositStep1
              walletsLoading={fetchSettingsLoading}
              depositStakeTabSelected={depositStakeTabSelected}
            />
            <DepositStep2 depositStakeTabSelected={depositStakeTabSelected} />
            {depositStakeTabSelected ? (
              <DepositStep3Stake
                onRequestSubmited={handleGetDepositStakeRequest}
                onReset={handleResetForm}
              />
            ) : (
              <DepositStep3Address
                onReset={(): void => {
                  updateValueDeposit({ selectedCoin: null });
                  updateValueDeposit({ selectedNetwork: '' });
                  updateValueDeposit({ currentStep: 1 });
                }}
              />
            )}
          </div>
          <div className="flex flex-col gap-3 mt-6">
            <GlobalDivider className="my-3 text-white" />
            {selectedNetwork?.feeValue > 0 && (
              <>
                <div className="flex justify-between text-sm text-textSecondary">
                  <span className="text-primaryGrey">
                    Network Fee (
                    {selectedNetwork?.feeType === 'fixed' ? 'fixed' : '%'}
                    ):
                  </span>
                  <span className="font-semibold text-primaryGrey">
                    {selectedNetwork?.feeType === 'fixed'
                      ? '$ ' + selectedNetwork?.feeValue
                      : selectedNetwork?.feeValue + '%'}
                  </span>
                </div>
                <div className="flex justify-between text-sm text-textSecondary">
                  <span className="text-primaryGrey">
                    Commision (
                    {selectedNetwork?.commissionType === 'fixed'
                      ? 'fixed'
                      : '%'}
                    ):
                  </span>
                  <span className="font-semibold text-primaryGrey">
                    {selectedNetwork?.commissionType === 'fixed'
                      ? '$ ' + selectedNetwork?.commissionValue
                      : selectedNetwork?.commissionValue + '%'}
                  </span>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DepositForm;
