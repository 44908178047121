import { Accordion, AccordionItem } from '@nextui-org/react';
import { FiPlus } from "react-icons/fi";
import { TbMinusVertical } from "react-icons/tb";


const FAQAccordian = ({ data }: { data: any[] }) => {
  return (
    <Accordion variant="bordered" className="px-0" defaultExpandedKeys={['0']}>
      {data?.map((item, index) => (
        <AccordionItem
          key={index}
          aria-label={item.question}
          title={item.question}
          indicator={({ isOpen }) => (isOpen ? <TbMinusVertical /> : <FiPlus />)}
          classNames={{
            heading: `px-5 py-1 text-defaultBlack font-medium text-lg`,
            indicator: 'text-black',
            content: 'px-5',
            base: 'border-b',
          }}
        >
          <div className={`flex gap-2 flex-1 `}>
            <div className="px-0 pb-2 flex flex-col justify-center">
              <span
                className="md:text-sm text-xs font-normal"
                dangerouslySetInnerHTML={{ __html: item.answer }}
              />
            </div>
          </div>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default FAQAccordian;
