import React, { useState } from 'react';
import { PLATFORMS } from '../../data/profile';
import PlatformCard from './PlatformCards';
import PlatformComingSoon from './PlatformComingSoon';

interface Data {
  heading: string;
  description: string;
  image: string;
  buttonText: string;
  isFavourite: boolean;
  isButtonFill: boolean;
}

const PlatformContainer = () => {
  const [platformList, setPlatfromList] = useState<Data[]>(PLATFORMS);
  const isComingsoon: boolean = false;

  const onFovouriteHandler = (index: number) => {
    const obj = [...platformList];
    obj[index].isFavourite = !obj[index].isFavourite;
    setPlatfromList(obj);
  };

  return (
    <section className="flex bg-white w-full flex-col gap-5 p-6 border shadow-sm rounded-lg">
      <article className="font-medium text-lg">Web 3.0 Platforms</article>
      <div className="relative">
        <article
          className={`grid grid-cols-2  gap-12  ${!isComingsoon && 'blur-md'}`}
        >
          {platformList.map((itm: Data, index: number) => {
            return (
              <React.Fragment key={index}>
                <PlatformCard
                  heading={itm.heading}
                  description={itm.description}
                  image={itm.image}
                  buttonText={itm.buttonText}
                  isFavourite={itm.isFavourite}
                  isButtonFill={itm.isButtonFill}
                  onFavouriteHandler={() => onFovouriteHandler(index)}
                />
              </React.Fragment>
            );
          })}
        </article>
        {!isComingsoon && <PlatformComingSoon />}
      </div>
    </section>
  );
};

export default PlatformContainer;
