/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GlobalSelectMui from '../../../../components/Global/GlobalSelectMui';
import useQueryParams from '../../../../hooks/useQueryParams';
import useUpdateQueryParam from '../../../../hooks/useUpdateQueryParam';
import { RootState } from '../../../../store';
import { selectWalletData } from '../../../../store/walletData/walletDataSlice';
import {
  DEPOSIT_SLICE_NAME,
  depositSliceAction,
  StateDeposit,
} from '../../../../store/wallets/depositSlice';
import { TOKEN } from '../../../../store/wallets/withdrawSlice';
import DepositStepper from './DepositStepper';
import GlobalSkeleton from '../../../../components/Global/GlobalSkeleton.tsx';

const DepositStep1 = ({
                        walletsLoading = false,depositStakeTabSelected
                      }: {
  walletsLoading: boolean;depositStakeTabSelected: boolean;
}) => {
  const dispatch = useDispatch();
  const { coin } = useQueryParams(['coin']);
  const updateQueryParam = useUpdateQueryParam();
const [data , setData] = useState([])
  const { depositSettings,depositStackSettings } = useSelector(selectWalletData);

  const { selectedCoin, selectedNetwork }: StateDeposit = useSelector(
    (state: RootState) => state[DEPOSIT_SLICE_NAME]
  );

  const updateCoinOption = (token: TOKEN) => {
    dispatch(
      depositSliceAction.setDepositSlice({
        selectedCoin: token,
        selectedNetwork: null,
      })
    );

    // update query param on selected coin state update
    updateQueryParam('coin', token.value);
  };

  const updateValue = (value: any) =>
    dispatch(
      depositSliceAction.setDepositSlice({
        ...value,
      })
    );

  // auto-select coin from deposit options as per coin name from query param
  useEffect(() => {
    if (depositSettings?.length > 0) {
      const selectedCoin = depositSettings.find(
        (option: any) => option.symbol === coin
      );
      if (selectedCoin) {
        updateCoinOption({
          ...selectedCoin,
          value: selectedCoin.symbol,
        });
      }
    }
  }, [depositSettings]);
  // useEffect(() => {
  //   console.log({depositStakeTabSelected})
  //   if (!depositStakeTabSelected) {
  //     if (depositSettings?.length > 0) {
  //       console.log({depositSettings})
  //       const type = depositSettings[0]?.type;
  //       console.log({type})
  //
  //       // setDepositStakeTabSelected(type === 'internal');
  //       // setListOfWallet(
  //       //   withdrawExternalSettings?.map((item: any) => {
  //       //     return {
  //       //       ...item,
  //       //       value: item?.name,
  //       //     };
  //       //   })
  //       // );
  //     }
  //   } else {
  //     console.log({depositStackSettings})
  //     if (depositStackSettings?.length > 0) {
  //       console.log({depositStackSettings})
  //       const type = depositStackSettings[0]?.type;
  //       // setDepositStakeTabSelected(type === 'internal');
  //       // setListOfWallet(
  //       //   withdrawInternalSettings?.map((item: any) => {
  //       //     return {
  //       //       ...item,
  //       //       value: item?.name,
  //       //     };
  //       //   })
  //       // );
  //     }
  //   }
  // }, [
  //   depositStakeTabSelected,
  //   depositSettings,
  //   depositStackSettings,
  // ]);
  useEffect(() => {
    if (selectedCoin && !selectedNetwork) {
      updateValue({ currentStep: 2 });
    }
  }, [selectedCoin]);
  useEffect(() => {

      if(!depositStakeTabSelected){
        console.log({depositSettings})
        setData(depositSettings)
      }else{
        console.log({depositStackSettings})
        setData(depositStackSettings)

      }
  }, [depositStackSettings,depositSettings,depositStakeTabSelected]);
  return (
    <div className="flex">
      <div className="w-10 lg:w-14 ">
        <DepositStepper step={1} enableBackground={selectedCoin} />
      </div>
      <div className="flex-1">
        <div className="flex flex-col gap-2">
          <GlobalSelectMui
            loading={walletsLoading}
            label="Wallet"
            required={true}
            options={
              data
                ? data?.map((item: any) => ({
                  ...item,
                  value: item?.name,
                }))
                : []
            }
            showPropName="value"
            selectedOption={selectedCoin}
            setSelectedOption={(token: TOKEN) => {
              updateCoinOption(token);
            }}
            placeHolder={'Select a Wallet'}
            firstAutoSelected={false}
          />
          <div className="flex flex-wrap justify-start gap-2">
            {!walletsLoading && data !== null
              ? (data
                  ? data?.map((item: any) => ({
                    ...item,
                    value: item?.name,
                  }))
                  : []
              )?.map((button: any, index: any) => (
                <button
                  key={index}
                  onClick={() => {
                    updateCoinOption(button);
                  }}
                  className={`rounded-lg py-2 px-4 text-xs text-textSecondary border ${selectedCoin?.value === button.value ? 'bg-primary text-white border !border-buttonColor' : 'border-secondary '} `}
                >
                  {button?.name}
                </button>
              ))
              : Array(4)
                .fill(null)
                .map((_, index) => (
                  <GlobalSkeleton
                    key={index}
                    animationValue="wave"
                    className="w-16"
                    height={44}
                  />
                ))}
          </div>
          {selectedCoin && (
            <div className="-mt-1 text-xs text-primaryGrey">
              {`Your deposits will automatically convert to the equivalent ${['USDT', 'USDK-W'].includes(selectedCoin.name) ? 'USDK' : 'mLYK'}
              tokens.`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositStep1;
