//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TUserProducts } from '../../data/cloudk2';
import { getFinalCompoundedValue, getMonthlyReward } from '../../utils/helper';

export type TChartDataType = {
  id: string;
  color?: string;
  data: {
    x: string;
    y: number;
    color?: string;
  }[];
};
// interface Product {
//   name: string;
//   stackLimit: number;
//   minitingPower: number;
// }
export type TChartValueType = {
  x: string;
  y: number;
  color?: string;
}[];

export interface IRewardsCalculatorState {
  value: string;
  products: TUserProducts[];
  LYK: number;
  stackAmount: number;
  calculatedStackAmount: number;
  years: any;
  selectedYears: number;
  selectedProduct: ISelectedProduct;
  rewardPerDay: number;
  autoCompound: boolean;
  monthlyReward: number;
  rewardsCalculatorChartData: TChartDataType[];
  rewardList: TChartValueType;
  lykList: TChartValueType;
  athList: TChartValueType;
  dlpList: TChartValueType;
  x: number[];
  y1: number[];
  y2: number[];
  y3: number[];
  y4: number[];
  totalReward: number;
  totalLoss: number;
  selectedMonth: number;
}

export interface ISelectedProduct {
  _id: string;
  name: string;
  url: string;
  imageUrl: string;
  externalProductId: string;
  price: number;
  mintingPowerPerc: number;
  defiPortal: boolean;
  airdropPromo: number;
  licenseName: string;
  profitSplitFee: number;
  quantwiseCapping: number;
  aiTradingSoftwareName: string;
  superNodeLevel: number;
  globalPool: number;
  bonus: number;
  createdAt: string;
  updatedAt: string;
  stakeLimit: number;
  stakeUnlimited: boolean;
  launchpadAirdrop: string;
}

export const calculatePerdayReward = (
  product: TUserProducts,
  stake: number,
  lykPrice: number,
  autoCompound: boolean,
  state: any
) => {
  let currentStake = state.calculatedStackAmount || stake;

  let monthlyReward = 0;

  for (let index = 0; index <= 30; index++) {
    const dailyReward =
      currentStake * lykPrice * (product.mintingPowerPerc / 100);

    monthlyReward += dailyReward;

    if (!autoCompound) {
      state.rewardPerDay = dailyReward;
    } else {
      state.rewardPerDay = (state.rewardPerDay || 0) + dailyReward;
    }
  }

  state.calculatedStackAmount = currentStake;

  state.monthlyReward = monthlyReward;
};

export const getRewardList = (years: number, rewardPerDay: number) => {
  let value = 0;
  const reward = Array.from({ length: years }, (_, i) => {
    return Number(Number((value = value + rewardPerDay))?.toFixed(3));
  });
  return reward;
};

const chartOfLine = [
  { key: 'rewardList', name: 'Rewards' },
  { key: 'lykList', name: 'LYK' },
  { key: 'athList', name: 'ATH' },
  { key: 'dlpList', name: 'DLP' },
];

const calculateReward = (state: any) => {
  calculatePerdayReward(
    state.selectedProduct,
    state.stackAmount,
    state.LYK,
    state.autoCompound,
    state
  );

  const reward = getMonthlyReward(
    state.autoCompound,
    state.stackAmount,
    state.selectedProduct?.mintingPowerPerc,
    state.selectedYears
  );
  state.y1 = reward;
  state.y2 = Array.from({ length: state.x.length }, (_, i) => state.LYK);
  state.y3 = Array.from({ length: state.x.length }, (_, i) => state.LYK + 0.05);
  state.y4 = Array.from({ length: state.x.length }, (_, i) => state.LYK);

  const list: any[] = [];
  chartOfLine.forEach((item, indexI) => {
    const storedValue = 'y' + (indexI + 1);
    const chartListItem = state.x.map((value: number, index: number) => {
      return {
        x: `${value} M`,
        y: Number(Number(state[storedValue][index])?.toFixed(3)),
      };
    });
    state[item.key] = chartListItem;
    list.push({
      id: item.name,
      data: chartListItem,
    });
  });
  state.rewardsCalculatorChartData = list;
  state.totalReward = state.rewardList[state.rewardList.length - 1].y;
  state.totalLoss = state.autoCompound ? 0 : state.totalReward * 0.3;
};

export const REWARD_SLICE_NAME = 'rewards-calculator';
const initialState: IRewardsCalculatorState = {
  value: '',
  products: [],
  LYK: 0,
  monthlyReward: 0,
  stackAmount: 0,
  calculatedStackAmount: 1,
  selectedYears: 1,
  years: [1, 2, 3, 4, 5],
  selectedProduct: {
    _id: '',
    name: '',
    url: '',
    imageUrl: '',
    externalProductId: '',
    price: 0,
    mintingPowerPerc: 0,
    defiPortal: false,
    airdropPromo: 0,
    licenseName: '',
    profitSplitFee: 0,
    quantwiseCapping: 0,
    aiTradingSoftwareName: '',
    superNodeLevel: 0,
    globalPool: 0,
    bonus: 0,
    createdAt: '',
    updatedAt: '',
    stakeLimit: 0,
    launchpadAirdrop: '',
    stakeUnlimited: false,
  },
  autoCompound: true,
  rewardPerDay: 0,
  totalReward: 0,
  selectedMonth: 1,
  totalLoss: 0,
  x: Array.from({ length: 12 }, (_, i) => i + 1), // years
  y1: [], // reward list
  y2: [], // lyk list
  y3: [],
  y4: [],

  rewardList: [
    {
      x: '2 M',
      y: 420,
    },
  ],
  lykList: [
    {
      x: '2 M',
      y: 420,
    },
  ],
  athList: [
    {
      x: '2 M',
      y: 420,
    },
  ],
  dlpList: [
    {
      x: '2 M',
      y: 420,
    },
  ],

  rewardsCalculatorChartData: [
    {
      id: 'Rewards',
      color: 'hsl(226, 70%, 50%)',
      data: [
        {
          x: '20 M',
          y: 1600,
        },
      ],
    },
    {
      id: 'LYK Price',
      data: [
        {
          x: '2 M',
          y: 420,
        },
      ],
    },
    {
      id: 'DLP',
      data: [
        {
          x: '2 M',
          y: 420,
        },
      ],
    },
    {
      id: 'ATH',
      color: 'hsl(161, 70%, 50%)',
      data: [
        {
          x: '2 M',
          y: 420,
        },
      ],
    },
  ],
};

const calculatorSlice = createSlice({
  name: REWARD_SLICE_NAME,
  initialState,
  reducers: {
    setRewardSlice: (
      state: IRewardsCalculatorState,
      action: PayloadAction<any>
    ) => {
      return { ...state, ...action.payload };
    },
    rewardPerDayCalculator: (state, action: PayloadAction<any>) => {
      return { ...state, rewardPerDay: action.payload };
    },
    selectYears: (state: any, action: PayloadAction<any>) => {
      state.selectedYears = action.payload;
      const totalMonth = state.selectedYears * 12;
      state.x = Array.from(
        { length: totalMonth / state.selectedYears },
        (_, i) => {
          return (i + 1) * state.selectedYears;
        }
      );
      calculateReward(state);
    },
    changeStakeAmount: (state, action: PayloadAction<number>) => {
      const value = action.payload;
      const totalAmount = value + state.calculatedStackAmount;

      // Disable autoCompound if stake limit is reached
      const amountReached = totalAmount >= state.selectedProduct.stakeLimit;
      state.autoCompound = state.autoCompound && !amountReached;

      // Update stack amount and recalculate rewards
      state.stackAmount = value;
      const reward = getFinalCompoundedValue(
        state.autoCompound,
        value,
        state.selectedProduct.mintingPowerPerc,
        state.selectedYears
      );
      state.calculatedStackAmount = reward;

      // Trigger the final reward calculation
      calculateReward(state);
    },
    initRewardCalculator: (state: any) => {
      calculateReward(state);
    },
    setAutoCompound: (state: any, action: PayloadAction<any>) => {
      state.autoCompound = action.payload;
      calculateReward(state);
    },
  },
});

export const { setRewardSlice, initRewardCalculator, selectYears } =
  calculatorSlice.actions;
export const selectRewardSlice = (state: {
  [REWARD_SLICE_NAME]: IRewardsCalculatorState;
}) => state[REWARD_SLICE_NAME];

export default calculatorSlice.reducer;
