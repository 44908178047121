import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';
import { homnifiLogo } from '../../images';
import { useNewsletterActions } from '../../store/newsLetter/newLetterActions';
import { selectNewsletter } from '../../store/newsLetter/newsLetterSlice';
import NewsLetterTabContent from './NewsletterTabContent';

const NewsletterPopOver = () => {
  const containerRef = useRef(null);
  const { getNewsletter } = useNewsletterActions();
  const { newsletterData, isLoading } = useSelector(selectNewsletter);
  const [currentPage, setCurrentPage] = useState<number>(
    newsletterData?.currentPage ?? 1
  );

  const fetchAllNewsletter = async (page: number) => {
    await getNewsletter(page);
  };

  const handleScroll = () => {
    const container: any = containerRef.current;
    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      if (currentPage < newsletterData.totalPages) {
        setCurrentPage(currentPage + 1);
        fetchAllNewsletter(currentPage + 1);
      }
    }
  };

  return (
    <div className="flex-col hidden md:flex w-[100vw] md:absolute top-2 right-0 md:w-96 bg-white border border-borderLightGray shadow-lg h-auto rounded-lg">
      <div className="flex items-center justify-between w-full p-4">
        <span className="text-lg font-medium">Newsletter</span>
        {/* {unReadNewsletterCount > 0 && (
          <button
            className="font-medium text-primary"
            onClick={() => {
              if (unReadNewsletterCount > 0) {
                markAllAsReadNotifications();
              }
            }}
          >
            Mark all as read
          </button>
        )} */}
      </div>
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className="max-h-[65vh] overflow-auto small-scroll border border-b-1"
      >
        {isLoading && newsletterData?.list?.length === 0 ? (
          <div className="flex justify-center w-full my-2">
            <span className="px-4 text-lg font-normal text-primary">
              Loading
            </span>
            <Spinner className="w-8 h-8 animate-spin text-primary" />
          </div>
        ) : (
          <NewsLetterTabContent />
        )}
      </div>
      <div className="flex justify-center w-full p-4 ">
        <img src={homnifiLogo} alt="Logo" className="w-24" />
      </div>
    </div>
  );
};

export default NewsletterPopOver;
