/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import GlobalTable from '../../../../components/Global/GlobalTable';
import { userStatsArray } from '../../../../data/superNode';
import { profileImg } from '../../../../images';
import { MachineImage } from '../../../../images/cloud';
import { selectSuperNodeSlice } from '../../../../store/superNode/superNodeSlice';
import { formatDateFns } from '../../../../utils/helper';
import { userDetailFunc } from '../../utils/SuperNode';
import DailyProductionGraphComp from './DailyProductionGraphComp';
import UserStats from './UserStats';

import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Spinner } from '../../../../SVGIcons/spinner.svg';
import { useSuperNodeActions } from '../../../../store/superNode/superNodeActions';
import {
  SUPERNODE_BONUS_TYPE,
  UserStatsData,
} from '../../../../types/superNodes.type';
import { toFixedWithoutRoundingOff } from '../../../../utils/utils';
import GlobalSelect, {
  IGlobalSelect,
} from '../../../../components/Global/GlobalSelect';

const TopUsersModalContent = () => {
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [machineOptions, setMachineOptions] = useState<any[]>([]);
  const [initialState, setInitialState] = useState<boolean>(true);

  const headCells = [
    {
      id: 'machine',
      label: 'Machine',
      dataClass: 'w-[30%]',
    },
    {
      id: 'type',
      label: 'Type',
      dataClass: 'auto',
    },
    {
      id: 'production',
      label: 'Production',
      dataClass: 'auto',
    },
    {
      id: 'myReward',
      label: 'My Rewards',
      dataClass: 'auto',
    },
    {
      id: 'date',
      label: 'Date',
      dataClass: 'auto',
    },
  ];

  const superNodesRewardsType: IGlobalSelect[] = [
    {
      name: 'Base Referral',
      value: SUPERNODE_BONUS_TYPE.BASE_REFERRAL,
    },
    {
      name: 'Builder Generational',
      value: SUPERNODE_BONUS_TYPE.BUILDER_GENERATIONAL,
    },
    {
      name: 'Builder Referral',
      value: SUPERNODE_BONUS_TYPE.BUILDER_REFERRAL,
    },
    {
      name: 'Supernode Global Pool',
      value: SUPERNODE_BONUS_TYPE.SUPERNODE_GLOBAL_POOL,
    },
  ];

  const {
    userDailyReward,
    userChartDetail,
    detailedUser,
    getUserDailyRewardLoading,
    userRewardHistory,
    getUserRewardHistoryLoading,
  } = useSelector(selectSuperNodeSlice);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { getUserRewardHistory } = useSuperNodeActions();
  const { list, totalPages } = userRewardHistory;

  const handleReset = () => {
    setFilters({
      limit: 10,
      page: 1,
      machine: '',
      fromDate: null,
      toDate: null,
      type: '',
    });
    setSelectedStatus(null);
    setResetFilter(true);
  };
  const rows =
    list.length > 0
      ? list.map((trx, i) => ({
          machine: (
            <div className="flex items-center gap-2">
              <img
                src={trx.machine?.imageUrl || MachineImage}
                alt="MachineImage"
                className="w-11"
              />
              <span className="text-defaultBlack font-normal text-xs">
                {trx.machine.name}
              </span>
            </div>
          ),
          type: <span className="text-defaultBlack text-xs ">{trx.type}</span>,
          production: (
            <span className="text-defaultBlack text-xs ">
              {toFixedWithoutRoundingOff(trx.production, 6)}
            </span>
          ),
          myReward: (
            <div className="flex items-center gap-1 text-xs text-defaultBlack font-normal">
              <span>{toFixedWithoutRoundingOff(trx.myReward, 6)}</span>
              <sub>{trx.token}</sub>
            </div>
          ),
          token: <span>{trx.token}</span>,
          date: (
            <span className="text-primaryGrey text-xs">
              {formatDateFns(trx.date, 'yyyy-MM-dd HH:mm')}
            </span>
          ),
        }))
      : [];

  useEffect(() => {
    if (list.length > 0 && initialState) {
      const machineOptions: any[] = [];
      const machineIds = new Set(); // Use a Set to track unique machine IDs

      list.forEach((item) => {
        if (!machineIds.has(item.machine._id)) {
          // Check if the ID is already in the Set
          machineOptions.push({
            name: item.machine.name,
            value: item.machine._id,
          });
          machineIds.add(item.machine._id); // Add the ID to the Set
        }
      });

      setMachineOptions(machineOptions); // Update state with unique options
      setInitialState(false);
    }
  }, [list]);

  const userStatsState: UserStatsData[] = useMemo(
    () => userDetailFunc(userStatsArray, userDailyReward),
    [userStatsArray, userDailyReward]
  );

  const [resetFilter, setResetFilter] = useState(false);
  const [isInitial, setIsInitial] = useState(true); // Track if it's the initial render

  const [filters, setFilters] = useState<any>({
    limit: 10,
    page: 1,
    machine: '',
    fromDate: null,
    toDate: null,
    type: '',
  });
  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');

    setFilters((prev: any) => ({
      ...prev,
      fromDate: from,
      toDate: to,
      page: 1,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isInitial) {
        setResetFilter(false);
        await getUserRewardHistory({
          limit: 10,
          page: filters.page,
          machine: selectedStatus,
          fromDate: filters.fromDate,
          toDate: filters.toDate,
          userId: detailedUser._id,
          type: filters.type,
        });
      }
    };
    fetchData();
    if (isInitial) {
      setIsInitial(false);
    }
  }, [filters, selectedStatus]);

  return (
    <section className="flex flex-col gap-6">
      <article className="flex gap-4 items-center">
        <div className="relative w-14 h-14">
          <img
            src={
              detailedUser?.profilePicture
                ? detailedUser.profilePicture
                : profileImg
            }
            alt="profile-name"
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="flex flex-col">
          <div className="font-medium text-xl text-defaultBlack">
            @{detailedUser?.username}
          </div>
          <div className="text-primaryGrey text-sm font-normal">
            Joining Date:{' '}
            {detailedUser?.dateJoined &&
              formatDateFns(detailedUser?.dateJoined, 'dd-MMMM-yyyy')}
          </div>
        </div>
      </article>
      <article className="grid md:grid-cols-2 xxs:grid-col-4 grid-flow-column  gap-4  ">
        {getUserDailyRewardLoading ? (
          <div className="flex justify-center items-center flex-1  h-full col-span-2">
            <div className="justify-center items-center self-center">
              <div className="flex flex-col gap-5">
                <Spinner className="animate-spin h-6" />
              </div>
            </div>
          </div>
        ) : (
          userStatsState.map((item, index) => (
            <UserStats
              key={index}
              blackBG={item.blackBG}
              title={item.title}
              value={item.value}
              token={item.token}
            />
          ))
        )}
      </article>
      <DailyProductionGraphComp
        data={userChartDetail}
        detailedUser={detailedUser}
      />

      <article className="flex flex-col gap-4">
        <h2 className="text-primaryDark font-medium text-base">
          Reward History
        </h2>
        <GlobalTable
          rows={rows.length ? rows : []}
          headCells={headCells}
          resetDateFilter={resetFilter}
          // isLoading={false}
          searchIsLoading={false}
          page={filters.page}
          totalPages={totalPages}
          handlePageChange={(page) => {
            setFilters({ ...filters, page });
          }}
          handleDateDate={handleDateDate}
          emptyDataMessage="No Reward History!"
          showTotalRecords={false}
          searchFieldPlaceholderText="Search"
          paginationPosition={isMobile ? 'left' : 'right'}
          rowBorder={true}
          rounded={true}
          showSearch={false}
          pagination={totalPages > 1 ? true : false}
          headStyles="text-primaryGrey !font-normal"
          height=" small-scroll flex flex-col border"
          // showDateFilter={true}
          tableWidth="w-full"
          isMobile={isMobile ? true : false}
          isLoading={getUserRewardHistoryLoading}
          dateArrowAllow={true}
          tableDropdowns={
            <div className="flex flex-row sm:items-center space-x-3">
              <GlobalSelect
                name="type"
                placeholder="Machine"
                isError={false}
                value={selectedStatus}
                getSelectedValue={(item: string) => {
                  setSelectedStatus(item);
                }}
                options={machineOptions || []}
                className={` !rounded-md !min-w-24  ${selectedStatus ? '!w-max ' : '!w-[122px]'}`}
                classNameSelect="!border-defaultBorder !rounded-md py-2.5 px-5 mr-6"
                optionsWidth="w-max"
              />
              <GlobalSelect
                name="type"
                placeholder="Type"
                isError={false}
                value={filters.type}
                getSelectedValue={(item: string) => {
                  setFilters({
                    ...filters,
                    type: item,
                  });
                  // setShouldFetchData(true);
                }}
                options={superNodesRewardsType}
                className={` !rounded-md !min-w-24 ${filters.type ? '!w-max ' : '!w-[122px]'} `}
                classNameSelect="!border-defaultBorder !rounded-md py-2.5 px-5 mr-6 "
                optionsWidth="w-max"
              />

              <button
                className="text-primary text-xs font-medium h-10"
                type="button"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          }
          fromDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
          toDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
        />
      </article>
    </section>
  );
};

export default TopUsersModalContent;
