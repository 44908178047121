import { ResponsivePie } from '@nivo/pie';
import { useWindowSize } from '../../../../../utils/helper';

export type TPieChart = {
  data?: any;
  colors?: any;
  valueFormat?: any;
  enableArcLinkLabels?: boolean;
  enableArcLabels?: boolean;
  isInteractive?: boolean;
  innerRadius?: number;
  padAngle?: number;
  loading?: boolean;
  totalPercentage?: number | string | undefined;
};
export const SNGPPieChart = ({
  data,
  colors,
  valueFormat,
  enableArcLinkLabels = true,
  enableArcLabels = true,
  innerRadius = 0.6, // Make innerRadius greater than 0 for a donut chart
  padAngle = 1,
  totalPercentage,
  isInteractive = true,
  loading = false,
}: TPieChart) => {
  const size = useWindowSize();

  // Define default values for arc link lengths based on screen size
  const diagonalLength = size.width <= 425 ? 8 : 5;
  const straightLength = size.width <= 425 ? 10 : 10;

  return (
    <div className=" w-full h-full  rounded-full border border-[#F3F3F3] z-999  bg-white shadow-pieChartBoxShadow">
      <ResponsivePie
        // width={500}
        // height={300}
        isInteractive={isInteractive}
        data={data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        startAngle={0} // Start angle for the chart to make the segments aligned like the image
        endAngle={360}
        colors={colors}
        padAngle={padAngle}
        cornerRadius={5}
        innerRadius={innerRadius}
        arcLabelsRadiusOffset={0.45} // Adjust to control the donut thickness
        arcLabelsSkipAngle={45}
        activeInnerRadiusOffset={5}
        activeOuterRadiusOffset={8}
        // arcLinkLabel={(d: any) => `${d.label}: ${d.value}%`}
        arcLinkLabelsDiagonalLength={diagonalLength}
        arcLinkLabelsStraightLength={straightLength}
        arcLinkLabelsSkipAngle={12}
        arcLinkLabelsOffset={-2}
        arcLinkLabelsThickness={2}
        arcLinkLabelsTextOffset={13}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsColor={{ from: 'color' }}
        // arcLabelsSkipAngle={10}
        arcLabelsTextColor="#ffffff" // White text for arc labels
        enableArcLabels={enableArcLabels}
        arcLabel={(d: any) =>
          parseFloat(d.value) > 0
            ? ((
                <tspan className="">
                  <tspan
                    x="3"
                    dy="0"
                    className=" text-md text-white font-normal"
                  >
                    {d.label}
                  </tspan>
                  <tspan
                    x="3"
                    dy="1em"
                    className=" text-lg text-white font-semibold"
                    // style={{ fontSize: '8px', fill: '#969696' }}
                  >
                    {(
                      Math.floor(d.data.actualValue ?? d.value * 100) / 100
                    ).toFixed(0)}
                    {/* % */}
                  </tspan>
                </tspan>
              ) as any)
            : null
        }
        enableArcLinkLabels={false}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        // layers={[
        //     'arcs',
        //     'arcLabels',
        //     'arcLinkLabels',
        //     'legends',
        //     (props) => (
        //         <div>{props.}</div>
        //     ),
        // ]}
        legends={[]}
      />
    </div>
  );
};
