import React from 'react';
import {
  addMonths,
  differenceInDays,
  differenceInMilliseconds,
  differenceInMonths,
  format,
  parseISO,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { IGlobalSelect } from '../components/Global/GlobalSelect';
import moment from 'moment';
import { number } from 'yup';

export function getRandomColor() {
  const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  return color;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const getTableIndexNumber = ({
  index,
  limit,
  page,
}: {
  page: number;
  index: number;
  limit: number;
}) => {
  return (page - 1) * limit + (index + 1);
};

export const formatDateFns = (inputDate: string, Dateformat?: string) => {
  if (inputDate) {
    const desiredFormat = 'yyyy-MM-dd HH:mm:ss';

    // Convert the input date to a zoned time (UTC)
    const zonedDate = toZonedTime(inputDate, 'UTC');

    const formattedDate = format(
      zonedDate,
      Dateformat ? Dateformat : desiredFormat
    );

    return formattedDate;
  }
};
export const formatStringToDOB = (isoDateString: string) => {
  if (isoDateString) {
    const date = parseISO(isoDateString);

    const formattedDate = format(date, 'yyyy-MM-dd');

    return formattedDate.toString();
  }
};

export const formatToUpperCase = (date: Date | string) => {
  const formattedDate = format(date, 'dd MMMM yyyy', { locale: enUS });
  return formattedDate.replace(/(\b\w+\b)/g, (match, p1, offset) =>
    offset === 3 ? p1.toUpperCase() : p1
  );
};

export function transformDataNameValue(
  data: [
    {
      code: string;
      name: string;
      disabled: boolean;
    },
  ]
) {
  const list: IGlobalSelect[] = data
    .filter((item) => !item.disabled)
    .map((fItem) => ({ name: fItem.name, value: fItem.code, disable: false }));
  return list;
}

export function formatDuration(dateString: string) {
  const currentDate = new Date();
  const parsedDate = parseISO(dateString);

  // Calculate total months difference
  const totalMonths = differenceInMonths(parsedDate, currentDate);

  // Calculate the remaining days after subtracting the months difference
  const remainingDate = addMonths(currentDate, totalMonths);
  const totalDays = differenceInDays(parsedDate, remainingDate);

  // Format the output string
  const formattedData = `${Math.abs(totalMonths)} Month${Math.abs(totalMonths) !== 1 ? 's' : ''} ${Math.abs(totalDays)} Day${Math.abs(totalDays) !== 1 ? 's' : ''}`;

  return {
    totalMonths,
    totalDays,
    formattedData,
  };
}

export function formatDurationFromStartToExpiry(expiryDateString: string) {
  const startDate = new Date('2024-10-16T14:40:06.188Z');
  const endDate = new Date(expiryDateString);

  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const startDay = startDate.getDate();

  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();
  const endDay = endDate.getDate();

  let totalMonths = (endYear - startYear) * 12 + (endMonth - startMonth);

  let totalDays = endDay - startDay;

  if (totalDays < 0) {
    totalMonths -= 1;
    const previousMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      0
    );
    const daysInPreviousMonth = previousMonth.getDate();

    totalDays += daysInPreviousMonth;
  }

  const formattedData = `${Math.abs(totalMonths)} Month${Math.abs(totalMonths) !== 1 ? 's' : ''} ${Math.abs(totalDays)} Day${Math.abs(totalDays) !== 1 ? 's' : ''}`;

  return {
    totalMonths,
    totalDays,
    formattedData,
  };
}

export const buildUrl = (baseUrl: string, params: any) => {
  const url = new URL(baseUrl);

  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      url.searchParams.append(key, params[key]);
    }
  });

  return url.toString();
};

export const filterParams = (params: object) => {
  let queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) =>
        value !== undefined &&
        value !== null &&
        value.length &&
        (!Array.isArray(value) || value.length > 0) &&
        (Array.isArray(value) || value.length)
    )
  );

  if (Object.entries(queryParams).length) {
    return queryParams;
  }

  // return queryParams;
};

export const constructUrl = (baseUrl: string, params: any) => {
  const queryParams = Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== '')
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');

  return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
};

export function formatDate(isoDate: string): string {
  const date = new Date(isoDate);

  // Extract date components
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getUTCFullYear();

  // Extract time components
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Combine into the desired format
  return `${day}-${month}-${year} - ${hours}:${minutes}`;
}

export const tolocalTime = (utcDateString: Date) => {
  const date = new Date(utcDateString);
  const timeZone = 'Asia/Dubai';

  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const dateParts = date.toLocaleString('en-US', options).split(', ');
  const [month, day, year] = dateParts[0].split('/');
  const [hours, minutes] = dateParts[1].split(':');

  return `${day}-${month}-${year} - ${hours}:${minutes}`;
};

export const calculateDateDifferenceInMonths = (
  startDateStr: string,
  endDateStr: string
) => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate total months difference
  let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  totalMonths += endDate.getMonth() - startDate.getMonth();

  // Adjust the days difference if the end day is earlier than the start day
  let remainingDays = endDate.getDate() - startDate.getDate();

  if (remainingDays < 0) {
    totalMonths -= 1; // Go back one month
    const previousMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      0
    );
    remainingDays += previousMonth.getDate(); // Add the number of days in the previous month
  }

  return { totalMonths: totalMonths, totalDays: remainingDays };
};

export function isIsoDateString(value: any): boolean {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  return typeof value === 'string' && isoDateRegex.test(value);
}

export function formatOnlyDate(isoDate: string): string {
  const date = new Date(isoDate);

  // Extract date components
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getUTCFullYear();

  // Combine into the desired format
  return `${day}-${month}-${year}`;
}

export const getDatePercentage = (startDate: string, endDate: string) => {
  const currentDate = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  const totalDuration = differenceInMilliseconds(end, start);
  const durationPassed = differenceInMilliseconds(currentDate, start);

  if (durationPassed <= 0) return 0; // If the current date is before the start date
  if (durationPassed >= totalDuration) return 100; // If the current date is after the end date

  return (durationPassed / totalDuration) * 100;
};

export const calculateDateDifference = (
  startDate?: string,
  endDate?: string
) => {
  // Ensure the input dates are valid
  if (!startDate || !endDate) {
    throw new Error('Both start date and end date are required.');
  }

  // Calculate the difference in days
  return differenceInDays(endDate, startDate);
};

export function dateToNumber(date: string) {
  return new Date(date).getTime();
}

export const convertDateToNumber = (dateString: string) => {
  // Parse the string date to a Date object
  const date = parseISO(dateString);

  // Return the numerical value of the date (timestamp)
  return date.getTime();
};

// export function calculateWithAutoCompound(
//   stakeAmount: number,
//   mintingPower: number,
//   year: number = 5
// ): number {
//   const minting = mintingPower / 100;

//   let staking = stakeAmount;
//   let reward = 0;
//   if (minting) {
//     const dailyReward = staking * minting;
//     for (let index = 0; index < year * 365; index++) {
//       staking = dailyReward + staking;
//       reward += dailyReward;
//     }
//     return setDecimalPlaces(reward, 2);
//   }
//   return 0;
// }

export function calculateWithAutoCompound(
  stakeAmount: number,
  mintingPower: number,
  year: number = 5200
): number {
  const dailyRate = mintingPower / 100;
  let staking = stakeAmount;
  let reward = 0;
  let totalDuration = year * 365;
  if (dailyRate > 0) {
    for (let index = 0; index < totalDuration; index++) {
      const dailyReward = staking * dailyRate;
      staking += dailyReward;
      reward += dailyReward;
    }

    return setDecimalPlaces(reward, 2);
  }
  return 0;
}

export function calculateWithoutAutoCompound(
  stakeAmount: number,
  mintingPower: number,
  year: number = 5
): number {
  const minting = mintingPower / 100;
  if (minting) {
    const reward = stakeAmount * minting;
    const totalReward = reward * (year * 365);
    return setDecimalPlaces(totalReward * 0.7, 2);
  }
  return 0;
}

export function getFinalCompoundedValue(
  autoCompound: boolean,
  principal: number,
  rate: number,
  years: number,
  setResult: (val: number) => void = () => {}
) {
  if (autoCompound) {
    setResult(calculateWithAutoCompound(principal, rate, years));
    return calculateWithAutoCompound(principal, rate, years);
  } else {
    setResult(calculateWithAutoCompound(principal, rate, years));
    return calculateWithoutAutoCompound(principal, rate, years);
  }
}

export function getSelectedMachineCalculation(
  autoCompound: boolean,
  stakeAmount: number,
  mintingPower: number,
  years: number = 5
): number {
  const dailyRate = mintingPower / 100;
  const totalDuration = years * 365;

  if (dailyRate <= 0) return 0; // Edge case handle: No minting power

  let reward = 0;

  if (autoCompound) {
    let staking = stakeAmount;
    for (let index = 0; index < totalDuration; index++) {
      const dailyReward = staking * dailyRate;
      staking += dailyReward; // compound the reward
      reward += dailyReward; // total reward accumulation
    }
  } else {
    const dailyReward = stakeAmount * dailyRate;
    reward = dailyReward * totalDuration * 0.7; // 0.7 factor as per logic without compound
  }

  return setDecimalPlaces(reward, 2); // Format the reward to 2 decimal places
}

const calculateAutoCompound = (
  stakeAmount: any,
  dailyRate: any,
  days: any,
  stakeLimit: any
) => {
  let rewardsPerDay = [];
  let totalReward = 0;
  for (let i = 1; i <= days; i++) {
    let dailyReward = stakeAmount * dailyRate;
    if (stakeAmount < stakeLimit) {
      stakeAmount += dailyReward;
      totalReward += dailyReward;
    } else {
      // Stop auto-compounding if the stake limit is reached
      break;
    }
    rewardsPerDay.push({
      day: i,
      reward: dailyReward,
      totalStake: stakeAmount,
    });
  }
  return {
    rewardsPerDay,
    finalStakeAmount: stakeAmount,
    totalReward,
  };
};
// Function for fixed reward calculation with 30% deduction in daily rewards (no auto-compounding)
const calculateFixedReward = (stakeAmount: any, dailyRate: any, days: any) => {
  let rewardsPerDay = [];
  let totalReward = 0;
  const deductionRate = 0.7;
  for (let i = 1; i <= days; i++) {
    let dailyReward = stakeAmount * dailyRate * deductionRate;
    totalReward += dailyReward;
    rewardsPerDay.push({
      day: i,
      reward: dailyReward,
      totalStake: stakeAmount,
    });
  }
  return {
    rewardsPerDay,
    finalStakeAmount: stakeAmount,
    totalReward,
  };
};
// Main function to switch between auto-compounding and fixed reward
const calculationWithAutoCompound = (
  initialStake: any,
  mintingPower: any,
  years: any,
  stakeLimit: any,
  autoCompound: any
) => {
  const days = years * 365;
  const dailyRate = mintingPower / 100;
  let stakeAmount = initialStake;
  if (autoCompound) {
    // Use auto-compounding until the stakeLimit is reached, then switch to fixed reward
    const autoCompoundResult = calculateAutoCompound(
      stakeAmount,
      dailyRate,
      days,
      stakeLimit
    );
    stakeAmount = autoCompoundResult.finalStakeAmount;
    // If stakeLimit was reached during auto-compounding, continue with fixed rewards for remaining days
    if (stakeAmount >= stakeLimit) {
      const remainingDays = days - autoCompoundResult.rewardsPerDay.length;
      const fixedRewardResult = calculateFixedReward(
        stakeAmount,
        dailyRate,
        remainingDays
      );
      // Merge results from auto-compounding and fixed rewards
      autoCompoundResult.rewardsPerDay.push(...fixedRewardResult.rewardsPerDay);
      autoCompoundResult.totalReward += fixedRewardResult.totalReward;
    }
    return {
      rewardsPerDay: autoCompoundResult.rewardsPerDay,
      finalStakeAmount: stakeAmount,
      totalReward: autoCompoundResult.totalReward,
    };
  } else {
    // If autoCompound is off, calculate with fixed rewards (30% deduction applied)
    return calculateFixedReward(stakeAmount, dailyRate, days);
  }
};

export function getMonthlyReward(
  autoCompound: boolean,
  principal: number,
  rate: number,
  years: number
): number[] {
  const totalMonths = years * 12;
  const monthsArray = Array.from(
    { length: totalMonths / years },
    (_, i) => (i + 1) * years
  );

  const rewards = monthsArray.map((month) => {
    const currentYears = month / 12;
    return (
      calculationWithAutoCompound(
        principal,
        rate,
        currentYears,
        1000,
        autoCompound
      ).totalReward || 0
    );
  });
  return rewards;
}

export function getRewardsPercentage(totalRewards: number, collatoral: number) {
  return (totalRewards / collatoral) * 100;
}

export function ConvertDollarToLYK(conversionRate: number, dollar: number) {
  return dollar * conversionRate;
}

export const DateFilterOptions: { key: string; label: string }[] = [
  {
    key: 'today',
    label: 'Today',
  },
  {
    key: 'yesterday',
    label: 'Yesterday',
  },
  {
    key: ' last-7-days',
    label: 'Last 7 Days',
  },
  {
    key: ' last-30-days',
    label: 'Last 30 Days',
  },
  {
    key: 'this-month',
    label: 'This Month',
  },
  {
    key: 'this-year',
    label: 'This year',
  },
];

export const setDecimalPlaces = (value: number, decimalPlaces: number) => {
  const factor = Math.pow(10, decimalPlaces);
  // return (
  //   (value >= 0 ? Math.floor(value * factor) : Math.ceil(value * factor)) /
  //   factor
  // );
  return Math.trunc(value * factor) / factor;
};

export enum ChargesType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export const calculateFeeCharge = (
  amount: number,
  fee: number,
  type: ChargesType,
  tokenPrice: number
) => {
  let feeTokenAmount = 0;
  switch (type) {
    case ChargesType.FIXED:
      feeTokenAmount = fee / tokenPrice;
      break;

    case ChargesType.PERCENTAGE:
      const feeInTokens = fee / tokenPrice;
      feeTokenAmount = (feeInTokens / 100) * amount;
      break;

    default:
      return 0;
  }

  return setDecimalPlaces(feeTokenAmount, 6);
};

export const extractSSOParams = (search: string, notSearch = false) => {
  let url;

  if (notSearch) {
    // Create a URL object
    url = new URL(search);
  }

  const queryParams: any = new URLSearchParams(
    notSearch ? url?.search : search
  );
  const queryParamsObject: any = {};
  for (const [key, value] of queryParams.entries()) {
    queryParamsObject[key] = value;
  }

  // const newQueryParams: any = {};

  // Check if redirect_uri exists and is valid
  // if (queryParamsObject.redirect_uri) {
  //   try {
  //     const parser = new URL(queryParamsObject.redirect_uri);
  //     const queryString = parser.search.substring(1);
  //     const params = new URLSearchParams(queryString);

  //     newQueryParams.redirect_uri = params.get('redirect_uri');
  //     newQueryParams.scope = params.get('scope');
  //     newQueryParams.response_type = params.get('response_type');
  //     newQueryParams.client_id = params.get('client_id');
  //   } catch (error) {
  //     console.error('Failed to parse redirect_uri:', error);
  //   }
  // } else {
  //   newQueryParams.redirect_uri = queryParamsObject.redirect_uri;
  //   newQueryParams.scope = queryParamsObject.scope;
  //   newQueryParams.response_type = queryParamsObject.response_type;
  //   newQueryParams.client_id = queryParamsObject.client_id;
  // }

  return queryParamsObject;
};

// /**
//  * Formats a Date object into the format "02 JULY 2024".
//  * @param date - The date to format.
//  * @returns
//  */
export const formatDateToString = (
  isoDate: Date,
  isFull: boolean = true
): string => {
  const date = new Date(isoDate);
  const monthNames = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];

  const day = date.getDate().toString().padStart(2, '0');
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${isFull ? month : month.substring(0, 3)} ${year}`;
};
export interface ExpiryCheckResult {
  status: 'expired' | 'active';
  daysRemaining?: number;
}
export const checkExpiry = (endDate: string): ExpiryCheckResult => {
  const end = new Date(endDate);
  const currentDate = new Date();

  // Calculate if expired and remaining time
  const isExpired = currentDate >= end;
  const daysRemaining = isExpired
    ? 0
    : Math.floor(
        (end.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
      );

  return {
    status: isExpired ? 'expired' : 'active',
    daysRemaining: isExpired ? undefined : daysRemaining,
  };
};

export const formatDateTimeZone = (dateString: any) => {
  const date = dateString;
  const formattedDate_ = moment.utc(date).format('YYYY-MM-DD HH:mm');
  const formattedDate = moment
    .utc(formattedDate_)
    .local()
    .format('YYYY-MM-DD HH:mm');
  return `${formattedDate_.split(' ')[0]} ${formattedDate.split(' ')[1]}`;
};

export const checkWindowsVersion = async () => {
  const device: {
    name: string;
    version: string;
  } = {
    name: '',
    version: '',
  };

  if ('userAgentData' in navigator) {
    const navigator_UserAgentData_PlatformVersion = (
      navigator as any
    ).userAgentData.getHighEntropyValues(['platformVersion']);
    const ua = await navigator_UserAgentData_PlatformVersion;

    if ((navigator as any).userAgentData?.platform === 'Windows') {
      const majorPlatformVersion = parseInt(
        ua.platformVersion.split('.')[0],
        10
      );
      device.name = 'Windows';

      if (majorPlatformVersion >= 13) {
        device.version = '11 or later';
      } else if (majorPlatformVersion > 0) {
        device.version = '10';
      } else {
        device.version = 'Before 10';
      }
    } else if (
      ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].indexOf(
        (navigator as any).userAgentData?.platform ||
          (navigator as any).platform
      ) !== -1
    ) {
      device.name = 'Mac OS';
      device.version = ua.platformVersion || '';
    } else if (
      ['iPhone', 'iPad', 'iPod'].indexOf(
        (navigator as any).userAgentData?.platform ||
          (navigator as any).platform
      ) !== -1
    ) {
      device.name = 'iOS';
      const versionMatch = (navigator as any).userAgent.match(
        /OS (\d+_\d+(_\d+)?)/
      );
      if (versionMatch) {
        device.version = versionMatch[1].replace(/_/g, '.') || '';
      }
    } else if (/Android/.test((navigator as any).userAgent)) {
      device.name = 'Android';
      const versionMatch = (navigator as any).userAgent.match(
        /Android\s([0-9.]*)/
      );
      if (versionMatch) {
        device.version = versionMatch[1] || '';
      }
    } else if (
      /Linux/.test(
        (navigator as any).userAgentData?.platform ||
          (navigator as any).platform
      )
    ) {
      device.name = 'Linux';
      device.version = '';
    } else {
      device.name = '';
      device.version = '';
    }
  } else {
    device.name = '';
    device.version = '';
  }

  return device;
};
export const encrypt = async (text: string) => {
  const algorithm = 'AES-CBC';
  const keyString = process.env.REACT_APP_ENCRYPTION_KEY;
  const key = await window.crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(keyString),
    { name: algorithm, length: 256 },
    false,
    ['encrypt']
  );

  // Generate a random initialization vector (IV)
  const iv = window.crypto.getRandomValues(new Uint8Array(16));

  // Encrypt the text
  const encryptedData = await window.crypto.subtle.encrypt(
    { name: algorithm, iv },
    key,
    new TextEncoder().encode(text)
  );

  // Convert IV and encrypted data to hex
  const ivHex = Array.from(iv)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  const encryptedHex = Array.from(new Uint8Array(encryptedData))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

  return `${ivHex}:${encryptedHex}`;
};
