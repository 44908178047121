import { Pagination } from '@nextui-org/pagination';

// import { Pagination, Stack } from "@mui/material";
type PaginationButtonsProps = {
  count?: any;
  totalPage?: number; // Add this line
  onChange: any;
  page?: number;
  paginationPosition?: 'center' | 'right' | 'left';
};

const PaginationButtons = ({
  onChange,
  totalPage = 1,
  page,
  paginationPosition = 'center',
}: PaginationButtonsProps): JSX.Element => {
  const position =
    paginationPosition === 'center'
      ? 'justify-center'
      : paginationPosition === 'right'
        ? 'flex justify-end'
        : 'flex justify-start';

  return (
    <div className={`flex ${position}`}>
      <div className="flex justify-center p-1 space-x-1 rounded-lg">
        {/* Large Screens */}
        <Pagination
          showControls
          total={totalPage}
          page={page}
          onChange={(_page: number) => onChange(_page)}
          classNames={{
            base: 'inline-block',
            item: 'border border-borderBlue rounded-lg text-primaryGrey font-normal w-fit px-1',
            cursor:
              'border border-borderBlue rounded-lg bg-[#0E173F26] text-primaryGrey w-fit px-1',
            next: 'border border-borderBlue rounded-lg text-primaryGrey',
            prev: 'border border-borderBlue rounded-lg text-primaryGrey',
          }}
        />
      </div>
    </div>
  );
};

export default PaginationButtons;
