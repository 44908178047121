import { STEP_TYPE, ICON_TYPE } from '../../../utils/data/dashboardSteps';

const HomepageAccordion = ({
  data,
  icons,
}: {
  data: STEP_TYPE[];
  icons: ICON_TYPE[];
}) => {
  return (
    <div className="flex flex-col gap-10">
      {data.map((item, index) => {
        return (
          <div className="flex gap-4 md:gap-10 lg:gap-20 ">
            <div
              className={`flex justify-center items-start w-[15%] md:w-[12%]  lg:w-[10%] hover:drop-shadow-[0_0_3rem_cyan] ml-4`}
            >
              <img
                src={icons[index].icon}
                alt={icons[index].icon}
                className={`${icons[index].style}`}
              />
            </div>

            <div className="flex flex-col gap-2 flex-1">
              <div className="flex gap-4">
                <h1 className="text-xl md:text-2xl lg:text-3xl font-semibold">
                  {index + 1 + '. '}
                </h1>
                <h1 className="text-lg md:text-2xl lg:text-3xl font-semibold">
                  {item.title}
                </h1>
              </div>
              <div className="flex flex-col gap-[0.2rem] items-start ">
                {item.children.map((child: string, i: number) => (
                  <div className="flex gap-4 px-2 w-full">
                    <span className="text-[20px] self-start">•</span>
                    <span
                      className="text-sm lg:text-[20px] font-light leading-6 lg:leading-7"
                      dangerouslySetInnerHTML={{ __html: child }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HomepageAccordion;
