import React from 'react';
import {
  formatNumberWithCommas,
  toFixedWithoutRoundingOff,
} from '../../../../utils/utils';

type TUserStats = {
  title: string;
  value: number;
  blackBG?: boolean;
  token: string;
};
const UserStats = ({ title, value, blackBG, token }: TUserStats) => {
  return (
    <div className="relative p-[1px] rounded-lg overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-[#1B9FDA] to-[#0E173F] md:rounded-md xxs:rounded-[4px]"></div>
      <div className="relative bg-white xxs:px-5 md:px-4 md:py-3 xxs:py-1 rounded-md flex flex-col justify-between items-center">
        <span
          className={`font-[Rubik] text-base  font-medium ${blackBG ? 'text-black' : 'text-primary'}`}
        >
          {title}
        </span>
        <div className="flex items-center gap-2">
          <span
            className={`font-[Rubik] text-3xl  font-medium ${blackBG ? 'text-black' : 'text-primary'}`}
          >
            {/* {formatNumberWithCommas(Number(value?.toFixed(2)))} */}
            {formatNumberWithCommas(
              Number(toFixedWithoutRoundingOff(value, 6))
            )}
          </span>
          <sub className={`${blackBG ? 'text-black' : 'text-primary'}`}>
            {token || 'LYK-W'}
          </sub>
        </div>
      </div>
    </div>
  );
};

export default UserStats;
