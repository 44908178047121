/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { processRewardAnalyticsDataFunc } from './utils/SuperNode';
import { rewardAnalyticsData } from '../../data/superNode';
import { useSuperNodeActions } from '../../store/superNode/superNodeActions';
import { selectSuperNodeSlice } from '../../store/superNode/superNodeSlice';
import GlobalHeading from '../../components/Global/GlobalHeading';
import FlexKeyValueComp from './_components/FlexKeyValueComp';
import TotalStatsRender from './_components/TotalStatsRender';
import CommunityCard from './_components/CommunityCard';
import CardComponent from './_components/CardComponent';
import RewardsTable from './_components/RewardsTable';
import DailyReward from './_components/DailyReward';
import ActiveFirstLineNodes from './_components/ActiveFirstLineNodes';
import GlobalModal from '../../components/Global/GlobalModal';
import { selectNewsletter } from '../../store/newsLetter/newsLetterSlice';
import { INewsletterList } from '../../types/newsletter.types';
import Tooltips from '../../components/Tooltips';
import { InfoIcon } from '../../images/svgs';
import TotalGASKSection from './_components/TotalGASKSection/index.tsx';
import MyTree from './_components/MyTree';

export type TTabsData = {
  name: string;
  active: boolean;
  available: boolean;
}[];

const SuperNode = () => {
  const [selectedSuperNodeNewsLetter, setSelectedSuperNodeNewsLetter] =
    useState<INewsletterList | null>(null);

  const [selectedPathName, setSelectedPathName] = useState(false);
  const { newsletterList, unReadNewsletterCount, isNewsletterOpen } =
    useSelector(selectNewsletter);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const {
    superNodeRewardsAnalytics,
    myTreeData,
    rewardTreeLoading,
    superNodeSuperNodeRewardsLoading,
    getActiveFirstLineUsersLoading,
    getSuperNodeRewardLoading,
    getSngpScoreLoading,
    getProductionLoading,
    getRewardAnalyticsLoading,
    getTotalGaskLoading,
    getCurrentLossOfRewardsLoading,
    getMyTreeLoading,
    isUserLoading,
    userActiveStatus,
    currentLossOfRewardsLoss,
    totalGask,
    superNodeProductData,
    sngpScoreResponse,
    dailySuperNodeRewards
  } = useSelector(selectSuperNodeSlice);

  const {
    getUserStatus,
    getMyTreeRewards,
    getCurrentLossOfRewards,
    getTotalGask,
    getRewardAnalytics,
    getTotalProduction,
    getSngpScore,
    getSuperNodeDailyRewards,
    getActiveFirstLineUsers,
    claimReward,
    getRewardsTableData,
    getRewardsTree,
  } = useSuperNodeActions();

  function fetchSuperNodeAction() {
    if (!isUserLoading && !userActiveStatus) {
      getUserStatus();
    }
    if (!getMyTreeLoading && !myTreeData) {
      getMyTreeRewards();
    }
    if (!getCurrentLossOfRewardsLoading && !currentLossOfRewardsLoss) {
      getCurrentLossOfRewards();
    }
    if (!getTotalGaskLoading && !totalGask) {
      getTotalGask();
    }
    if (!getRewardAnalyticsLoading && !superNodeRewardsAnalytics) {
      getRewardAnalytics();
    }
    if (!getProductionLoading && !superNodeProductData) {
      getTotalProduction();
    }
    if (!getSngpScoreLoading && !sngpScoreResponse) {
      getSngpScore();
    }
    if (!getSuperNodeRewardLoading && !dailySuperNodeRewards) {
      getSuperNodeDailyRewards();
    }
    if (!getActiveFirstLineUsersLoading) {
      getActiveFirstLineUsers({ page: 1, limit: 10, type: 'base-referral' });
    }
    if (!rewardTreeLoading) {
      getRewardsTree({ depth: 1 });
    }
  }

  const handleClaimButton = async (): Promise<void> => {
    const { data } = await claimReward();

    if (data.status) {
      toast.success(data.data);
      data.status && getRewardAnalytics();
    }
  };

  const rewardAnalytics = useMemo(
    () =>
      processRewardAnalyticsDataFunc(
        rewardAnalyticsData,
        superNodeRewardsAnalytics
      ),
    [superNodeRewardsAnalytics]
  );

  const opeNewsLetterForSuperNode: INewsletterList[] =
    useMemo((): INewsletterList[] => {
      return newsletterList?.filter((newsletter: INewsletterList): boolean => {
        return newsletter.showsOn?.supernode === true;
      });
    }, [newsletterList]);

  useEffect((): void => {
    fetchSuperNodeAction();
  }, []);

  useEffect((): void => {
    if (opeNewsLetterForSuperNode.length > 0) {
      setSelectedPathName(true);
      setSelectedSuperNodeNewsLetter(opeNewsLetterForSuperNode[0]);
    } else setSelectedPathName(false);
  }, [opeNewsLetterForSuperNode]);

  const handleSNNewsletterModalClose = (): void => {
    setSelectedPathName(false);
  };

  return (
    <section className="flex flex-col gap-6">
      <div className="flex flex-col gap-2 xxs:items-start lg:items-end lg:justify-between lg:flex-row lg:space-y-0">
        <GlobalHeading
          heading={'Super Node'}
          className="flex-1 !text-defaultBlack"
        />
        <Alert
          severity="info"
          icon={false}
          sx={{
            width: isMobile ? '100%' : 'auto',
            borderRadius: '4px',
            '& .MuiAlert-message': {
              width: { width: isMobile ? '100%' : 'auto' },
            },
          }}
        >
          <DailyReward />
        </Alert>
      </div>
      <article className="flex justify-between gap-5 xxs:flex-col xl:flex-row">
        <CardComponent
          title="MY TREE"
          loading={getMyTreeLoading && myTreeData?.totalNodes === 0}
        >
          {/*<FlexKeyValueComp data={myTreeDataState} decimal={false} />*/}
          <MyTree data={myTreeData} />
        </CardComponent>
        <CardComponent
          title="SUPERNODE REWARDS"
          loading={
            getRewardAnalyticsLoading &&
            superNodeRewardsAnalytics?.totalRewards === 0
          }
          showInfoIcon
          infoIcon={
            <Tooltips
              placement={isMobile ? 'top' : 'left-start'}
              content={
                <div className="p-3 space-y-2 text-xs font-normal bg-white rounded-lg max-w-[250px] md:max-w-max shadow-md">
                  <p>
                    Actual Builder Generational Rewards:{' '}
                    <span className="font-semibold">
                      {
                        superNodeRewardsAnalytics?.actualbuilderGenerationalRewards
                      }
                    </span>
                  </p>

                  <p>
                    Actual Base Referral Rewards:{' '}
                    <span className="font-semibold">
                      {superNodeRewardsAnalytics?.actutalbaseReferralRewards}
                    </span>
                  </p>
                  <p>
                    Please note that you can only withdraw only up to the higher
                    <br />
                    amount of Base Referral and Builder Generational rewards.
                  </p>
                </div>
              }
              containerClass="mb-2"
            >
              <InfoIcon className={`w-5 h-5`} />
            </Tooltips>
          }
        >
          <FlexKeyValueComp
            data={rewardAnalytics}
            onClaimClick={handleClaimButton}
            btn
          />
        </CardComponent>
      </article>
      <TotalGASKSection />
      <article className="grid grid-cols-1 gap-4 xl:grid-cols-2">
        <ActiveFirstLineNodes />
        <TotalStatsRender />
      </article>
      {/*<ReferralChartCard />*/}
      <RewardsTable />
      <CommunityCard />
      <GlobalModal
        isOpen={selectedPathName}
        onClose={handleSNNewsletterModalClose}
        size="2xl"
        title={opeNewsLetterForSuperNode[0]?.newsDetails?.title}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: opeNewsLetterForSuperNode[0]?.newsDetails?.body ?? '',
          }}
        ></div>
      </GlobalModal>
    </section>
  );
};

export default SuperNode;
