import { useNavigate } from 'react-router-dom';
import { empty } from '../images/wallets';
import GlobalButton from './Global/GlobalButton';

export default function EmptyPage({
  title,
  message,
  buttonText,
  url,
  className = '',
}: {
  title: string;
  message: string;
  buttonText?: string;
  url?: string;
  className?: string;
}) {
  const navigate = useNavigate();

  return (
    <div
      className={`${className} space-y-3 w-full flex flex-col items-center justify-center bg-white border border-borderBlue rounded-xl py-5 lg:py-0`}
    >
      <img src={empty} alt="empty" className="w-32 h-32 sm:w-auto sm:h-auto" />

      <p className="text-primary text-sm sm:text-base text-center font-bold">
        {' '}
        {title}{' '}
      </p>

      <p className="text-primaryGrey text-xs sm:text-sm text-center">
        {' '}
        {message}{' '}
      </p>

      {buttonText && url && (
        <GlobalButton
          classNames="!w-40"
          // loadingType="circular"
          text={buttonText}
          onClick={() => navigate(url)}
        />
      )}
    </div>
  );
}
