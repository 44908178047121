import React, { useEffect, useState } from 'react';
import CloudKSelect from '../Common/CloudKSelect/CloudKSelect';
import CloudKTable from '../Common/CloudKTable/CloudKTable';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../store/cloudk/cloudkSlice';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';
import RangeDatePicker from '../../../Wallet/RangeDatePicker';
import moment from 'moment';
import { formatNumberWithCommas, toFixedFormat } from '../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { EmptyWithdrawHistoryGif } from '../../../../images/wallets';
import GetCSVData from '../../../../components/GetCSVData';

const RewardHistory = () => {
  const {
    allMachinesRewardHistoryData,
    allMachinesRewardHistoryLoading,
    machineRewardsHistoryLicensesDropdown,
  } = useSelector(selectCloudSlice);
  const { getAllMachinesRewardHistory, getMinterLicenses } = useCloudkActions();
  const [downloadTransaction, setDownloadTransaction] = useState([]);

  const [resetFilter, setResetFilter] = useState(false);
  const [filters, setFilters] = useState<{
    limit: number;
    page: number;
    selectedLicense: { label: string; value: string } | null;
    fromDate: string | null;
    toDate: string | null;
  }>({
    limit: 10,
    page: 1,
    selectedLicense: null,
    fromDate: null,
    toDate: null,
  });
  const [resetDisable, setResetDisable] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setResetFilter(false);
    getAllMachinesRewardHistory({
      ...filters,
      licenseId: filters.selectedLicense?.value,
    });
  }, [filters]);

  useEffect(() => {
    getMinterLicenses({ minterId: undefined });
  }, []);

  const columns = [
    {
      label: 'Linked License',
      key: 'licenseId',
      classNames: ' !text-center text-[#939393] font-medium',
    },
    {
      label: 'License Rewards',
      key: 'licenseReward',
      classNames: '!text-center text-[#939393] font-medium',
    },
    {
      label: 'Hardware Benefit',
      key: 'hardwareBenifit',
      classNames: '!text-center text-[#939393] font-medium',
    },
    {
      label: 'Date',
      key: 'date',
      classNames: '!text-center text-[#939393] font-medium',
    },
  ];

  // @ts-ignore
  const rows = allMachinesRewardHistoryData?.list?.map((row) => ({
    date: (
      <p className="text-[#121212] text-xs text-center">
        {moment(row.createdAt).format('lll')}
      </p>
    ),
    licenseId: (
      <p className="text-[#121212] text-xs text-center ">{row.identifier}</p>
    ),
    hardwareBenifit: (
      <div className="flex flex-col gap-2 items-center w-full">
        <div className="flex gap-3">
          <span className="text-xs text-[#939393]">LYK-R</span>
          <span className="text-xs font-medium text-[#7FC9FF]">
            {formatNumberWithCommas(
              Number(
                toFixedFormat(
                  Number(row.hardWare.lyk_r || 0),
                  4
                )
              )
            )}
          </span>
        </div>
        <div className="flex gap-3">
          <span className="text-xs text-[#939393]">LYK-W</span>
          <span className="text-xs font-medium text-[#7583FF]">
            {formatNumberWithCommas(
              Number(
                toFixedFormat(
                  Number(row.hardWare.lyk_w || 0),
                  4
                )
              )
            )}
          </span>
        </div>
      </div>
    ),
    licenseReward: (
      <div className="flex flex-col gap-2  items-center w-full">
        <div className="flex gap-3">
          <span className="text-xs text-[#939393]">LYK-R</span>
          <span className="text-xs font-medium text-[#7FC9FF]">
            {formatNumberWithCommas(
              Number(
                toFixedFormat(
                  Number(row.license.lyk_r || 0),
                  4
                )
              )
            )}
          </span>
        </div>
        <div className="flex gap-3">
          <span className="text-xs text-[#939393]">LYK-W</span>
          <span className="text-xs font-medium text-[#7583FF]">
            {formatNumberWithCommas(
              Number(
                toFixedFormat(
                  Number(row.license.lyk_w || 0),
                  4
                )
              )
            )}
          </span>
        </div>
      </div>
    ),
  }));

  const getFilteredApiData = async () => {
    const res = await getAllMachinesRewardHistory({
      ...filters,
      limit: (allMachinesRewardHistoryData as any)?.totalCount,
      licenseId: filters.selectedLicense?.value,
    });
    return res.data?.data?.list;


  };

  const filterDownloadData = (data: any) => {
    const result = data.map((dt: any, index: number) => {
      return {
        'Date': moment(dt.createdAt).format('lll'),
        'License Id': dt?.identifier,
        'HB. LYK-R': dt.hardWare.lyk_r,
        'HB. LYK-W': dt.hardWare.lyk_w,
        'LR. LYK-R': dt.license.lyk_r,
        'LR. LYK-W': dt.license.lyk_w,
      };
    });

    return setDownloadTransaction(result);

  };


  useEffect(() => {
    const noData = !rows?.length;
    const hasFilters =
      filters.selectedLicense || filters.fromDate || filters.toDate;

    // If there's no data and no filters, keep it disabled
    // If there's data but no filters, disable it
    // If there's data or filters are applied, enable it
    setResetDisable(noData || (!noData && !hasFilters));
  }, [rows, filters]);

  const handleLicenseChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilters((prev: any) => ({
      ...prev,
      selectedLicense: selectedOption,
    }));
  };

  const handleDateChange = (fromDate: string, toDate: string) => {
    const from = moment(fromDate).format('YYYY-MM-DD');
    const to = moment(toDate).format('YYYY-MM-DD');

    setFilters((prev: any) => ({
      ...prev,
      fromDate: from,
      toDate: to,
    }));
  };

  const handlePageChange = (page: number) => {
    setFilters((prev: any) => ({
      ...prev,
      page,
    }));
  };

  const noDataUI = <div className="flex-1 flex justify-center items-center w-full mt-5">
    <div className="flex flex-col gap-2 items-center ">
      <div className=" w-36 h-auto">
        <img src={EmptyWithdrawHistoryGif} alt="empty transactions" />
      </div>
      <div className="flex flex-col gap-1 items-center">
        <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium">
          Sorry, No Transactions History Found.
        </span>

      </div>
    </div>
  </div>;

  return (
    <div>
      <div className="mb-11 flex flex-wrap items-center">
        <span onClick={() => navigate('/defi-360/cloudk')} className="text-xl font-baumans cursor-pointer">
          CloudK Overview {'>'} &nbsp;
        </span>
        <span className="text-2xl font-baumans">Reward History</span>
      </div>

      <CloudKTable
        // @ts-ignore
        rows={rows || []}
        title={'Hardware Transaction History'}
        columns={columns}
        loading={allMachinesRewardHistoryLoading}
        // @ts-ignore
        totalPages={allMachinesRewardHistoryData?.totalPages}
        // @ts-ignore
        currentPage={allMachinesRewardHistoryData?.currentPage}
        // @ts-ignore
        onPageChange={(page) => handlePageChange(page)}
        renderNoData={noDataUI}
        filters={[
          <CloudKSelect
            options={machineRewardsHistoryLicensesDropdown.map((item) => ({
              label: item.identifier,
              value: item.userlicenseId,
            }))}
            placeholder={'License'}
            onChange={handleLicenseChange} // Handle the option change
            selectedOption={filters.selectedLicense}
          />,
          <RangeDatePicker
            handleDateDate={(fromDate, toDate) =>
              handleDateChange(fromDate, toDate)
            }
            resetFilter={resetFilter}
            toDateClassName={`!h-9 text-secondaryBlack text-sm`}
            fromDateClassName={`!h-9 text-secondaryBlack text-sm`}
          // setDateChanged={true}
          // fromDateDisable={resetDisable}
          // fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
          // toDateDisable={resetDisable}
          // toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
          />,
          <button
            className={`${resetDisable ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
            type="button"
            disabled={resetDisable}
            onClick={() => {
              setResetFilter(true);
              setFilters({
                limit: 10,
                page: 1,
                selectedLicense: null,
                fromDate: null,
                toDate: null,
              });
            }}
          >
            Reset
          </button>,
          <GetCSVData
            disabled={(allMachinesRewardHistoryData as any)?.totalPages <= 0}
            transactionData={downloadTransaction}
            fileName={`CloudkHardwareTransactionhistory.csv`}
            classes="flex justify-end"
            onclick={() => {
              if ((allMachinesRewardHistoryData as any)?.list?.length > 0) {
                getFilteredApiData().then((data: any) => {
                  filterDownloadData(data);
                });
              }
            }}
            onDownloadReady={() => {
              // Callback function to handle any additional logic if needed
            }}
          />
        ]}
      // paginationContainerStyle={'!justify-end'}
      // navigationText={`View All Rewards History`}
      // navigationStyle={``}
      // navigationShow={true}
      // navigationContainerStyle={``}
      />
    </div>
  );
};

export default RewardHistory;
