import GlobalHeading from '../../components/Global/GlobalHeading';
import { useEffect, useState } from 'react';
import VoucherCard from './components/VoucherCard';
import useUserCheck from '../../hooks/useUserCheck';
import { usePlatformAction } from '../../store/platform/platformAction';
import { EmptyVoucher } from '../../images/svgs';
import { voucherEmptyIcon } from '../../images/others';
import GlobalSkeleton from '../../components/Global/GlobalSkeleton';
import { selectPlatorfom } from '../../store/platform/platformSlice';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const colors = {
  active: ['#48C7FF', '#FFBA53', '#C5A1FF', '#99DFFF', '#72E9CC'],
  redeemed: '#A8C1D3',
  expired: '#858585',
};

let statusTabs = [
  { key: 'active', label: 'Active' },
  { key: 'redeemed', label: 'Redeemed' },
  { key: 'expired', label: 'Expired' },
];

const Vouchers = () => {
  const { vouchersData, userVouchersLoading } = useSelector(selectPlatorfom);
  const [vouchers, setVouchers] = useState([] as any);
  const [activeTab, setActiveTab] = useState('active');

  const { getUserVouchers } = usePlatformAction();
  const handleTimeTabClick = (buttonText: any) => {
    if (buttonText.key === 'redeemed') {
      setActiveTab('redeemed');
      setVouchers(
        vouchersData.filter((voucher: any) => voucher.redeemedAT !== null)
      );
    }
    if (buttonText.key === 'active') {
      setActiveTab('active');
      setVouchers(
        vouchersData.filter(
          (voucher: any) =>
            voucher.redeemedAT === null &&
            new Date(voucher.expiryAt) > new Date()
        )
      );
    }

    if (buttonText.key === 'expired') {
      setActiveTab('expired');
      setVouchers(
        vouchersData.filter(
          (voucher: any) => new Date(voucher.expiryAt) < new Date()
        )
      );
    }
  };

  useEffect(() => {
    if (!userVouchersLoading && vouchersData.length === 0) {
      getUserVouchers();
      setVouchers(
        vouchersData.filter(
          (voucher: any) =>
            voucher.redeemedAT === null &&
            new Date(voucher.expiryAt) > new Date()
        )
      );
    }
  }, []);
  return (
    <div className="">
      <GlobalHeading
        heading={`My Vouchers`}
        className="flex-1 !text-defaultBlack"
      />

      <div className="flex gap-2 my-8">
        {statusTabs.map((status) => {
          return (
            <button
              key={status.key}
              onClick={() => handleTimeTabClick(status)}
              className={`!px-2 !py-0.5 sm:!px-8 sm:!py-1.5 max-w-44 font-normal rounded-lg transition-all
                      hover:text-primary bg-transparent border ${activeTab === status.key && 'border-primary !bg-primary/10 text-primary'}`}
              style={{
                transformStyle: 'preserve-3d',
              }}
            >
              {activeTab === status.key && (
                <motion.span
                  layoutId="bubble"
                  className="absolute inset-0 bg-inherit rounded-lg !border-primary"
                  transition={{
                    type: 'spring',
                    bounce: 0.2,
                    duration: 0.6,
                  }}
                />
              )}
              <span
                className={`relative block ${activeTab === status.key ? 'text-primary' : 'text-primaryGrey'}`}
              >
                {status.label}
              </span>
            </button>
          );
        })}
      </div>

      {userVouchersLoading && vouchersData.length === 0 ? (
        <>
          {Array(1)
            .fill('')
            .map(() => (
              <GlobalSkeleton
                animationValue="wave"
                variant="rectangular"
                width={'55%'}
                className="relative w-full my-4 bg-white border rounded-lg shadow-lg cursor-pointer"
                height={180}
              />
            ))}
        </>
      ) : (
        <>
          {vouchers.length > 0 ? (
            <div className="flex flex-col gap-5">
              {vouchers.map((voucher: any, index: number) => (
                <VoucherCard
                  key={voucher.internalVoucherCode}
                  voucher={voucher}
                  disableBtn={activeTab !== 'active' ? true : false}
                  backgroundColor={
                    activeTab === 'redeemed'
                      ? colors.redeemed
                      : activeTab === 'expired'
                        ? colors.expired
                        : colors.active[index % colors.active.length]
                  }
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-1 bg-white justify-center h-[60vh] items-center w-full rounded-3xl border border-[#E9E9E9]">
              <div className="flex flex-col items-center w-full gap-6">
                <div className="flex items-center justify-center h-auto">
                  <div className="relative">
                    <EmptyVoucher />
                    <img
                      src={voucherEmptyIcon}
                      alt="No vouchers"
                      className="absolute top-7 left-[138px]"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center gap-2">
                  <span className="text-primary xxs:text-sm  sm:text-[18px] font-medium">
                    Sorry, No {activeTab} vouchers available
                  </span>
                  <span className="text-[#7D7D7D] xxs:text-[10px]  sm:text-xs font-normal">
                    Check back later for exciting offers!
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Vouchers;
