import { lazy } from 'react';

/* App Pages */
export const Login = lazy(() => import('./Auth/Login'));
export const Signup = lazy(() => import('./Auth/Signup'));
export const VerifyEmail = lazy(() => import('./Auth/VerifyEmail'));
export const SSOAuth = lazy(() => import('./Auth/SSOAuth/SSOAuth'));
export const EmailSent = lazy(() => import('./Auth/EmailSent'));
export const Terms = lazy(() => import('./Auth/Terms'));
export const Success = lazy(() => import('./Auth/Success'));
export const ReferralLink = lazy(() => import('./Auth/Referral'));
export const GoogleAuth = lazy(() => import('./Auth/GoogleAuth'));
export const ResetPassword = lazy(() => import('./Auth/ResetPassword'));
export const ForgetPassword = lazy(() => import('./Auth/ForgetPassword'));
export const AccountType = lazy(() => import('./Auth/AccountType'));
export const Maintenance = lazy(() => import('./Maintenance'));
export const Profile = lazy(() => import('./Profile'));
export const WalletsWithdraw = lazy(() => import('./Wallet/WalletsWithdraw'));
export const WalletsSwap = lazy(() => import('./Wallet/WalletSwap'));
export const SpecialSwap = lazy(() => import('./Wallet/SpecialSwap'));
export const WalletsHistory = lazy(() => import('./Wallet/WalletsHistory'));
// export const WalletsDeposit = lazy(() => import('./Wallet/WalletsDeposit'));
// export const ExternalApp = lazy(() => import('./ExternalApp'));
// export const Vouchers = lazy(() => import('./Vouchers'));
// export const Dashboard = lazy(() => import('./Dashboard'));
// export const WalletNew = lazy(() => import('./Wallet/index'));
export const Cloud2 = lazy(() => import('./Cloud2'));
export const Walletk = lazy(() => import('./Walletk'));
export const Xera = lazy(() => import('./Xera'));
export const Wiki = lazy(() => import('./Wiki'));
export const Legal = lazy(() => import('./Legal'));
export const MyFriendsPage = lazy(() => import('./MyFriends'));
// export const SuperNode = lazy(() => import('./SuperNode'));
// export const Support = lazy(() => import('./Support'));
export const PageNotFound = lazy(() => import('./PageNotFound'));
export const Notifiations = lazy(() => import('./Notification'));
export const Newsletter = lazy(() => import('./Newsletter'));
export const Defi = lazy(() => import('./Defi'));
// export const CountryPools = lazy(() => import('./GlobalPool/CountryPools'));
// export const SNGP = lazy(() => import('./GlobalPool/SNGP'));
export const Cloudk2PurchasePage = lazy(() => import('./Cloud2/purchase'));
export const RewardsCalculator = lazy(
  () =>
    import('./Cloud2/components/RenderCloudMain/components/RewardsCalculator')
);
export const Impersonate = lazy(() => import('./Auth/Impersonate'));
export const Community = lazy(() => import('./Community'));
export const CloudKTransactionHistory = lazy(
  () => import('./Defi/Cloudk/TransactionHistory')
);
export const CloudKSoftwareUpgrade = lazy(
  () => import('./Defi/Cloudk/SoftwareUpgrade')
);
export const CloudkOverviewLicenses = lazy(
  () => import('./Defi/Cloudk/CloukOverviewLicenses')
);
export const CloudkProcessedCheckout = lazy(
  () => import('./Defi/Cloudk/Checkout')
);
export const CloudkLicenseUnlock = lazy(
  () => import('./Defi/Cloudk/LicenseUnlock')
);
