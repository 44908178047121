export interface STEP_TYPE {
  title: string;
  children: string[];
}

export interface ICON_TYPE {
  icon: string;
  style?: string;
}

export const steps: STEP_TYPE[] = [
  {
    title: 'Choose Your Plan',
    children: [
      'Discover the power of automated trading with Quantwise license. Quantwise Plus .',
      "<b style='color: #0E173F;'>Quantwise Plus:</b> Quantiwise plus is ideal for beginners, featuring flexible deposits and a 50P. software fee. ",
      "<b style='color: #0E173F;'>Quantwise Pro:</b> Quantwise Pro offers enhanced versatility with a 40P. software fee.",
      "<b style='color: #0E173F;'>Quantwise Expert:</b> Quantwise Expert is designed for seasoned operators, providing advanced tools with a 30P. software fee.",
      'Unlock your potential with Quantwise, tailored for every level of expertise.',
    ],
  },
  {
    title: 'Set Up Your Device',
    children: [
      'If you have chosen a plan with Minters, follow the instructions to install and set up your device at home.',
    ],
  },
  {
    title: 'Download and Install Apps',
    children: [
      'Download the necessary Homnifi apps and dApps from the official website or app store.',
      'Install these applications on your devices to start interacting with the Web3 ecosystem.',
    ],
  },
  {
    title: 'Create Your Account',
    children: [
      'Register and create your Homnifi account.',
      'Complete the onboarding process, including any necessary verifications.',
    ],
  },
  {
    title: 'Explore Defi360',
    children: [
      'Access Defi360 through the Homnifi platform to start exploring various DeFi ecosystems.',
      'Use the unified interface to lend, borrow, trade, and lock assets across multiple DeFi platforms.',
    ],
  },
];

export const starts: STEP_TYPE[] = [
  {
    title: 'Access to Web3',
    children: [
      'A single gateway to the Web3 world, allowing seamless interaction with blockchain technologies and decentralized applications.',
    ],
  },
  {
    title: 'Enhanced Computational Power',
    children: [
      'For users with Minters, enhanced computational power and abilities to support token development and other Web3 activities.',
    ],
  },
  {
    title: 'Comprehensive Defi Access',
    children: [
      'With Defi360, you gain comprehensive access to various decentralized finance ecosystems.',
      'Engage in different financial activities across multiple platforms without switching interfaces.',
    ],
  },
  {
    title: 'User-friendly Interface',
    children: [
      'An easy-to-use interface designed to simplify the user experience, making it accessible even for those new to blockchain and DeFi.',
    ],
  },
  {
    title: 'Regular Updates and Support',
    children: [
      "Continuous updates and dedicated support to ensure you have the best experience with Homnifi's services and products.",
    ],
  },
];
