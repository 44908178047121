import { useEffect, useState } from 'react';
import GlobalButton from '../../../../../components/Global/GlobalButton';
import GlobalSelectMui from '../../../../../components/Global/GlobalSelectMui';
import ApiRequestCloudk from '../../../../../utils/network/ApiRequestCloudk';
import {
  LICENSE_LOCKUP_PERIOD,
  USER_LICENSES_STAKE_UPGRADE_PERIOD,
} from '../../../../../utils/network/ApiEndpoints';
import { toast } from 'react-toastify';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import { INVALID_TOKEN, UNAUTHORIZED } from '../../../../../utils/constants';

interface lockupPeriodFilterType {
  value: string;
  name: string;
}
const UpgradeStakePeriods = ({
  selectedMintingToken,
  closeFunc,
  handleUpgradeStakePeriods,
}: any) => {
  const [licenseLockupPeriod, setLicenseLockupPeriod] = useState<
    string[] | null
  >(null);
  const [selectedOption, setSelectedOption] =
    useState<lockupPeriodFilterType | null>(null); // Updated type
  const [upgradeStakePeriodLoading, setUpgradeStakePeriodLoading] =
    useState(false);

  const { getAllLicenses, getAllUserLinkLicenses } = useCloudkActions();

  const lockupOptions = licenseLockupPeriod
    ?.filter(
      (period) =>
        period !== selectedMintingToken?.lockedPeriod &&
        period > selectedMintingToken?.lockedPeriod
    ) // Exclude the locked period
    ?.map((period) => ({
      value: period,
      name: period === 'max' ? 'Max Period' : `${period} Months`,
    })) as lockupPeriodFilterType[];

  const getLicenseLockupPeriod = () => {
    return ApiRequestCloudk()
      .request({
        method: 'GET',
        url: LICENSE_LOCKUP_PERIOD,
      })
      .then((response) => {
        const lockupPeriod = response.data?.data.lockupPeriod;
        setLicenseLockupPeriod(lockupPeriod);
        return lockupPeriod;
      })
      .catch((error) => {
        toast.error('Error fetching license lockup Period:', error);
        return error;
      });
  };

  useEffect(() => {
    getLicenseLockupPeriod();
  }, []);

  const upgradeLicenseStakePeriod = async (id: string) => {
    setUpgradeStakePeriodLoading(true);
    const data = {
      collateralLockedPeriod: selectedOption?.value,
    };
    return ApiRequestCloudk()
      .request({
        method: 'PUT',
        url: `${USER_LICENSES_STAKE_UPGRADE_PERIOD}/${id}`,
        data,
      })
      .then((response) => {
        setUpgradeStakePeriodLoading(false);
        getAllLicenses();
        getAllUserLinkLicenses({
          limit: 10,
          page: 1,
          status: '',
          date: '',
          query: '',
          fromDate: null,
          toDate: null,
        });

        if (response.data.status) {
          toast.success('Stake Period Upgrade successfuly!');
        }
        closeFunc();
        return response;
      })
      .catch((error) => {
        const errorMessage =
          error?.data?.message || 'Something went wrong. Please try again.';
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(errorMessage);
        setUpgradeStakePeriodLoading(false);
        return error;
      });
  };

  const handleUpdateLockedupPeriod = async () => {
    await upgradeLicenseStakePeriod(selectedMintingToken?._id)
      .then((response) => {
        if (response.data.status) {
          handleUpgradeStakePeriods(selectedOption?.value);
          closeFunc();
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  //   const { availableCloudKBalance } = useSelector(selectCloudSlice);

  return (
    <div className="flex flex-col gap-2  p-6">
      <span className="font-medium text-[#292929]">Selected License</span>
      <span className="font-medium text-[#121212] text-2xl">
        {selectedMintingToken?.name}
      </span>

      <div className="flex flex-col gap-4 mt-5">
        <div className="flex-1 flex items-start justify-between flex-col gap-5">
          <div className="flex flex-col gap-2 w-full">
            <div className="text-defaultBlack text-sm font-medium">
              Lockup Period :{/* <span className="text-redDanger">*</span> */}
              <span className="text-defaultBlack text-sm ">
                {selectedMintingToken?.lockedPeriod &&
                  (selectedMintingToken.lockedPeriod === 'max'
                    ? 'Max period'
                    : `${selectedMintingToken.lockedPeriod} Months`)}
              </span>
            </div>
            {
              <>
                <GlobalSelectMui
                  required={true}
                  className={`!rounded-[10px]`}
                  upgradeStakeText={'Get 60% more'}
                  upgradeStakeBtn={true}
                  options={
                    lockupOptions
                      ? lockupOptions?.map((item: any) => ({
                        ...item,
                        value: item.value,
                      }))
                      : []
                  }
                  selectedOption={selectedOption}
                  setSelectedOption={(option: any) => {
                    setSelectedOption(option);
                  }}
                  placeHolder={'Select a lockup period'}
                  firstAutoSelected={false}
                />
              </>
            }
          </div>
        </div>

        <GlobalButton
          text="Update Changes"
          loading={upgradeStakePeriodLoading}
          type="button"
          disabled={upgradeStakePeriodLoading && !selectedOption ? true : false}
          classNames={`!w-full rounded-[10px] !text-base !py-[10px] px-5 min-w-max place-item-end 
           ${!upgradeStakePeriodLoading && selectedOption ? '!bg-updateBtnGredient' : 'bg-[#C0D5DF]'} font-medium !text-[#FFFFFF]`}
          onClick={() => handleUpdateLockedupPeriod()}
        />
      </div>
    </div>
  );
};

export default UpgradeStakePeriods;
