import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Update or add a query parameter in the URL.
 *
 * @param key - The key of the query parameter to update or add.
 * @param value - The new value of the query parameter.
 */
const useUpdateQueryParam = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const updateQueryParam = (key: string, value: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);

    // Construct the new URL
    const newUrl = `${location.pathname}?${searchParams.toString()}`;

    // Navigate to the new URL without reloading the page
    navigate(newUrl, { replace: true });
  };

  return updateQueryParam;
};

export default useUpdateQueryParam;
