import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { WalletPieChart } from '../../components/WalletPieChart';
import { pieChartBg } from '../../images/wallets';
import { selectRewardSlice } from '../../store/Cloudk2/rewardCalculatorSlice';
import {
  PieChartDataInterface,
  selectWalletData,
  TokenData,
} from '../../store/walletData/walletDataSlice';
import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';
import GlobalSkeleton from '../../components/Global/GlobalSkeleton';
import HoverBorderAnimation from '../../components/HoverBorderAnimation';

const WalletPieChartGraph = () => {
  const { walletList } = useSelector(selectWalletData);
  const { LYK } = useSelector(selectRewardSlice);
  const { loading } = useSelector(selectWalletData);
  const [totalBalance, setTotalBalance] = React.useState(0);
  const [totalPercentage, setTotalPercentage] = React.useState(0);

  React.useEffect(() => {
    if (LYK) {
      setTotalBalance(walletList?.total * LYK);
    }
  }, [LYK, walletList]);

  useEffect(() => {
    setTotalPercentage(
      walletList?.wallets?.reduce(
        (total: number, item: TokenData) => total + parseFloat(item.percentage),
        0
      )
    );
  }, [walletList]);

  function hexToRgb(hex: string) {
    hex = hex.replace(/^#/, '');

    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
  }

  function rgbToGrayscale(r: number, g: number, b: number) {
    return Math.round(0.299 * r + 0.587 * g + 0.114 * b);
  }

  function blendColors(original: number, grayscale: number, weight: number) {
    return Math.round(original * (1 - weight) + grayscale * weight);
  }

  function applyGrayscaleEffect(hexColor: string) {
    const { r, g, b } = hexToRgb(hexColor);
    const grayscale = rgbToGrayscale(r, g, b);

    const scaledGrayscale = Math.round(grayscale * 0.9);

    const blendedR = blendColors(r, scaledGrayscale, 0.9);
    const blendedG = blendColors(g, scaledGrayscale, 0.9);
    const blendedB = blendColors(b, scaledGrayscale, 0.9);

    return `rgb(${blendedR}, ${blendedG}, ${blendedB})`;
  }

  const modifiedDataPieChart: PieChartDataInterface[] = walletList?.wallets
    ? walletList?.wallets
        ?.filter(
          (item: TokenData) =>
            (Number(item.percentage) === 0 && item?.token?.showZeroBalance) ||
            Number(item.percentage) > 0
        )
        ?.map((item: TokenData) => {
          return {
            id: item.token.name,
            label: item.token.name,
            value: Number(item.percentage) < 1 ? 1 : item.percentage,
            actualValue: item.percentage,
            color:
              item.dollarBalance === 0 ||
              (item.dollarBalance && item.dollarBalance < 0)
                ? applyGrayscaleEffect(
                    item.token.color === 'black' ? '#000000' : item.token.color
                  )
                : item.token.color,
            dollarBalance: item.dollarBalance,
          };
        })
    : [];

  const modifiedColorsPieChart = walletList?.wallets
    ? walletList.wallets
        ?.filter(
          (item: TokenData) =>
            (Number(item.percentage) === 0 && item?.token?.showZeroBalance) ||
            Number(item.percentage) > 0
        )
        ?.map((item: TokenData) =>
          Number(item.percentage) || Number(item.percentage) >= 0
            ? item.dollarBalance === 0 ||
              (item.dollarBalance && item.dollarBalance < 0)
              ? applyGrayscaleEffect(
                  item.token.color === 'black' ? '#000000' : item.token.color
                )
              : item.token.color
            : '#E6E6E6'
        )
    : [];

  // Check if all wallet values are 0
  const allValuesZero = modifiedDataPieChart.every(
    (wallet: PieChartDataInterface) => Number(wallet.value) === 0
  );

  // Calculate the percentage based on the array length
  const percentageValue = Number(100 / 6).toFixed(2);
  // Create the adjusted data array based on the condition
  const adjustedDataPieChart =
    allValuesZero || modifiedDataPieChart.length === 0
      ? Array.from({ length: 6 }, (_, index) => ({
          id: `Name-0${index + 1}`,
          label: `Name-0${index + 1}`, // Include the index in the label
          value: percentageValue,
          actualValue: percentageValue,
          name: `Name-0${index + 1}`,
        }))
      : modifiedDataPieChart;
  return (
    <HoverBorderAnimation className="xl:col-span-4 col-span-12  rounded-[20px] bg-white border border-[#E6E6E6] flex flex-col justify-between xl:order-first order-last min-h-96 radius-22px">
      {/* {loading ? (
        <div className="flex items-center justify-center flex-1 h-full min-h-32">
          <div className="items-center self-center justify-center">
            <div className="flex flex-col gap-5">
              <Spinner className="h-6 animate-spin" />
            </div>
          </div>
        </div>
      ) : ( */}
      <>
        <div className=" flex flex-col flex-1 rounded-[20px] relative bg-pieChartBgImg bg-cover bg-center">
          {Number(totalBalance) <= 0 ? (
            <div
              className={`absolute inset-0 rounded-[20px] ${'grayscale opacity-50'}`}
              style={{
                backgroundImage: `url(${pieChartBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: 0,
              }}
            ></div>
          ) : (
            <></>
          )}

          <div className="relative flex items-center justify-center h-full py-8 walletPieChart">
            <div className="xxs:h-[220px] xxs:w-[220px] xs:h-[300px] xs:w-[300px] sm:w-[350px] sm:h-[350px] xl:h-[220px] xl:w-[220px] 2xl:h-[300px] 2xl:w-[300px] screen1640:w-[350px] screen1640:h-[350px] ">
              <WalletPieChart
                data={adjustedDataPieChart}
                colors={
                  allValuesZero || modifiedDataPieChart.length === 0
                    ? Array(6).fill('#E6E6E6')
                    : modifiedColorsPieChart
                }
                valueFormat={(e: number) => (e === 100 ? 0 : e) + '%'}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                innerRadius={0.5}
                padAngle={0.3}
                totalPercentage={totalPercentage}
                isInteractive={
                  allValuesZero || modifiedDataPieChart.length === 0
                    ? false
                    : true
                }
                loading={loading}
                // arcLinkLabel={(d: any) => {
                //   return `${d.data.actualValue}P.`;
                // }}

                arcLinkLabel={(d: any) => {
                  return (
                    <tspan>
                      <tspan
                        x="3"
                        dy="0"
                        style={{
                          fontWeight: '500',
                          fontSize: '14px',
                        }}
                      >
                        {d.data.actualValue}%
                      </tspan>
                    </tspan>
                  ) as any;
                }}
              />
            </div>
          </div>
        </div>
        {allValuesZero ? (
          <></>
        ) : (
          <div className="grid  grid-cols-2 xxs:gap-x-2  sm:gap-x-10 gap-y-2 border-t-[#D9E4F1] h-auto border-t p-6  bg-[#FEFCFC] rounded-b-[20px]">
            {loading && modifiedDataPieChart.length === 0 ? (
              <>
                <div className="flex items-center justify-between w-full gap-2">
                  <GlobalSkeleton
                    animationValue="wave"
                    variant="text"
                    sx={{ fontSize: '30px' }}
                    width={'90%'}
                  />
                </div>
                <div className="flex items-center justify-between w-full gap-2">
                  <GlobalSkeleton
                    animationValue="wave"
                    variant="text"
                    sx={{ fontSize: '30px' }}
                    width={'90%'}
                  />
                </div>
              </>
            ) : allValuesZero || modifiedDataPieChart.length === 0 ? (
              adjustedDataPieChart.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between col-span-1  xxs:gap-2 sm:gap-5"
                  >
                    <div className="flex items-center xxs:gap-2 sm:gap-5">
                      <div
                        className={`w-4 h-4 rounded-full `}
                        style={{
                          background: '#E6E6E6',
                        }}
                      ></div>
                      <span className="text-[10px] ">{item.name}</span>
                    </div>
                    <span className="text-[10px] ">{item.id}</span>
                  </div>
                );
              })
            ) : (
              modifiedDataPieChart.map(
                (item: PieChartDataInterface, index: number) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between col-span-1  xxs:gap-2 sm:gap-5"
                    >
                      <div className="flex items-center xxs:gap-2 xl:gap-2 2xl:gap-5 sm:gap-5">
                        <div
                          className={`w-4 h-4 rounded-full `}
                          style={{
                            background: item.color,
                          }}
                        ></div>
                        <span className="text-[12px] ">{item.label}</span>
                      </div>
                      <span className="text-[12px] ">{item.actualValue}%</span>
                    </div>
                  );
                }
              )
            )}
          </div>
        )}
      </>
      {/* )} */}
    </HoverBorderAnimation>
  );
};

export default WalletPieChartGraph;
