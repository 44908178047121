import { useDispatch, useSelector } from 'react-redux';
import useModalHook from '../../../../hooks/useModalHook.ts';
import {
  selectSuperNodeSlice,
  setSuperNodeSlice,
} from '../../../../store/superNode/superNodeSlice.ts';
import {
  TABS_OPTIONS,
  IMyTreeUsers,
  IHeadCells,
} from '../../../../types/superNodes.type.ts';
import { useSuperNodeActions } from '../../../../store/superNode/superNodeActions.ts';
import CardLayout from '../../../../components/Cards/CardLayout/index.tsx';
import GlobalTable from '../../../../components/Global/GlobalTable.tsx';
import GlobalModal from '../../../../components/Global/GlobalModal.tsx';
import TopUsersModalContent from './TopUsersModalContent.tsx';
import HoverBorderAnimation from '../../../../components/HoverBorderAnimation.tsx';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import ArrowForward from '../../../../images/arrow_forward.png';
import { profileImg } from '../../../../images';
import { toFixedWithoutRoundingOff } from '../../../../utils/utils.ts';

const ActiveFirstLineNodes = (): ReactElement => {
  const dispatch = useDispatch();
  const { open, handleClose, handleOpen } = useModalHook();
  const {
    getUserDailyRewards,
    getUserChartDetail,
    getUserRewardHistory,
    getActiveFirstLineUsers,
  } = useSuperNodeActions();
  const { getActiveFirstLineUsersLoading, activeFirstLineUsers } =
    useSelector(selectSuperNodeSlice);

  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(10);

  const [tabs, setTabs] = useState<TABS_OPTIONS>(
    TABS_OPTIONS.BASE_REFERRAL_REWARDS
  );

  const headCells: IHeadCells[] = [
    {
      id: 'user',
      label: 'User',
      dataClass: 'w-[40%] !pl-8 text-sm md:text-md ',
    },
    {
      id: 'machines',
      label: 'Machines',
      dataClass: 'w-[20%] text-center text-sm lg:text-md',
    },
    {
      id: 'rewards',
      label: 'My Rewards',
      dataClass: 'w-[30%] text-center text-sm lg:text-md',
    },
    {
      id: 'action',
      label: 'View ',
      dataClass: 'w-[15%] text-center text-sm lg:text-md',
    },
  ];

  const fetchUserDetails = async (user: IMyTreeUsers): Promise<void> => {
    handleOpen();
    dispatch(
      setSuperNodeSlice({
        detailedUser: user,
      })
    );
    try {
      await Promise.allSettled([
        getUserDailyRewards(user?._id),
        getUserChartDetail('weekly', user?._id),
        getUserRewardHistory({ userId: user?._id }),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleSelectTab = async (tab: TABS_OPTIONS): Promise<void> => {
    setTabs(tab);
    handleFetchDataOnPagination(1, tab);
  };

  const handleFetchDataOnPagination = useCallback(
    async (page: any, tab: string = 'base-referral'): Promise<void> => {
      await getActiveFirstLineUsers({
        page: page,
        type: tab,
      });
    },
    [getActiveFirstLineUsers]
  );

  useEffect((): void => {
    if (activeFirstLineUsers) {
      setTotalPages(activeFirstLineUsers?.totalPages || 1);
      setTotalCount(activeFirstLineUsers?.totalCount || 0);
    }
  }, [activeFirstLineUsers]);

  const rows =
    activeFirstLineUsers?.list.length > 0
      ? activeFirstLineUsers?.list.map((trx: any, i: number) => {
        return {
          user: (
            <div className="flex items-center 2xl:gap-3 md:gap-2 xxs:gap-1">
              <span className="w-12">
                <img
                  src={trx?.user?.profilePicture || profileImg}
                  alt="Avatar"
                  className="w-full h-full rounded-full md:min-w-11 md:min-h-11 xxs:min-w-8 xxs:min-h-8"
                />
              </span>
              <div className="flex flex-col w-full">
                <span className="font-normal capitalize text-defaultBlack xxs:text-sm md:text-md">
                  {trx?.user?.username || '-'}
                </span>
                <span className="text-sm font-normal text-primaryGrey xxs:text-sm md:text-sm">
                  bid: {trx?.user?.blockchainId || '-'}
                </span>
              </div>
            </div>
          ),
          machines: (
            <div className="flex items-center justify-center xxs:text-sm md:text-md">
              {trx.machineCount || 0}
            </div>
          ),
          rewards: (
            <div className="flex items-center justify-center xxs:text-sm md:text-md">
              {toFixedWithoutRoundingOff(trx?.totalRewards, 6) || 0}
            </div>
          ),
          action: (
            <div className="flex justify-center ">
              <div
                onClick={(): Promise<void> => fetchUserDetails(trx?.user)}
                className=" flex items-center justify-center w-[29px] !h-[29px] bg-primary rounded-md text-white"
              >
                <img
                  src={ArrowForward}
                  alt={'Arrow'}
                  style={{ objectFit: 'contain', width: 12, height: 12 }}
                />
              </div>
            </div>
          ),
        };
      })
      : [];

  return (
    <>
      <GlobalModal
        isOpen={open}
        onClose={handleClose}
        size="5xl"
        childrenClass="xxs:px-3 md:px-6 small-scroll"
      >
        <TopUsersModalContent />
      </GlobalModal>
      <HoverBorderAnimation className="radius-5px">
        <CardLayout
          className="bg-white h-[450px] box-border !rounded-md md:outline md:outline-1 md:outline-slate-200 md:px-6 "
          isBorder={false}
        >
          <p
            className={
              'text-lg text-black font-medium py-4 ml-4 md:ml-0 md:mt-1 '
            }
          >
            ACTIVE FIRST LINE USERS
          </p>
          <div className="relative flex flex-row justify-center w-full cursor-pointer md:px-0">
            <div
              onClick={(): Promise<void> =>
                handleSelectTab(TABS_OPTIONS.BASE_REFERRAL_REWARDS)
              }
              className={`flex w-full xxs:h-12 md:h-auto ${tabs === TABS_OPTIONS.BASE_REFERRAL_REWARDS ? ` bg-card-header-gradient text-white` : 'bg-gray-200 text-black'} md:p-5 rounded-tl-lg  font-[500] md:text-md  xxs:text-sm items-center justify-center`}
            >
              {'Base Referral Rewards'}
            </div>
            <div
              onClick={(): Promise<void> =>
                handleSelectTab(TABS_OPTIONS.BUILDER_GENERATIONAL)
              }
              className={`flex w-full xxs:h-12 md:h-auto ${tabs === TABS_OPTIONS.BUILDER_GENERATIONAL ? ` bg-card-header-gradient text-white` : 'bg-gray-200 text-black'} md:p-5 rounded-tr-lg  font-[500] md:text-md  xxs:text-sm items-center justify-center`}
            >
              {'Builder Generational'}
            </div>
          </div>

          <div className="flex-grow overflow-hidden ">
            <GlobalTable
              rows={rows}
              headCells={headCells}
              externalLoader={getActiveFirstLineUsersLoading}
              searchIsLoading={false}
              totalPages={totalPages}
              emptyDataMessage="No Active First Line Nodes!"
              showTotalRecords={false}
              searchFieldPlaceholderText="Search"
              pagination={true}
              paginationPosition={'right'}
              handlePageChange={(page: number): Promise<void> =>
                handleFetchDataOnPagination(page, tabs)
              }
              rowBorder={true}
              rounded={false}
              borderClassName="!border-r-0 !border-l-0 !border-borderGrey "
              showSearch={false}
              showDateFilter={false}
              tableWidth=" flex-1 flex flex-col"
              headStyles="!font-[400] !text-[12px] !lg:text-[12px] text-primaryGrey  px-3"
              containerStyles="md:!border-0  xxs:!border xxs:!border-[#D9E4F1] xxs:rounded-[8px] flex flex-col "
              mainContainerClassnames=""
              isFirstLineUser={true}
              tableContainerClassname={'flex-1'}
              tableBodyClassnames={' flex-grow overflow-y-scroll'}
            />
          </div>
        </CardLayout>
      </HoverBorderAnimation>
    </>
  );
};

export default ActiveFirstLineNodes;
