import React, { ReactNode } from 'react';
import PrelineInit from './PrelineInit';

type Props = {
  children: ReactNode;
};

const PrelineSetup = ({ children }: Props) => {
  return (
    <React.Suspense fallback={''}>
      <PrelineInit>{children}</PrelineInit>
    </React.Suspense>
  );
};

export default PrelineSetup;
