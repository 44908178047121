import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserSlice,
  setProfileCompletePercentage,
  setUserSlice,
} from '../store/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { OTP_METHOD } from '../components/interface/auth/auth.interface';
import { PAGES } from '../utils/routers';
import { useUserActions } from '../store/user/userActions';

const useUserCheck = () => {
  const { user, isProfileCompletePercentage, profileCompletePercentage } =
    useSelector(selectUserSlice);
  const navigate = useNavigate();

  const { getUser } = useUserActions();

  const dispatch = useDispatch();

  const fetchUser = async () => {
    return await getUser();
  };

  const checkValidation = () => {
    if (user) {
      if (user.isImpersonateMode) {
        return navigate(PAGES.DASHBOARD, {
          replace: true,
        });
      }
      if (!user.accountType) {
        navigate(PAGES.ACCOUNT_TYPE, {
          replace: true,
        });
        return;
      }
      if (!user.termsAccepted) {
        navigate(PAGES.TERMS, {
          replace: true,
        });
        return;
      }
      if (user.otpMethod === OTP_METHOD.EMAIL) {
        navigate(PAGES.GOOGLE_AUTH, {
          replace: true,
        });
        return;
      }
      if (!user.referralSubmitted) {
        navigate(PAGES.REFERRAL_LINK, {
          replace: true,
        });
        return;
      }

      return navigate(PAGES.DASHBOARD, {
        replace: true,
      });
    }
     else {
      navigate(PAGES.LOGIN, {
        replace: true,
      });
    }
  };

  const checkLayoutValidation = () => {
    if (user) {
      if (user.isImpersonateMode) {
        return navigate(PAGES.DASHBOARD, {
          replace: true,
        });
      }
      if (!user.accountType) {
        navigate(PAGES.ACCOUNT_TYPE, {
          replace: true,
        });
        return;
      }
      if (!user.termsAccepted) {
        navigate(PAGES.TERMS, {
          replace: true,
        });
        return;
      }
      if (user.otpMethod === OTP_METHOD.EMAIL) {
        navigate(PAGES.GOOGLE_AUTH, {
          replace: true,
        });
        return;
      }
      if (!user.referralSubmitted) {
        navigate(PAGES.REFERRAL_LINK, {
          replace: true,
        });
        return;
      }
    } 
    
    else {
      navigate(PAGES.LOGIN, {
        replace: true,
      });
    }
  };

  const checkProfileCompletePercentage = () => {
    if (!isProfileCompletePercentage && profileCompletePercentage < 100) {
      if (user?.firstName || user?.lastName) {
        dispatch(setProfileCompletePercentage(20));
      }
      if (user?.address && user?.country && user?.phone) {
        dispatch(setProfileCompletePercentage(20));
      }
      if (user?.kycApproved) {
        dispatch(setProfileCompletePercentage(50));
      }

      dispatch(
        setUserSlice({
          isProfileCompletePercentage: true,
        })
      );
    }
  };

  const checkLoginValidation = () => {
    checkValidation();
  };

  return {
    user,
    checkValidation,
    fetchUser,
    checkLoginValidation,
    checkLayoutValidation,
    checkProfileCompletePercentage,
    profileCompletePercentage,
    isProfileCompletePercentage,
  };
};

export default useUserCheck;
