import React from 'react';

type Props = {
  name: string;
  version: string;
  description: string;
};

const AppTooltip = ({ name, version, description }: Props) => {
  return (
    <div className="flex flex-col gap-2 w-full bg-primaryDark/60 text-white px-4 py-2 rounded">
      <div className="flex justify-between w-full">
        <div className="flex gap-2 items-center">
          <span className="font-medium">{name}</span>{' '}
          <span className="text-sm text-[#FFC267]">{version}</span>
        </div>
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.14566 6.89449C9.07629 6.65805 8.78149 6.65805 8.71213 6.89449C7.76815 10.1037 6.95839 11.0305 4.15438 12.1109C3.94854 12.1903 3.94854 12.5277 4.15438 12.6071C6.95839 13.6875 7.76815 14.6143 8.71213 17.8227C8.78149 18.0591 9.07629 18.0591 9.14566 17.8227C10.0896 14.6134 10.8994 13.6866 13.7027 12.6071C13.9092 12.5277 13.9092 12.1903 13.7027 12.1109C10.8986 11.0305 10.0889 10.1037 9.14566 6.89449ZM16.5655 4.12879C16.515 3.95707 16.3001 3.95707 16.2496 4.12879C15.5612 6.4682 14.9716 7.14388 12.9276 7.93087C12.7775 7.98869 12.7775 8.23463 12.9276 8.29244C14.9716 9.0803 15.5619 9.75511 16.2496 12.0945C16.3001 12.2671 16.515 12.2671 16.5655 12.0945C17.2539 9.75511 17.8435 9.07944 19.8875 8.29244C20.0375 8.23463 20.0375 7.98869 19.8875 7.93087C17.8435 7.14302 17.2531 6.4682 16.5655 4.12879Z"
            fill="white"
          />
        </svg>
      </div>
      <span className="text-xs">{description}</span>
    </div>
  );
};

export default AppTooltip;
