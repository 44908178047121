import React from 'react'

const RightLongBlackArrow = ({color='#000000'}) => {
  return (
    <svg
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0192 4.15784C12.1754 4.00163 12.1754 3.74837 12.0192 3.59216L9.47359 1.04657C9.31738 0.890364 9.06411 0.890364 8.9079 1.04657C8.75169 1.20278 8.75169 1.45605 8.9079 1.61226L11.1706 3.875L8.9079 6.13774C8.75169 6.29395 8.75169 6.54722 8.9079 6.70343C9.06411 6.85964 9.31738 6.85964 9.47359 6.70343L12.0192 4.15784ZM0.486328 4.275L11.7363 4.275L11.7363 3.475L0.486328 3.475L0.486328 4.275Z"
        fill={color}
      />
    </svg>
  );
}

export default RightLongBlackArrow
