

import React from 'react';

const DepositIcon = ({ color = '#292929' }: any) => {
  return (
 <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.79166 9.70833L1.98332 15.5167" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.41668 12.6833L1.41668 16.0833L4.81668 16.0833" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.20834 16.0833L10.625 16.0833C14.1667 16.0833 15.5833 14.6666 15.5833 11.125L15.5833 6.87498C15.5833 3.33331 14.1667 1.91665 10.625 1.91665L6.37501 1.91665C2.83334 1.91665 1.41668 3.33331 1.41668 6.87498L1.41668 8.29165" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  );
};

export default DepositIcon;
