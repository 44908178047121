import DoneIcon from '@mui/icons-material/Done';

type Props = {
  number: number;
  enableBackground: boolean;
  active: boolean;
};

const StepNumber = ({ number, enableBackground, active }: Props) => {
  return (
    <div className="w-full flex justify-center items-center">
      <div
        className={`text-xs flex items-center justify-center w-6 h-6 ${active && `border-primary text-primary`} ${enableBackground ? `` : `bg-white text-borderGray border border-borderGray`}  rounded-full -start-4  ring-white  `}
      >
        {enableBackground ? (
          <DoneIcon
            sx={{ color: 'white' }}
            className="bg-[#5BAC0A] rounded-full p-1 w-6"
          />
        ) : (
          <span>{number}</span>
        )}
      </div>
    </div>
  );
};

export default StepNumber;
