import { useEffect } from 'react';
import ActivePool from './_components/ActivePool';
import { useCountryPoolActions } from '../../../store/countryPool/poolAction';
import { useSelector } from 'react-redux';
import { selectGlobalPoolSlice } from '../../../store/countryPool/poolSlice';
import { Stack } from '@mui/material';
import MyScore from './_components/MyScore';
import UpcomingPools from './_components/UpcomingPools';
import PoolGenerationHistory from './_components/PoolGenerationHistory';
import PoolRewardHistory from './_components/PoolRewardHistory';
import GlobalHeading from '../../../components/Global/GlobalHeading';
import BannerComp from './_components/BannerComp';
import ScoreHistory from './_components/ScoreHistory';

const CountryPools = () => {
  const {
    countryBannerToken,
    activePool,
    myScore,
    upcomingPools,
    scoreHistory,
    rewardHistory,
    tokenType,
    countryBannerData,
    countryBannerLoading,
    claimLoading,
    myScorehistory,
    myScorehistoryLoading,
  } = useSelector(selectGlobalPoolSlice);
  const {
    getCountryBannerData,
    getCountryPool,
    getMyScore,
    getUpComingPool,
    getScoreHistory,
    getRewardHistory,
    getTokenType,
    // getMyScoreHistory,
  } = useCountryPoolActions();

  const fetchGlobalPool = async () => {
   (!countryBannerLoading && !countryBannerData) &&  await getCountryBannerData();
  //  !activePool &&   await getCountryPool({ page: 1, limit: 10 });
  //  !myScore && await getMyScore({ year: '2024' });
   !upcomingPools && await getUpComingPool();
  //  !scoreHistory && await getScoreHistory({ page: 1, limit: 10 });
   !rewardHistory && await getRewardHistory({ page: 1, limit: 10 });
   !tokenType && await getTokenType();
//  (!myScorehistoryLoading && !myScorehistory)  && await getMyScoreHistory({ page: 1, limit: 10 });
   
  };

  useEffect(() => {
    fetchGlobalPool();
  }, []);

  return (
    <Stack direction={'column'} spacing={{ xs: 2, lg: 6 }}>
      {countryBannerData?.length ? (
        <BannerComp
          rewardToken={countryBannerToken}
          data={countryBannerData}
          loading={countryBannerLoading}
          claimLoading={claimLoading}
        />
      ) : (
        <></>
      )}
      <Stack
        direction={'column'}
        justifyContent={'space-between'}
        alignItems={'start'}
        spacing={{ xs: 2, lg: 6 }}
      >
        <GlobalHeading
          heading="Country Pool"
          className="!text-defaultBlack !text-2xl md:!text-4xl w-full lg:w-60"
        />
        <ActivePool data={activePool} />
      </Stack>
      <ScoreHistory
        title="Your Score History"
        data={myScorehistory}
        loading={myScorehistoryLoading}
      />
      <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
        <MyScore data={myScore} />
        <UpcomingPools data={upcomingPools} />
      </Stack>
      <PoolGenerationHistory data={scoreHistory} />
      <PoolRewardHistory data={rewardHistory} typeList={tokenType} />
    </Stack>
  );
};

export default CountryPools;
