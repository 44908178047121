// Input component extends from shadcnui - https://ui.shadcn.com/docs/components/input
import * as React from 'react';
import { cn } from '../../utils/utils';
import { useMotionTemplate, useMotionValue, motion } from 'framer-motion';
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  rounded?: string;
  animationClasses?: string;
  min?: any;
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, disabled, type, isError, animationClasses, rounded, min ,...props },
    ref
  ) => {
    const radius = 150; // change this to increase the rdaius of the hover effect
    const [visible, setVisible] = React.useState(false);
    const [focused, setFocused] = React.useState(true);
    let mouseX = useMotionValue(0);
    let mouseY = useMotionValue(0);
    function handleMouseMove({ currentTarget, clientX, clientY }: any) {
      let { left, top } = currentTarget.getBoundingClientRect();
      mouseX.set(clientX - left);
      mouseY.set(clientY - top);
    }
    return (
      <motion.div
        onClick={() => {
          setVisible(true);
        }}
        style={{
          background: useMotionTemplate`
        radial-gradient(
          ${!disabled && visible ? radius + 'px' : '0px'} circle at ${mouseX}px ${mouseY}px,
          ${!isError ? '#1B9FDA' : '#FF0000 '},
          transparent 80%
        )
      `,
        }}
        onMouseMove={handleMouseMove}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        className={`p-[2px] rounded-md transition duration-300 group/input w-full ${animationClasses}`}
      >
        <input
          disabled={disabled}
          tabIndex={0}
          type={type}
          min={min}
          className={cn(
            `flex h-10  w-full bg-gray-50  text-black  shadow-input rounded-md px-3 py-2 text-sm   file:bg-transparent 
          file:text-sm file:font-medium placeholder:text-neutral-400 
           disabled:cursor-not-allowed disabled:opacity-50          
           group-hover/input:shadow-none transition duration-400
           `,
            className
          )}
          ref={ref}
          {...props}
        />
      </motion.div>
    );
  }
);
Input.displayName = 'Input';
export { Input };
