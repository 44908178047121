import React, { FC, ReactElement } from 'react';

type Props = {
  enableBackground: boolean;
};

const StepLine: FC<Props> = ({ enableBackground }): ReactElement => {
  return (
    <div className="flex justify-center items-center h-full w-full ">
      <div
        className={` h-[90%] w-[1px] ${enableBackground ? 'bg-primary' : 'bg-borderLightGray'}`}
      ></div>
    </div>
  );
};

export default StepLine;
