import { InfoIcon } from '../../images/svgs';
import Tooltips from '../Tooltips';

type Props = {
  cellValue: any;
};

const TooltipItemView = ({ cellValue }: Props) => {
  return (
    <div className="flex items-center gap-2">
      {cellValue?.length > 5
        ? cellValue.slice(0, 4) +
          '......' +
          cellValue.slice(cellValue?.length - 4)
        : !cellValue
          ? '-'
          : cellValue}
      {cellValue && (
        <Tooltips
          placement="top-end"
          content={
            <div className="p-3 bg-white shadow-md rounded-lg text-xs w-48 ">
              {cellValue}
            </div>
          }
        >
          <InfoIcon className={`w-5 h-5 cursor-pointer`} />
        </Tooltips>
      )}
    </div>
  );
};

export default TooltipItemView;
