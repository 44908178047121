import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NewsLetterIcon, NotificationIcon, UserIcon } from '../../images/svgs';
import { useNotificationsActions } from '../../store/notifications/notificationActions';
import IconLabel from '../IconLabel';

type Props = {};

const BottomBar = (props: Props) => {
  const { readAllHomnifiNotifications } = useNotificationsActions();
  const [isSelected, setIsSelected] = useState('');

  const selectedClassName = 'bg-primary/10 text-primary';

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.pathname === '/profile') {
      setIsSelected('profile');
    } else {
      setIsSelected('');
    }
  }, [location]);

  const markAllAsReadNotifications = async () => {
    await readAllHomnifiNotifications();
  };

  return (
    <div className="z-40 md:hidden sticky bottom-0 left-0 border-t border-borderBlue w-full py-2 px-2 flex justify-between items-center bg-white">
      <button
        className={`flex justify-center items-center text-black hover:text-primary hover:bg-selectedLightBlue py-2 px-4 rounded-md transition-all ease-in-out delay-75 ${
          isSelected === 'newsletter' ? selectedClassName : ''
        }`}
        onClick={() => {
          setIsSelected('newsletter');
          navigate('/newsletter');
        }}
      >
        <IconLabel
          className="flex-col items-center justify-center w-max"
          Icon={<NewsLetterIcon className="w-5 h-5" />}
          label={'Newsletter'}
          labelClass={'text-xs'}
        />
      </button>
      <button
        className={`flex justify-center items-center text-black hover:text-primary hover:bg-selectedLightBlue py-2 px-4 rounded-md transition-all ease-in-out delay-75 ${
          isSelected === 'notifications' ? selectedClassName : ''
        }`}
        onClick={() => {
          setIsSelected('notifications');
          markAllAsReadNotifications();
          navigate('/notification');
        }}
      >
        <IconLabel
          className="flex-col items-center justify-center w-max"
          Icon={<NotificationIcon className="w-5 h-5" />}
          label={'Notifications'}
          labelClass={'text-xs'}
        />
      </button>
      <button
        className={`flex justify-center items-center py-2 px-4 rounded-md transition-all ease-in-out delay-75 ${
          isSelected === 'profile' ? selectedClassName : ''
        } min-w-24`}
        onClick={() => {
          setIsSelected('profile');
          navigate('/profile');
        }}
      >
        <IconLabel
          className="flex-col items-center justify-center w-max"
          Icon={<UserIcon className="w-5 h-5" />}
          label={'Profile'}
          labelClass={'text-xs'}
        />
      </button>
    </div>
  );
};

export default BottomBar;
