/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import Animation from '../../components/Maintenance/Animation';
import { homnifiLogo } from '../../images';
import { PLATFORM_NAME } from '../../utils/constants';

const Maintenance = ({ maintenance }: any) => {
  return (
    <div className="flex flex-col gap-10 max-w-[1200px] items-center justify-center m-auto">
      <div className="flex justify-center w-full px-0 py-4 text-sm">
        <img
          src={homnifiLogo}
          alt={`${PLATFORM_NAME || ''} Logo`}
          className="w-32"
        />
      </div>

      <div className="flex flex-col items-center gap-10">
        <Animation />

        <div className="flex flex-col items-center gap-2">
          <div className="text-3xl font-baumans text-primary">
            Under Maintenance
          </div>
          {maintenance?.reason && (
            <span className="text-lg text-primaryGrey">
              {maintenance?.reason}
            </span>
          )}
          <span className="text-lg text-primaryGrey">
            Thank you for your cooperation. Our platform is under some cool
            changes. We will be back shortly.
          </span>
          {maintenance?.endDateTime && (
            <span className="flex-row text-lg text-primary">
              Issue will be resolved till{' '}
              {moment.utc(maintenance?.endDateTime).format('Do MMMM, YYYY')}
            </span>
          )}
          {/* <span className='text-sm text-primaryGrey'>
                        Incase of emergency, reach out to us on our <button className='font-medium text-primary'>Contact Page.</button>
                    </span> */}
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
