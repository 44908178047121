import React, { useState, memo, useMemo, FC, useRef } from 'react';
import GlobalTable from '../../../../../components/Global/GlobalTable';
import useDebounce from '../../../../../hooks/useDebounceHook';

import LinearProgressBar from '../../../../../components/Progress';
import { formatDateToString } from '../../../../../utils/helper';
import moment from 'moment';
import { useCountryPoolActions } from '../../../../../store/countryPool/poolAction';
import { useEffect } from 'react';
import { IActivePool } from '../../../../../types/countryPool.type';
import HoverBorderAnimation from '../../../../../components/HoverBorderAnimation';

type ActivePoolProps = {
  data: IActivePool;
};
const ActivePool: FC<ActivePoolProps> = ({ data }) => {
  const { getCountryPool } = useCountryPoolActions();
  const [activePool, setActivePool] = useState(data);
  const [resetFilter, setResetFilter] = useState(false);
  const [isLoading, setLoading] = useState({
    searchLoading: false,
    dataFilter: false,
  });
  const [filterActiveValue, setFilterActiveValue] = React.useState<any>({
    page: 1,
    limit: 10,
    fromDate: null,
    toDate: null,
  });
const [query , setQuery] = useState('')
  const { debouncedValue: debouncedSearchValue, setDebouncedValue: setSearchDebounce } = useDebounce(
    query,
    1000,
    // () => {
    //   filterActiveValue?.query != null
    //     ? setLoading({ ...isLoading, searchLoading: true })
    //     : (() => {})();
    // }
  );
  const prevFilterActiveValue = useRef(filterActiveValue);
  const prevDebouncedSearchValue = useRef(debouncedSearchValue);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        label: 'Name',
        dataClass: '!text-primaryGrey !text-sm !font-normal !pl-6 !w-[25%] ',
      },
      {
        id: 'startDate',
        label: 'Start Date',
        dataClass: '!text-primaryGrey !text-sm !font-normal',
      },
      {
        id: 'status',
        label: 'Status',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[30%]',
      },
      {
        id: 'total',
        label: 'Total Points',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[15%] ',
      },
      {
        id: 'maximum',
        label: 'Maximum Reward Points',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[15%] ',
      },
    ],
    []
  );
  const handleReset = () => {
    setResetFilter(true);
    setFilterActiveValue({
      page: 1,
      limit: 10,
      fromDate: null,
      toDate: null,
    });
    setQuery('')
    setSearchDebounce('')
  };

  const rows = activePool?.list?.map((ele: any) => {
    return {
      name: (
        <div className="flex items-start justify-start h-5 gap-1 ">
          <div className="w-2.5 h-2.5 rounded-full bg-[#AF52DE]"></div>
          <p className="text-sm font-normal leading-none text-black">
            {ele?.name}{' '}
          </p>
        </div>
      ),
      startDate: (
        <span className="!text-black">
          {ele?.startDate
            ? formatDateToString(new Date(ele?.startDate.toString()), false)
            : '-'}
        </span>
      ),
      status: (
        <div className=" flex flex-col justify-center  text-black text-xs w-full !min-w-[140px] !max-w-[28rem]">
          <div className="flex flex-row items-start justify-between pb-1 text-xs font-normal ">
            <p>Generated</p> <p>Available</p>
          </div>
          <LinearProgressBar
            isBlackThemed={true}
            value={
              ((Number(ele?.generatedPoints) || 1) /
                (Number(ele?.remainingPoints) || 1)) *
              100
            }
          />
          <div className="flex flex-row items-start justify-between pt-1 text-xs font-normal ">
            <p>{Number(ele?.generatedPoints)}</p>{' '}
            <p>{Number(ele?.remainingPoints)}</p>
          </div>
        </div>
      ),
      total: (
        <div className="flex flex-row items-center justify-start">
          {/* <img src={ele?.tokenIcon} alt={ele.name} className="w-5 h-5" /> */}
          <p className="ml-1 text-sm font-normal text-black">
            {`${ele?.totalPoints}`}
          </p>
        </div>
      ),
      maximum: (
        <div className="flex flex-row items-center justify-start">
          {/* <img src={ele?.tokenIcon} alt={ele.name} className="w-5 h-5" /> */}
          <p className="ml-1 text-sm font-normal text-black">
            {`${ele?.rewardAmount}`}
          </p>
        </div>
      ),
    };
  });
  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');
    setFilterActiveValue({
      ...filterActiveValue,
      ...{ fromDate: from, toDate: to },
    });
  };
  const tableDropdowns = (
    <button
      className="h-10 text-xs font-medium text-primary"
      type="button"
      onClick={handleReset}
    >
      Reset
    </button>
  );

  useEffect(() => {
    if ( !activePool || prevFilterActiveValue.current !== filterActiveValue || prevDebouncedSearchValue.current !== debouncedSearchValue) {
      setLoading({ ...isLoading, dataFilter: true });
      getCountryPool({
        ...filterActiveValue,
        query: debouncedSearchValue
      }).then(() => {
        setLoading({
          searchLoading: false,
          dataFilter: false,
        });
      });
    }
    prevFilterActiveValue.current = filterActiveValue;
    prevDebouncedSearchValue.current = debouncedSearchValue;
  }, [filterActiveValue, debouncedSearchValue]);

  useEffect(() => setActivePool(data), [data]);

  return (
    <HoverBorderAnimation
      className={`h-full border radius-5px !rounded-lg  w-full col-span-12 bg-white  relative !p-0`}
      hoverAnimation={true}
    >
      <p className="p-6 pb-3 mb-3 text-lg font-semibold ">Active Pool</p>
      <GlobalTable
        isCellClass={true}
        stopHoverEffect={true}
        filterClass="!px-6"
        containerStyles="!border-none rounded-lg"
        isLoading={isLoading?.dataFilter}
        bodyStyles="!text-black !font-normal"
        totalPages={1}
        searchIsLoading={isLoading?.searchLoading}
        searchFieldPlaceholderText="Search"
        handlePageChange={(page: any) => {
          setFilterActiveValue({ ...filterActiveValue, ...{ page } });
        }}
        setSearchValue={(e: any) => {
          setQuery(e)
        }}
        // tableWidth="true"
        handleDateDate={handleDateDate}
        tableDropdowns={tableDropdowns}
        resetDateFilter={resetFilter}
        toolTipItems={['remarks']}
        colorItems={['status']}
        fromDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
        toDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
        dateArrowAllow={true}
        paginationPosition="right"
        rows={rows?.length ? rows : []}
        headCells={headCells}
        searchValue={query}
        showTotalRecords={false}
        bodyClass={'!min-h-0 !rounded-md'}
      ></GlobalTable>
    </HoverBorderAnimation>
  );
};

export default memo(ActivePool);
