import React, { useEffect, useState, memo, FC, useRef } from 'react';
import GlobalTable from '../../../../../components/Global/GlobalTable';
import GlobalPageWrapper from '../../../../../components/Global/GlobalPagewrapper';
import useDebounce from '../../../../../hooks/useDebounceHook';
import { formatDateToString } from '../../../../../utils/helper';
import { useCountryPoolActions } from '../../../../../store/countryPool/poolAction';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectscoreHistorySlice } from '../../../../../store/countryPool/poolSlice';

type PoolGenerationHistoryProps = {
  data: any;
};
const PoolGenerationHistory: FC<PoolGenerationHistoryProps> = memo(
  ({ data }) => {
    const { getScoreHistory } = useCountryPoolActions();
    const [scoreHistory, setHistory] = useState<any>(data);
    const [filterPoolScoreValue, setFilterPoolScoreValue] = React.useState<any>(
      {
        page: 1,
        limit: 10,
        fromDate: null,
        toDate: null,
      }
    );
    const [query , setQuery] = useState('')

    const [resetFilter, setResetFilter] = useState(false);
    const [isLoading, setLoading] = useState({
      searchLoading: false,
      dataFilter: false,
    });

    const { debouncedValue: debouncedSearchValue,setDebouncedValue: setSearchDebounce } = useDebounce(
      query,
      1000,
      // () => {
      //   filterPoolScoreValue?.query != null
      //     ? setLoading({ ...isLoading, searchLoading: true })
      //     : (() => {})();
      // }
    );
    const prevFilterActiveValue = useRef(filterPoolScoreValue);
    const prevDebouncedSearchValue = useRef(debouncedSearchValue);

    useEffect(() => {
      if (  prevFilterActiveValue.current !== filterPoolScoreValue || prevDebouncedSearchValue.current !== debouncedSearchValue) {
  setResetFilter(false);
      setLoading({ ...isLoading, dataFilter: true });

      getScoreHistory({
        ...filterPoolScoreValue,
        query: debouncedSearchValue
      }).then(() =>
        setLoading({
          searchLoading: false,
          dataFilter: false,
        })
      );
}
prevFilterActiveValue.current = filterPoolScoreValue;
    prevDebouncedSearchValue.current = debouncedSearchValue;
    
    }, [
      filterPoolScoreValue,
      debouncedSearchValue,
    ]);

    const handleReset = () => {
      setResetFilter(true);

      setFilterPoolScoreValue({
        page: 1,
        limit: 10,
        fromDate: null,
        toDate: null,
      });
      setQuery('')
    setSearchDebounce('')
    };
    const rows = scoreHistory?.map((score: any) => {
      return {
        minterName: score?.minterName || '-',
        poolName: score?.poolName || '-',
        token: score?.token || 0,
        tokenName: score?.tokenName || '-',
        date: (
          <span className="!text-primaryGrey">
            {score?.date ? formatDateToString(score?.date, false) : '-'}
          </span>
        ),
      };
    });
    const headCells = [
      {
        id: 'minterName',
        label: 'Minter Name',
        dataClass: '!text-primaryGrey  !font-normal !pl-6',
      },
      {
        id: 'poolName',
        label: 'Pool Name',
        dataClass: '!text-primaryGrey  !font-normal',
      },

      {
        id: 'token',
        label: 'Token',
        dataClass: '!text-primaryGrey  !font-normal',
      },
      {
        id: 'tokenName',
        label: 'Token Name',
        dataClass: '!text-primaryGrey  !font-normal',
      },
      {
        id: 'date',
        label: 'Date',
        dataClass: '!text-primaryGrey  !font-normal',
      },
    ];
    const handleDateDate = (startDate: any, endDate: any) => {
      const from = moment(startDate).format('YYYY-MM-DD');
      const to = moment(endDate).format('YYYY-MM-DD');
      setFilterPoolScoreValue({ fromDate: from, toDate: to });
    };
    const tableDropdowns = (
      <button
        className="h-10 text-xs font-medium text-primary"
        type="button"
        onClick={handleReset}
      >
        Reset
      </button>
    );

    useEffect(() => {
      setHistory(data);
    }, [data]);

    return (
      <>
        <GlobalPageWrapper containerClass="!p-0 !border-none !mb-0 !bg-transparent ">
          <p className="mb-3 text-lg font-semibold ">
            Your Pool Generation History
          </p>
          <div className="relative">
            <GlobalTable
              totalPages={1}
              containerStyles="!border-none rounded-lg"
              paginationPosition="right"
              rows={rows?.length ? rows : []}
              headCells={headCells}
              showTotalRecords={false}
              isLoading={isLoading?.dataFilter}
              searchIsLoading={isLoading?.searchLoading}
              bodyStyles="!pl-3"
              searchFieldPlaceholderText="Search"
              handlePageChange={(page) => {
                setFilterPoolScoreValue({
                  ...filterPoolScoreValue,
                  ...{ page },
                });
              }}
              setSearchValue={(e: any) => {
                setQuery(e)
              }}
              tableWidth="true"
              searchValue={query}
              isCellClass={false}
              handleDateDate={handleDateDate}
              tableDropdowns={tableDropdowns}
              resetDateFilter={resetFilter}
              bodyClass={'!min-h-0 !rounded-lg'}
            ></GlobalTable>
            <div className="backdrop-blur-sm w-[100%] h-[100%] absolute top-1 flex justify-center items-center z-20 ">
              <p>Coming Soon</p>
            </div>
          </div>
        </GlobalPageWrapper>
      </>
    );
  }
);

export default PoolGenerationHistory;
