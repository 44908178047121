export const MachineImage = '/img/cloud/machine.png';
export const NodekBanner1 = ' /img/cloud/nodekslider.png';
export const SMToken = ' /img/cloud/sm.png';
export const Smicon = ' /img/cloud/sm-icon.png';
export const horrymallicon = ' /img/cloud/horysmall.png';
export const hom = ' /img/cloud/hom.png';
export const usdicon = ' /img/cloud/usdCoin.png';
export const SMCoin = ' /img/cloud/sm-coin.png';
export const MCoin = ' /img/cloud/mCoin.png';
export const PromoCoin = ' /img/cloud/promo-icon.png';
export const fire = ' /img/cloud/fire-flame.gif';
