import React from 'react';

const PlusSquareIcon = ({ color = 'white' }: any) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.27429 4.79218V11.0259"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.15759 7.90906H11.3914"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9098 1.15582H4.63703C2.91562 1.15582 1.52014 2.5513 1.52014 4.27271V11.5454C1.52014 13.2669 2.91562 14.6623 4.63703 14.6623H11.9098C13.6312 14.6623 15.0266 13.2669 15.0266 11.5454V4.27271C15.0266 2.5513 13.6312 1.15582 11.9098 1.15582Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusSquareIcon;
